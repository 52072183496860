import { FieldContainer, FormInput } from "components/form-control";
import { MAPBOX_ACCESS_TOKEN } from "constants/mapbox.const";
import { Dispatch, SetStateAction, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { OsmSearchJsonV2 } from "services/common/osm.type";
import { CityInput, MapViewType } from "types";
import DeckGL from "@deck.gl/react";
import { Map as StaticMap } from "react-map-gl";
import { GeoJsonLayer } from "@deck.gl/layers";
import { isJson } from "helper/utils";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "assets";
import { mapboxStyle } from "constants/map.const";

interface CoordinatesProps {
  viewState: MapViewType | undefined;
  setViewState: Dispatch<SetStateAction<MapViewType | undefined>>;
  selectedCity: OsmSearchJsonV2 | undefined;
}

export const Coordinates = forwardRef<any, CoordinatesProps>(({ viewState, setViewState, selectedCity }, ref) => {
  const {
    watch,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<CityInput>();
  const handleViewState = ({ viewState }: any) => setViewState({ ...viewState });

  const layer = new GeoJsonLayer({
    id: "city-boundries",
    data: isJson(watch("boundaries")) ? JSON.parse(watch("boundaries")) : [],
    stroked: true,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getLineWidth: 1,
    getFillColor: [160, 160, 180, 100],
    getLineColor: [0, 0, 0],
  });
  const coordinateErrors = errors.longitude || errors.latitude || errors.boundaries;
  return (
    <>
      <div className="w-full flex flex-col">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <DisclosureButton className="flex w-full justify-between items-center rounded-md shadow-lg bg-white  px-4 py-2 text-left text-sm font-medium">
                <h1 className={`font-bold text-md ${coordinateErrors ? "text-red-700" : ""}`}>Coordinates</h1>
                <div className="flex flex-row gap-x items-center">
                  {coordinateErrors ? <span className="text-red-700 text-lg">&#9888;</span> : null}
                  <ChevronDownIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5`} />
                </div>
              </DisclosureButton>
              <DisclosurePanel className="px-4 pt-4 pb-2 text-sm">
                <h1 className="font-bold text-sm mb-2">Position</h1>
                <div className="flex flex-row items-center w-full gap-x-4 justify-around">
                  <FieldContainer
                    className="basis-1/2 items-center border-b border-primary"
                    helpText={errors.longitude ? "This is required" : undefined}
                    status={errors.longitude ? "error" : "default"}
                  >
                    <FormInput
                      className="border-none"
                      label="Longitude"
                      {...register("longitude", { required: true })}
                      defaultValue={getValues("longitude")}
                      status={errors.longitude ? "error" : "primary"}
                    />
                  </FieldContainer>
                  <FieldContainer
                    className="basis-1/2 items-center border-b border-primary"
                    helpText={errors.latitude ? "This is required" : undefined}
                    status={errors.latitude ? "error" : "default"}
                  >
                    <FormInput
                      label="Latitude"
                      className="border-none"
                      {...register("latitude", { required: true })}
                      defaultValue={getValues("latitude")}
                      status={errors.longitude ? "error" : "primary"}
                    />
                  </FieldContainer>
                </div>

                <h1 className="font-bold text-sm ">GeoJson</h1>
                <div className="w-full flex flex-row gap-x-2 h-80 mb-2 mt-2">
                  <FieldContainer status={errors.boundaries ? "error" : "default"} className="basis-1/2 p-0">
                    <textarea
                      defaultValue={selectedCity && JSON.stringify(selectedCity.geojson)}
                      className={`w-full h-full bg-default overflow-x-hidden outline-none resize-none p-1 ${
                        errors.boundaries ? "ring-red-500 ring-2" : ""
                      }`}
                      {...register("boundaries", { required: true })}
                    />
                  </FieldContainer>
                  <FieldContainer className="basis-1/2 p-0">
                    <textarea
                      className="w-full h-full bg-primary border-2 border-secondary text-[#ffffff] text-sm text-bold px-1 resize-none"
                      disabled
                      value={JSON.stringify(
                        isJson(watch("boundaries")) ? JSON.parse(watch("boundaries")) : watch("boundaries"),
                        undefined,
                        6,
                      )}
                    />
                  </FieldContainer>
                </div>
                <div className="w-full h-96" ref={ref}>
                  {viewState && (
                    <StaticMap
                      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                      mapStyle={mapboxStyle["normal"]}
                      viewState={{ ...viewState }}
                    >
                      <DeckGL
                        viewState={viewState}
                        layers={[layer]}
                        onViewStateChange={handleViewState}
                        controller={{
                          touchZoom: true,
                          touchRotate: true,
                        }}
                      />
                    </StaticMap>
                  )}
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
});
Coordinates.displayName = "Coordinates";
