export const typography = {
  h1: "text-2xl font-medium leading-8",
  h2: "text-xl font-medium leading-7",
  h3: "text-lg font-medium leading-relaxed",
  h4: "text-base font-normal leading-normal",
  body: "text-sm font-normal leading-snug",
  bodySecondary: "text-xs font-normal leading-4",
  label: "text-xs font-bold leading-4 tracking-wide",
  subtitle: "text-subtitle",
};
