import { useMutation } from "@apollo/client";
import {
  QUERY_CREATE_RESTRICTION,
  QUERY_REMOVE_RESTRICTION,
  QUERY_UPDATE_RESTRICTION,
  QUERY_UPDATE_RESTRICTIONS,
  QUERY_REMOVE_RESTRICTIONS,
  QUERY_RESTRICTION_AND_CURB_ZONES,
} from "services/web/api-restriction.const";
import { DisabledCurbZoneParams } from "../disabled-part.type";
import { useFetchRestriction } from "hooks/use-restriction.hook";
import {
  CreateRestrictionResponse,
  DeleteMultiRestrictionResponse,
  DeleteRestrictionResponse,
  UpdateMultiRestrictionResponse,
  UpdateRestrictionResponse,
} from "services/web/api-restriction.type";

export const useQueryRestriction = ({ selectedDate, refetchDecree }: DisabledCurbZoneParams) => {
  const { setIsResetData, loading, data, isResetData, bounding, setBounding, nearBy } = useFetchRestriction({
    queryString: QUERY_RESTRICTION_AND_CURB_ZONES,
    selectedDate,
  });

  const resetState = () => {
    setIsResetData(true);
  };

  const [createRestriction] = useMutation<CreateRestrictionResponse>(QUERY_CREATE_RESTRICTION, {
    onError: resetState,
    onCompleted: () => {
      refetchDecree();
    },
  });
  const [updateRestriction] = useMutation<UpdateRestrictionResponse>(QUERY_UPDATE_RESTRICTION, {
    onError: resetState,
    onCompleted: () => {
      refetchDecree();
    },
  });

  const [removeRestriction] = useMutation<DeleteRestrictionResponse>(QUERY_REMOVE_RESTRICTION, {
    onCompleted: () => {
      refetchDecree();
    },
  });

  const [removeMultiRestriction] = useMutation<DeleteMultiRestrictionResponse>(QUERY_REMOVE_RESTRICTIONS, {
    onCompleted: () => {
      refetchDecree();
    },
    onError: resetState,
  });

  const [updateMultiRestriction] = useMutation<UpdateMultiRestrictionResponse>(QUERY_UPDATE_RESTRICTIONS, {
    onCompleted: () => {
      refetchDecree();
    },
    onError: resetState,
  });

  return {
    bounding,
    setBounding,
    data,
    loading,
    createRestriction,
    updateRestriction,
    updateMultiRestriction,
    removeMultiRestriction,
    isResetData,
    setIsResetData,
    removeRestriction,
    nearBy,
  };
};
