import { getQueryParams } from "helper/utils";
import { useContext, useState } from "react";
import { useSetQueryParams } from "./use-set-query-params";
import { Filter } from "../components/search-and-filter/SearchFilter.type";
import { CityContext } from "../contexts/city-context-provider";

export const useSearchFilterCurbZone = () => {
  const params = getQueryParams();
  const setQueryParams = useSetQueryParams();
  const { currentCity } = useContext(CityContext);

  const currentFilter: Filter = {
    selectedPolicies: params.policies?.split(",") || [],
  };
  const [filter, setFilter] = useState<Filter>(currentFilter);

  const changeFilter = (input: Filter) => {
    setFilter(input);
    if (currentCity) {
      setQueryParams(
        {
          cityID: currentCity.id,
          policies: input.selectedPolicies,
        },
        { merge: true },
      );
    }
  };
  return {
    filter,
    changeFilter,
  };
};
