import * as React from "react";

export const PenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.703336 6.74854L0.342163 7.68156L3.15128 8.77511L3.51246 7.84208L0.703336 6.74854Z"
      fill="#F3F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61921 8.65507L4.4454 8.20361L4.08423 9.13664L6.13087 9.93924L6.27133 9.5881L6.78299 8.45442L7.34482 7.62172L9.88306 7.67188L10.8462 6.68869L6.83315 6.48804L5.61921 8.65507Z"
      fill="#F3F3F4"
    />
    <mask id="mask0" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="6" y="10" width="5" height="5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52541 10.0607C8.43412 10.0777 8.35687 10.1379 8.31774 10.2222V10.2222L6.54298 14.0366C6.4898 14.148 6.51589 14.2814 6.60618 14.3677V14.3677C6.66337 14.4228 6.73761 14.4489 6.81285 14.4469V14.4469C6.857 14.4459 6.90114 14.4359 6.94127 14.4128V14.4128L10.6493 12.4284C10.7316 12.3842 10.7878 12.304 10.7998 12.2117V12.2117C10.8118 12.1194 10.7797 12.0261 10.7115 11.9629V11.9629L8.77924 10.1349C8.72506 10.0828 8.65383 10.0557 8.58059 10.0557V10.0557C8.56253 10.0557 8.54347 10.0567 8.52541 10.0607V10.0607ZM8.67289 10.8312L10.0223 12.1073L7.43287 13.4928L8.67289 10.8312Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.24854 5.24063L15.6162 4.84033L16.0165 19.209L1.64883 19.6093L1.24854 5.24063Z"
        fill="#F3F3F4"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88307 14.624L7.19434 15.9684L7.63577 16.8613L10.3345 15.5169L9.88307 14.624Z"
      fill="#F3F3F4"
    />
    <mask id="mask1" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="8" y="0" width="13" height="13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4453 0.549695L8.36988 10.1459C8.26052 10.2622 8.26554 10.4448 8.38091 10.5552V10.5552L10.3142 12.3831C10.3724 12.4383 10.4476 12.4644 10.5209 12.4624V12.4624C10.5951 12.4594 10.6683 12.4303 10.7235 12.3721V12.3721L19.799 2.77592C20.1963 2.35556 20.1772 1.6914 19.7568 1.29512V1.29512L18.9262 0.509565C18.7305 0.323962 18.4697 0.232666 18.2088 0.232666V0.232666C17.9269 0.232666 17.645 0.340015 17.4453 0.549695V0.549695ZM8.98989 10.3335L17.8657 0.94801C18.0383 0.765417 18.3473 0.757391 18.5289 0.929951V0.929951L19.3596 1.7155C19.5482 1.89308 19.5562 2.19105 19.3786 2.37865V2.37865L10.5018 11.7631L8.98989 10.3335Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9265 13.7213L11.2277 15.0657L10.7762 14.1728L12.8028 13.1595V10.9686L13.8061 9.96533L13.9265 13.7213Z"
        fill="#F3F3F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86279 -4.35842L24.9074 -4.97241L25.5214 17.0621L3.47679 17.6761L2.86279 -4.35842Z"
        fill="#F3F3F4"
      />
      <mask id="mask2" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="16" y="1" width="3" height="4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3668 1.69039C16.2575 1.80677 16.2625 1.99036 16.3789 2.09972V2.09972L18.3112 3.92665C18.3693 3.98283 18.4436 4.00792 18.5178 4.00691V4.00691C18.5921 4.00491 18.6653 3.9738 18.7195 3.91562V3.91562C18.8308 3.79924 18.8258 3.61665 18.7094 3.50729V3.50729L16.7762 1.67835C16.72 1.62618 16.6498 1.6001 16.5785 1.6001V1.6001C16.5003 1.6001 16.424 1.6312 16.3668 1.69039V1.69039Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1259 -3.26662L23.6134 -3.61475L23.9615 8.8728L11.474 9.22093L11.1259 -3.26662Z"
          fill="#F3F3F4"
        />
      </g>
    </g>
  </svg>
);
