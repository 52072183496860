import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import * as React from "react";
import { Confirm, MacButton } from "components";
import { IPrivateParking } from "types";
import useToggle from "hooks/use-toggle";
import { moneyFormat, numberFormat } from "helper/format-data";
import { PrivateParkingModalState } from "../private-parking.const";

export type PrivateParkingDetailProps = {
  parking: IPrivateParking;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof PrivateParkingModalState>>;
  removeParking: () => void;
};
export const PrivateParkingDetail = ({ parking, setParkingModalState, removeParking }: PrivateParkingDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle(false);

  const handleOpenConfirm = () => {
    setOpenConfirm();
  };

  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80">
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Name</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{parking.name}</TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Address</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{parking.address}</TableCell>
            </TableRow>
            {!!parking.totalPlaces && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Parking capacity</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {numberFormat(parking.totalPlaces)}
                </TableCell>
              </TableRow>
            )}
            {!!parking.pricePerHour && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Price per hour</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {moneyFormat(parking.pricePerHour)}
                </TableCell>
              </TableRow>
            )}
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">External Id</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {parking.externalId}
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between mt-4">
          <MacButton onClick={handleOpenConfirm} variant="error">
            Delete
          </MacButton>
          <MacButton onClick={() => setParkingModalState(PrivateParkingModalState.SHOW)} variant="secondary">
            Edit
          </MacButton>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete private parking"
          content="Are you sure that you want to delete this parking?"
          onCancel={handleOpenConfirm}
          onOk={removeParking}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
