import clsx from "clsx";
import * as React from "react";
import { callAll } from "helper/utils";

export interface CheckboxProps extends Omit<React.ComponentPropsWithoutRef<"input">, "type" | "children"> {
  variant?: "primary" | "secondary" | "error";
  label?: React.ReactNode;
  onChangeValue?: (checked: boolean) => void;
  value?: string | number;
  wrapperClass?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { variant = "primary", label, onChangeValue, wrapperClass, ...props },
  ref,
) {
  const checkboxEl = (
    <input
      {...props}
      checked={props.checked}
      onChange={callAll(props.onChange, onChangeValue && ((ev) => onChangeValue(ev.target.checked)))}
      ref={ref}
      className="cursor-pointer rounded"
      type="checkbox"
    />
  );
  return (
    <div className={clsx("flex items-center", wrapperClass)}>
      <label className="lb-container h-auto">
        {checkboxEl}
        <span className={clsx("text-sm", classByVariant[variant])}>{label}</span>
        <span className={clsx("checkmark border-solid border", classBorderByVariant[variant])} />
      </label>
    </div>
  );
});

type CheckBoxVariant = NonNullable<CheckboxProps["variant"]>;

const classByVariant: Record<CheckBoxVariant, string> = {
  primary: "text-primary",
  secondary: "text-secondary",
  error: "text-red-500",
};

const classBorderByVariant: Record<CheckBoxVariant, string> = {
  primary: "border-primary",
  secondary: "border-secondary",
  error: "border-red-500",
};
