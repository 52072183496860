import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useContext, useEffect, useRef } from "react";

import { CityContext } from "../contexts/city-context-provider";
import { ViewState } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import { getQueryParams, stringifyQueryString } from "../helper/utils";
import { useLocation } from "react-router-dom";
import { useDebounce } from "./lib-ui";

export type ItemParam = { viewport?: ViewState } & {
  [key: string]: string | string[] | undefined;
};

export const useSetQueryParams = () => {
  const currentParam = getQueryParams();
  const currentViewport = useRef<ViewState>();
  const { setViewport } = useContext(CityContext);
  const [mergeParams, setMergeParams] = useState<ItemParam>({ ...currentParam });
  const newParams = useDebounce(mergeParams, 500);

  const location = useLocation();

  useEffect(() => {
    return () => {
      if (currentViewport.current) {
        setViewport(currentViewport.current);
      }
    };
  }, [setViewport]);
  useEffect(() => {
    window.history.replaceState(null, "", `${location.pathname}?${stringifyQueryString(newParams)}`);
  }, [newParams, location.pathname]);

  const setQueryParams = useCallback(
    (params: ItemParam, options?: { merge?: boolean }) => {
      const { viewport, ...rest } = params;
      let view = currentParam.view;
      if (viewport) {
        view = `${viewport.latitude},${viewport.longitude},${viewport.zoom}`;
      }

      const newParams = {
        view,
        ...rest,
      };

      const mergeParams = options?.merge
        ? {
            ...currentParam,
            view,
            ...rest,
          }
        : newParams;
      currentViewport.current = viewport;
      setMergeParams(mergeParams);
    },
    [currentParam],
  );

  return setQueryParams;
};

export type QueryParamByViewPort = {
  viewState: any;
  viewport: ViewState;
  setBounding: Dispatch<SetStateAction<number[][] | undefined>>;
  cityID: string;
  policies?: string[];
};

export const useQueryParamByViewPort = () => {
  const setQueryParams = useSetQueryParams();
  return function setQueryParamByViewport({
    viewState,
    viewport,
    setBounding,
    cityID,
    policies,
  }: QueryParamByViewPort) {
    const elmViewPort = new WebMercatorViewport({ ...viewState, height: window.innerHeight, width: window.innerWidth });
    const currentZoom = Math.round(viewport.zoom);
    const nearBy = elmViewPort.getBoundingRegion({ z: currentZoom });
    nearBy.forEach((item) => {
      item.splice(2, 1);
    });
    if (nearBy) {
      setBounding(nearBy);
      setQueryParams(
        {
          cityID,
          viewport: viewState,
          policies,
        },
        { merge: true },
      );
    }
  };
};
