import { forwardRef } from "react";
import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { borderStatus, FieldStatus } from "./field.type";
import { callAll } from "helper/utils";

export interface TextInputProps extends React.ComponentPropsWithoutRef<"input"> {
  status?: FieldStatus;
  onChangeValue?: (value: string) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  { status = "default", className, onChange, onChangeValue, ...inputProps },
  forwardedRef,
) {
  return (
    <input
      className={clsx(
        "text-input block min-w-0 flex-2 lg:flex-3",
        !hasClassName(className, "w-") && "w-full",
        !hasClassName(className, "focus:") && "focus:border-brand-200 focus:shadow-outline-brand",
        status ? borderStatus[status] : "border-grey",
        (inputProps.readOnly || inputProps.disabled) && !hasClassName(className, "bg-") && "bg-gray-200",
        className,
      )}
      onChange={callAll(onChange, onChangeValue && ((ev) => onChangeValue(ev.target.value)))}
      {...inputProps}
      ref={forwardedRef}
    />
  );
});
