import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import dateFnsFormat from "date-fns/format";
import { fr } from "date-fns/locale";
import { isDefined } from "./utils";

export const formatDate = (
  date: string | Date,
  {
    format = "d MMM yyyy",
  }: {
    format?: string;
  } = {},
) => dateFnsFormat(typeof date === "string" ? new Date(date) : date, format, { locale: fr });

export type RangeType = {
  name: DateRangeType;
  display: string;
};

export const DateRangeTypeValues = [
  "custom",
  "today",
  "yesterday",
  "thisWeek",
  "lastWeek",
  "thisMonth",
  "lastMonth",
  "thisYear",
  "lastYear",
];

export type DateRangeType = (typeof DateRangeTypeValues)[number];

export const formatDateRange = (from: Date | undefined, to: Date | undefined) => {
  if (isDefined(from) && isDefined(to)) {
    if (isSameDay(from, to)) {
      return formatDate(from);
    } else if (isSameYear(from, to)) {
      return formatDate(from, { format: "d MMM" }) + " - " + formatDate(to);
    }
    return formatDate(from) + " - " + formatDate(to);
  }
  return [from, to]
    .filter(isDefined)
    .map((itemDate) => formatDate(itemDate))
    .join(" - ");
};

export const Ranges: RangeType[] = [
  {
    name: "custom",
    display: "Période",
  },
  {
    name: "today",
    /* eslint-disable-next-line quotes */
    display: "Aujourd'hui",
  },
  {
    name: "yesterday",
    display: "Hier",
  },
  {
    name: "thisWeek",
    display: "Cette semaine",
  },
  {
    name: "lastWeek",
    display: "Semaine dernière",
  },
  {
    name: "thisMonth",
    display: "Ce mois-ci",
  },
  {
    name: "lastMonth",
    display: "Le mois dernier",
  },
  {
    name: "thisYear",
    display: "Cette Année",
  },
  {
    name: "lastYear",
    /* eslint-disable-next-line quotes */
    display: "L'année dernière",
  },
];

export type DateSelect = {
  from: Date | undefined;
  to: Date | undefined;
};

export const getDateRangeByType = (input: DateRangeType): DateSelect => {
  const today = new Date();
  let from;
  let to;

  switch (input) {
    case "yesterday":
      from = startOfDay(addDays(today, -1));
      to = endOfDay(addDays(today, -1));
      break;
    case "thisWeek":
      from = startOfWeek(today, { weekStartsOn: 1 });
      to = endOfWeek(today, { weekStartsOn: 1 });
      break;
    case "lastWeek":
      from = startOfWeek(addWeeks(today, -1), { weekStartsOn: 1 });
      to = endOfWeek(addWeeks(today, -1), { weekStartsOn: 1 });
      break;
    case "thisMonth":
      from = startOfMonth(today);
      to = endOfMonth(today);
      break;
    case "lastMonth":
      from = startOfMonth(addMonths(today, -1));
      to = endOfMonth(addMonths(today, -1));
      break;
    case "thisYear":
      from = startOfYear(today);
      to = endOfYear(today);
      break;
    case "lastYear":
      from = startOfYear(addYears(today, -1));
      to = endOfYear(addYears(today, -1));
      break;
    default:
      from = today;
      to = today;
      break;
  }

  return {
    from,
    to,
  };
};

export const timeToString = (hours: number, minutes: number) => {
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

/**
 * convert date object to UTC time
 * @input: Date | string
 * @output: string date '2019-09-18T19:00:00Z'
 * */
export const convertDateToUTC = (input: Date | string) => {
  const date = dateFnsFormat(new Date(input), "yyyy-MM-dd").split("-");
  const time = dateFnsFormat(new Date(input), "HH:mm").split(":");

  return new Date(Date.UTC(Number(date[0]), Number(date[1]) - 1, Number(date[2]), Number(time[0]), Number(time[1]), 0));
};

export function parseISOString(date: Date | string) {
  if (typeof date === "object") {
    return date;
  }
  const b = date.split(/\D+/);
  return new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5]), Number(b[6]));
}
