import { checkPermission, getParameterByIndex } from "helper/utils";
import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./menu-items";
import { ERole } from "store/auth/auth.type";

export type SidebarMenuItemProps = {
  item: MenuItem;
  roles: ERole[];
};

export const SidebarMenuItem = ({ item, roles }: SidebarMenuItemProps) => {
  const currentPath = getParameterByIndex(0);
  const { path, menuIcon: MenuIcon } = item;

  const isActive = currentPath === path;
  const hasPermission = checkPermission(item.roles, roles);
  if (!hasPermission) {
    return <></>;
  }

  return (
    <li className={`relative ${isActive && "active"}`}>
      <NavLink to={path} className="h-45px justify-center flex items-center">
        <MenuIcon
          className={`h-8 w-8 fill-current text-gray-100 ${isActive ? "opacity-100" : "opacity-60"}  hover:opacity-100`}
        />
      </NavLink>
    </li>
  );
};
