import { MacButton } from "components";
import { CalendarSelector, FieldContainer, FormInput } from "components/form-control";
import { DropdownAutoCompleteSelect } from "components/form-control/dropdown-autocomplete";
import { InputNumber } from "components/form-control/input-number";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { colors } from "constants/map.const";
import { findObject } from "helper/array";
import { getEnumKeyByEnumValue } from "helper/format-data";
import { Primitive } from "helper/utils";
import { useEffect, useState } from "react";
import { CreatePolicyResponse, PolicyInput } from "services/web/api-policy.type";
import { City, ColorInput, CreatePolicyRuleInput, Policy } from "types";
import { Activity, PolicyCategory, UnitOfTime } from "types/policty.enum";
import { usePolicies } from "../hooks/use-policies";
import { arrCategory, arrCategoryDropdown, defaultCategory, defaultRule } from "../policies.const";
import { PolicyColorInput } from "./PolicyColorInput";
import { RuleListing } from "./RuleListing";
import { TimeSpanListing } from "components/time-span";
import { defaultTimeSpan } from "components/time-span/time-span.const";

export type AddPolicyProps = {
  policyData: Policy;
  onClose: () => void;
  currentCity: City;
  onSubmit?: (data: CreatePolicyResponse) => void;
};

export const AddPolicyModal = ({ policyData, onClose, currentCity, onSubmit }: AddPolicyProps) => {
  const currentPublishedDate = policyData.publishedDate ? new Date(policyData.publishedDate) : new Date();

  const [timeSpans, setTimeSpans] = useState(policyData.timeSpans || []);
  const [rules, setRules] = useState(policyData.rules);
  const [name, setName] = useState(policyData.name);
  const [priority, setPriority] = useState(policyData.priority);
  const [publishedDate, setPublishedDate] = useState<Date>(currentPublishedDate);
  const [policyColor, setPolicyColor] = useState<ColorInput>({
    fillColor: policyData.color?.fillColor ?? colors.PAYANT.fillColor,
    lineColor: policyData.color?.lineColor ?? colors.PAYANT.lineColor,
    dashed: policyData.color?.dashed ?? colors.PAYANT.dashedBorder,
  });

  const [selectedCategory, setSelectedCategory] = useState(policyData?.category || defaultCategory.label);

  const { handleAddPolicy, handleUpdatePolicy } = usePolicies();

  const savePolicy = async () => {
    const newRules = rules.map((item) => {
      const newActivity = getEnumKeyByEnumValue(Activity, item.activity);
      return {
        ...item,
        activity: newActivity,
      } as CreatePolicyRuleInput;
    });
    const fCategoryIndex = findObject(arrCategory, "label", selectedCategory);
    const category = arrCategory[fCategoryIndex].key as PolicyCategory;
    const newPolicy: PolicyInput = {
      state: policyData.state,
      note: policyData.note,
      cityId: currentCity.id,
      dataSourceOperatorId: policyData.dataSourceOperatorId,
      name,
      timeSpans,
      rules: newRules,
      priority,
      publishedDate,
      category,
      color: policyColor,
    };

    if (policyData.id) {
      await handleUpdatePolicy({ updatePolicyInput: { ...newPolicy, id: policyData.id } });
    } else {
      const res = await handleAddPolicy({ createPolicyInput: newPolicy });
      if (res) {
        onSubmit?.(res);
      }
    }
    onClose();
  };

  const changePublishedDate = (input: Date) => {
    setPublishedDate(input);
  };
  const handleSaveColor = (value: ColorInput) => {
    setPolicyColor(value);
  };

  const handleCategoryChange = (value: Primitive) => {
    setSelectedCategory(value as PolicyCategory);
  };

  useEffect(() => {
    if (policyData.id) {
      return;
    }
    const fCategoryIndex = findObject(arrCategory, "label", selectedCategory);
    if (fCategoryIndex < 0) {
      return;
    }
    const { value } = arrCategory[fCategoryIndex];
    if (value) {
      const {
        activity,
        priority,
        timeOfDayEnd,
        timeOfDayStart,
        userClasses,
        maxStay = 0,
        maxStayUnit = UnitOfTime.Minute,
      } = value;
      setPriority(priority);
      setTimeSpans([{ ...defaultTimeSpan, timeOfDayStart, timeOfDayEnd }]);
      setRules([{ ...defaultRule, activity, userClasses, maxStay, maxStayUnit }]);
    } else {
      setPriority(1);
      setTimeSpans([]);
      setRules([]);
    }
  }, [selectedCategory, policyData.id]);

  useEffect(() => {
    if (policyData?.category) {
      const fCategoryIndex = findObject(arrCategory, "key", policyData.category);
      if (fCategoryIndex < 0) {
        return;
      }
      setSelectedCategory(arrCategory[fCategoryIndex].label as PolicyCategory);
    }
  }, [policyData]);

  return (
    <Modal isOpen className="my-1vh">
      <ModalContent
        className="pb-0"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{policyData.id ? "Edit " : "Add "} a policy</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Policy information</p>
          </>
        }
      >
        <FieldContainer>
          <DropdownAutoCompleteSelect
            className="justify-between w-full border-b"
            variant="secondary"
            status="default"
            label="Category"
            value={selectedCategory}
            options={arrCategoryDropdown}
            onChangeValue={handleCategoryChange}
          />
        </FieldContainer>
        <FieldContainer className="border-b">
          <FormInput label="Name" className="col-span-3 border-none" value={name} name="name" onChangeValue={setName} />
        </FieldContainer>
        <FieldContainer>
          <InputNumber label="Priority" defaultValue={priority} onChangeValue={(value) => setPriority(value)} />
        </FieldContainer>
        <FieldContainer label="Color">
          <PolicyColorInput policyColor={policyColor} onSaveColor={handleSaveColor} />
        </FieldContainer>
        <TimeSpanListing timeSpans={timeSpans} setTimeSpans={setTimeSpans} />
        <RuleListing rules={rules} setRules={setRules} />
        <FieldContainer>
          <CalendarSelector
            label="Publish"
            selectedDate={publishedDate}
            onChangeValue={(newDate) => changePublishedDate(newDate)}
            minDate={new Date()}
          />
        </FieldContainer>
        <ModalFooter>
          <MacButton variant="primary" onClick={savePolicy}>
            Save
          </MacButton>
          <MacButton onClick={onClose} variant="secondary">
            Cancel
          </MacButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
