import { Filter } from "./SearchFilter.type";
import { PoliciesFilter } from "./PoliciesFilter";

export type SearchFilterCurbZoneProps = {
  filter: Filter;
  onChangeFilter: (input: Filter) => void;
};

export const SearchFilterCurbZone = ({ filter, onChangeFilter }: SearchFilterCurbZoneProps) => {
  const changeFilter = (input: Pick<Filter, "selectedPolicies">) => {
    onChangeFilter({ ...filter, ...input });
  };

  return (
    <div className="flex ml-2">
      <PoliciesFilter selectedPolicies={filter.selectedPolicies} onChange={changeFilter} />
    </div>
  );
};
