import clsx from "clsx";
import { forwardRef } from "react";

export type DialogFooterProps = React.ComponentProps<"div"> & {
  align?: "right" | "left";
};

export const ModalFooter = forwardRef<HTMLDivElement, DialogFooterProps>(
  ({ align = "right", children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx("space-x-3", "bg-default py-4", align === "right" && "text-right", props.className)}
      >
        {children}
      </div>
    );
  },
);

ModalFooter.displayName = "ModalFooter";
