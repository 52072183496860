import * as React from "react";
import { TimeSpanInput } from "types";
import clsx from "clsx";
import { callAll } from "helper/utils";
import { TimeSpanModal } from "./time-span-modal";
import { InputButtonProps } from "components/form-control/input-button";
import { FieldStatus } from "components/form-control/field.type";
import { InputButton } from "components/form-control";
import useToggle from "hooks/use-toggle";
import { formatDate } from "helper/date-time";
import { TimeSpanComponentProps } from "./time-span.type";

export interface TimeSpanProps extends InputButtonProps, Pick<TimeSpanComponentProps, "options" | "label"> {
  status?: FieldStatus;
  timeSpan: TimeSpanInput;
  onChangeValue?: (timeSpan: TimeSpanInput) => void;
  className?: string;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
}

export const TimeSpanItem = ({
  onChangeValue,
  timeSpan,
  className,
  label,
  options,
  readonly = false,
  ...buttonProps
}: TimeSpanProps) => {
  const [showPopup, setShowPopup] = useToggle();

  const onApply = (input: TimeSpanInput) => {
    if (onChangeValue) {
      onChangeValue(input);
    }
    setShowPopup();
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <InputButton
          className={clsx(
            "border-solid border-primary form-input focus-visible:outline-none focus:outline-none flex items-center min-w-0 bg-transparent w-full h-full",
            className,
          )}
          {...buttonProps}
          onClick={callAll(buttonProps.onClick, setShowPopup)}
          disabled={readonly}
        >
          <TimeSpanContent timeSpan={timeSpan} options={options} />
        </InputButton>
      </div>

      {showPopup && (
        <TimeSpanModal
          label={label}
          isOpen={showPopup}
          timeSpanData={timeSpan}
          onApply={onApply}
          onDismiss={setShowPopup}
          options={options}
        />
      )}
    </>
  );
};

export const TimeSpanContent = ({ options, timeSpan }: TimeSpanProps) => {
  return (
    <div className="flex flex-col text-sm font-medium">
      {options?.showSelectDate && (
        <>
          <span className="h-5 flex-1 truncate text-left">
            Start date: {timeSpan?.startDate && formatDate(timeSpan.startDate, { format: "d MMM yyyy" })}{" "}
            {timeSpan?.timeOfDayStart && timeSpan.timeOfDayStart}
          </span>
          <span className="h-5 flex-1 truncate text-left">
            End date: {timeSpan?.endDate && formatDate(timeSpan.endDate, { format: "d MMM yyyy" })}{" "}
            {timeSpan?.timeOfDayEnd && timeSpan.timeOfDayEnd}
          </span>
        </>
      )}
      {options?.showDesignatedPeriod && (
        <span className="h-5 flex-1 truncate text-left">Designated Period: {timeSpan?.designatedPeriod}</span>
      )}
      {options?.showDesignatedPeriodExcept && (
        <span className="h-5 flex-1 truncate text-left">
          Designated Period Except:{" "}
          {typeof timeSpan?.designatedPeriodExcept === "boolean" && String(timeSpan.designatedPeriodExcept)}
        </span>
      )}
      <span className="h-5 flex-1 truncate text-left">Days of week: {timeSpan.daysOfWeek?.join(", ")}</span>
      <span className="h-5 flex-1 truncate text-left">Days of month: {timeSpan.daysOfMonth?.join(", ")}</span>
      <span className="h-5 flex-1 truncate text-left">Month: {timeSpan.months?.join(", ")}</span>
    </div>
  );
};
