import * as React from "react";
import { Transition, Dialog, TransitionChild, DialogPanel } from "@headlessui/react";
import { Fragment } from "react";
import { FILES_ICON } from "configs/files";
import { BackModalIcon, DownloadIcon } from "components/icons";
import { noop } from "helper/utils";
import { Decree } from "types/decree.type";

export type DescriptionModalProps = {
  decree: Decree;
  closeModal: () => void;
};
export const DecreeModal = ({ decree, closeModal }: DescriptionModalProps) => {
  const height = window.innerHeight - 110;
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={noop}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-title bg-opacity-90" />
        </TransitionChild>
        <div className="fixed inset-0 flex flex-col pt-5">
          {decree.publicUrl ? (
            <>
              <div className="w-full z-20">
                <div className="flex items-center justify-between px-20 h-21">
                  <div className="flex items-center hover:cursor-pointer" onClick={closeModal} role="none">
                    <BackModalIcon className="mr-7" />
                    <div className="flex items-end">
                      <img src={FILES_ICON["pdf"]} alt="Numéro AM" className="mr-1 h-4" />
                      <span className="truncate text-subtitle text-white">{decree.name}</span>
                    </div>
                  </div>
                  <a
                    download={decree.publicUrl}
                    href={decree.publicUrl}
                    className="focus:outline-none flex items-center hover:cursor-pointer"
                  >
                    <DownloadIcon className="hover:cursor-pointer w-4 h-4" />
                  </a>
                </div>
              </div>

              <div className="overflow-y-auto">
                <div className="flex min-h-full justify-center text-center">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <DialogPanel className="max-w-4xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all object-contain">
                      <embed src={decree.publicUrl} width="700" height={height} />
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </>
          ) : (
            <div>Fichier n'existe pas</div>
          )}
        </div>
      </Dialog>
    </Transition>
  );
};
