import clsx from "clsx";
import { typography } from "../typography";
import { hasClassName } from "helper/check-class";
import { DialogPanel, DialogTitle } from "@headlessui/react";
import { forwardRef } from "react";

export type DialogContentProps = {
  header: React.ReactNode;
} & React.ComponentProps<"div">;

export const ModalContent = forwardRef<HTMLDivElement, DialogContentProps>(({ header, children, ...props }, ref) => {
  return (
    <DialogPanel
      ref={ref}
      className={clsx(
        "bg-default text-left px-4 py-6 font-sans transform transition-all md:px-6 lg:px-8",
        !hasClassName(props.className, "rounded-") && "rounded-md",
        !hasClassName(props.className, "w-") && "w-full",
        !hasClassName(props.className, "max-w-") && "max-w-sm md:max-w-md lg:max-w-2xl",
        props.className,
      )}
    >
      <DialogTitle as="div" className={clsx("mb-4", typography.h2)}>
        {header}
      </DialogTitle>
      {children && (
        <div className="mt-2">
          <div className={typography.h4}>{children}</div>
        </div>
      )}
    </DialogPanel>
  );
});

ModalContent.displayName = "ModalContent";
