import { useAuth0 } from "@auth0/auth0-react";
import { auth0Config } from "../configs/auth-config";
import { useMemo } from "react";
import { ERole } from "store/auth/auth.type";

export const useUser = () => {
  const { user, isLoading } = useAuth0();

  const userRoles = useMemo((): ERole[] => {
    if (user) {
      return user[`${auth0Config.namespace}/roles`];
    }
    return [];
  }, [user]);

  return {
    isLoading,
    userRoles,
  };
};
