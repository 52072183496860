/** Describes a side of the road. */
export type Parite = "Pair" | "Impair";

/** Pseudo-boolean type to represent presence of road signs/lines. */
export type Signalisation = "Presente" | "Absente";

export enum DaysOfWeek {
  Mo = "Mo",
  Tu = "Tu",
  We = "We",
  Th = "Th",
  Fr = "Fr",
  Sa = "Sa",
  Su = "Su",
}
