export type FieldStatus = "warning" | "error" | "success" | "default" | "primary";
export type FieldLayout = "vertical" | "horizontal";
export const borderStatus: Record<FieldStatus, string> = {
  error: "border-red-500",
  warning: "border-yellow-500",
  success: "border-green-500",
  default: "",
  primary: "border-primary",
};
export const textStatus: Record<FieldStatus, string> = {
  error: "text-red-500",
  warning: "text-yellow-500",
  success: "text-green-500",
  default: "text-gray-600",
  primary: "",
};
