import { AddIcon } from "assets";
import { IconButton } from "components";
import { FieldContainer, FormInput } from "components/form-control";
import { DeleteIcon } from "components/icons";
import { useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { City, CityInput } from "types";

interface AddDeviceIdsProps {
  city?: City;
}

export const AddDeviceIds = ({ city }: AddDeviceIdsProps) => {
  const { control, register, getValues } = useFormContext<CityInput>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "deviceIds",
  });
  useEffect(() => {
    if (city) {
      const devices = city?.details?.dataProvider?.deviceIds?.map((device: string) => {
        return { device };
      });
      if (devices?.length) {
        replace(devices);
      }
    }
  }, [city, replace]);

  return (
    <>
      <div className="w-full flex flex-col gap-y-2 ">
        <div className="flex flex-row gap-x-2">
          <h1 className="font-bold text-sm">Devices</h1>
          <IconButton className="w-6 h-6" onClick={() => append({ device: "" })}>
            <AddIcon className="w-4 h-4 text-gray-400" stroke="black" />
          </IconButton>
        </div>
        <div className="grid grid-cols-2 mb-2 gap-2" key={"device-container"}>
          {fields.map((_, id: number) => (
            <>
              <div key={`device-formcontainer-${id}`} className="w-full flex flex-row gap-x-2 items-center">
                <FieldContainer className="basis-10/12 mb-2 border-b">
                  <FormInput
                    label={`Device ${id + 1}`}
                    className="col-span-3 border-none"
                    {...register(`deviceIds.${id}.device`)}
                    name={`deviceIds[${id}].device`}
                    defaultValue={getValues(`deviceIds.${id}.device`)}
                  />
                </FieldContainer>
                <IconButton key={`delete-button-${id}`} className="w-6 h-6 basis-1/12" onClick={() => remove(id)}>
                  <DeleteIcon key={`svg-delete-${id}`} className="w-4 h-4 text-gray-400" stroke="black" />
                </IconButton>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
