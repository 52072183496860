import { createContext, ReactNode, useEffect, useState, Dispatch, SetStateAction, useRef, useMemo } from "react";
import { AllCityResponse, City, CityResponse } from "../types";
import { useQuery } from "@apollo/client";
import { QUERY_CITY, QUERY_CITY_LIST } from "../services/web/api-cities.const";
import { getQueryParams, noop } from "../helper/utils";
import { INITIAL_VIEW_STATE } from "../constants/map.const";
import { useSetQueryParams } from "../hooks/use-set-query-params";
import { findObject } from "../helper/array";
import { ViewState } from "react-map-gl";
import { saveItem, loadItem, CITY_ID } from "helper/storage";

export const CityContext = createContext<{
  currentCity: City | null;
  handleSelectCity: (input: City | string) => void;
  cityList: City[];
  setCityList: Dispatch<SetStateAction<City[]>>;
  viewport: ViewState;
  setViewport: Dispatch<SetStateAction<ViewState>>;
  isCityListLoading: boolean;
}>({
  currentCity: null,
  handleSelectCity: noop,
  cityList: [],
  setCityList: noop,
  viewport: INITIAL_VIEW_STATE,
  setViewport: noop,
  isCityListLoading: false,
});

export type CityContextProviderProps = {
  children: ReactNode;
};
export type CityParamType = {
  cityID?: string;
  view?: string;
};

export const CityContextProvider = ({ children }: CityContextProviderProps) => {
  const preSelectedCityId = loadItem(CITY_ID);
  const { view, cityID } = useMemo(() => getQueryParams<CityParamType>(), []);
  const [currentCity, setCurrentCity] = useState<City | null>(null);
  const [cityList, setCityList] = useState<City[]>([]);
  const setQueryParams = useSetQueryParams();
  const currentViewport = useRef<ViewState | null>();
  const isRefresh = useRef(true);

  const [viewport, setViewport] = useState<ViewState>(INITIAL_VIEW_STATE);

  const { data: { city } = {} } = useQuery<CityResponse>(QUERY_CITY, {
    skip: !preSelectedCityId && !cityID,
    variables: { cityId: preSelectedCityId || cityID },
  });
  const { data: cityListRes, loading: isCityListLoading } = useQuery<AllCityResponse>(QUERY_CITY_LIST);

  useEffect(() => {
    if (!view || currentViewport.current) {
      return;
    }
    const arr = view.split(",");
    const latitude = Number(arr[0]);
    const longitude = Number(arr[1]);
    const zoom = Number(arr[2]);

    currentViewport.current = { ...INITIAL_VIEW_STATE, latitude, longitude, zoom };
    setViewport({ ...INITIAL_VIEW_STATE, latitude, longitude, zoom });
  }, [view]);

  useEffect(() => {
    if (city) {
      saveItem(CITY_ID, city.id);
      setCurrentCity(city);
      if (!isRefresh.current || !view) {
        setViewport((prevState) => ({
          ...prevState,
          latitude: city.position[1],
          longitude: city.position[0],
          zoom: INITIAL_VIEW_STATE.zoom,
        }));
      }
    }
  }, [city, cityID, view]);

  useEffect(() => {
    if (cityListRes) {
      setCityList(cityListRes.cities || []);
    }
  }, [cityListRes]);

  const handleSelectCity = (input: City | string) => {
    let newCity: City;
    if (typeof input === "string") {
      const fCity = findObject(cityList, "id", input);
      newCity = cityList[fCity];
    } else {
      newCity = input;
    }
    setQueryParams({ cityID: newCity.id });
    saveItem(CITY_ID, newCity.id);
    setCurrentCity(newCity);
    isRefresh.current = false;
    setViewport((prevState) => ({
      ...prevState,
      latitude: newCity.position[1],
      longitude: newCity.position[0],
      zoom: INITIAL_VIEW_STATE.zoom,
    }));
  };

  return (
    <CityContext.Provider
      value={{
        currentCity,
        handleSelectCity,
        cityList,
        setCityList,
        viewport,
        setViewport,
        isCityListLoading,
      }}
    >
      {children}
    </CityContext.Provider>
  );
};
