import { useCallback, useContext, useState } from "react";
import { AppMode } from "types";
import { MapToolBoxEntry } from "components/map-tool-box-entry";
import { Confirm, Label } from "components";
import { toolboxIcons } from "./map-tool-box-entry.const";
import { CityContext } from "contexts/city-context-provider";
import { MapViewProps } from "../curb-zone.type";

const MapToolBox = ({ appMode: currentAppMode, setAppMode }: MapViewProps) => {
  const { currentCity } = useContext(CityContext);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const selectMode = useCallback(
    (selectedMode: AppMode) => {
      if (!currentCity) {
        setOpenWarning(true);
        return;
      }
      setAppMode(selectedMode);
    },
    [currentCity, setAppMode],
  );
  const handleCloseConfirm = useCallback(() => {
    setOpenWarning(false);
  }, []);
  return (
    <div
      className="origin-top-right p-1 w-13 rounded-md z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      {toolboxIcons.map((item) => (
        <div key={item.name} className="mb-2 last:mb-0">
          <Label className="text-lg font-semibold font-sans">{item.name}</Label>
          <div>
            {item.groups.map((icon) => (
              <MapToolBoxEntry
                key={`maptoolbox:${icon.mode}`}
                text={icon.text}
                mode={icon.mode as AppMode}
                icon={icon.icon}
                onSelect={selectMode}
                selected={currentAppMode === icon.mode}
              />
            ))}
          </div>
        </div>
      ))}
      {openWarning && (
        <Confirm title="Warning" content="Please select a city" onOk={handleCloseConfirm} butOkName="Close" />
      )}
    </div>
  );
};

export default MapToolBox;
