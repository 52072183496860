import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MacButton } from "components";
import { Modal, ModalContent, ModalFooter } from "components";
import { FieldContainer, FormInput, DropdownSelect, Textarea, CalendarSelector } from "components/form-control";
import { ParkingAngle } from "./curb-zone.const";
import { isNil } from "helper/utils";
import { CurbZonePropertiesPromptProps } from "./curb-zone.type";
import { PolicyView } from "./policy-view";
import { CurbZone } from "types/curb-zone.type";
import { calculateArea } from "helper/format-data";
import { Feature, LineString } from "geojson";

export const CurbZonePropertiesPrompt = ({
  registerProperties,
  prefill,
  cancel,
  id,
}: CurbZonePropertiesPromptProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CurbZone>({
    defaultValues: { ...prefill?.properties },
    mode: "onChange",
  });
  const [curbPolicyIds, setCurbPolicyIds] = useState<string[]>([]);
  const [publishedDate, setPublishedDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());

  useEffect(() => {
    if (prefill?.properties?.curbPolicies && prefill?.properties?.curbPolicies.length) {
      const currentCurbPolicies = prefill.properties.curbPolicies.map((item) => item.curbPolicyId) as string[];
      setCurbPolicyIds(currentCurbPolicies);
    }
    if (prefill?.properties?.publishedDate) {
      setPublishedDate(new Date(prefill.properties.publishedDate));
    }
    if (prefill?.properties?.startDate) {
      setStartDate(new Date(prefill.properties.startDate));
    }
  }, [prefill]);

  const isEdit = !isNil(id);
  const dismiss = () => cancel(isEdit);
  const saveParking = (promptedProperties: CurbZone) => {
    const newParkingProperty = { ...promptedProperties, publishedDate, startDate };
    newParkingProperty.curbPolicyIds = curbPolicyIds;
    registerProperties(newParkingProperty);
  };

  const calculateCurbZoneProperties = (value: string) => {
    const curbZoneFeature = {
      ...prefill,
      properties: { ...prefill?.properties, width: Number(value) },
    } as Feature<LineString, CurbZone>;
    const { calculatedArea, calculatedSpaces } = calculateArea(curbZoneFeature);

    setValue("calculatedArea", calculatedArea);
    setValue("calculatedSpaces", calculatedSpaces);
  };
  return (
    <Modal isOpen className="my-0 items-center h-full">
      <ModalContent
        className="w-128 rounded-2xl overflow-auto max-h-[calc(100vh-5em)] px-3 pb-0 lg:px-5"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{isEdit ? "Edit" : "Add"} Curb zone</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Curb zone informations</p>
          </>
        }
      >
        <form onSubmit={handleSubmit(saveParking)}>
          <PolicyView curbPolicies={curbPolicyIds} setCurbPolicies={setCurbPolicyIds} />
          <FieldContainer className="mt-8" helpText={errors.parkingAngle ? "This is required" : undefined}>
            <DropdownSelect
              label="Forme"
              className="border-primary border-solid"
              value={prefill?.properties?.parkingAngle}
              options={Object.values(ParkingAngle)}
              {...register("parkingAngle")}
              defaultValue={ParkingAngle.parallel}
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.streetName ? "This is required" : undefined}
            status={errors.streetName ? "error" : "primary"}
          >
            <FormInput
              label="Nom voie"
              {...register("streetName", { required: true })}
              status={errors.streetName ? "error" : "primary"}
              defaultValue={prefill?.properties?.streetName}
              tabIndex={0}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              label="Début du numéro de rue"
              {...register("streetNumberStart")}
              status={errors.streetName ? "error" : "primary"}
              defaultValue={prefill?.properties?.streetNumberStart}
              tabIndex={0}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              label="Fin du numéro de rue"
              {...register("streetNumberEnd")}
              defaultValue={prefill?.properties?.streetNumberEnd}
              tabIndex={0}
            />
          </FieldContainer>
          <FieldContainer readOnly helpText={errors.length ? "This is required" : undefined}>
            <FormInput
              label="Longueur"
              {...register("length", { valueAsNumber: true })}
              readOnly
              status={errors.length ? "error" : "primary"}
              defaultValue={prefill?.properties?.length}
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.width ? "This is required" : undefined}
            status={errors?.width ? "error" : "primary"}
          >
            <FormInput
              label="Largeur"
              {...register("width", { valueAsNumber: true })}
              status={errors.width ? "error" : "primary"}
              defaultValue={prefill?.properties?.width}
              onChangeValue={calculateCurbZoneProperties}
            />
          </FieldContainer>
          <FieldContainer helpText={errors?.calculatedArea ? "This is required" : undefined}>
            <FormInput
              label="Surface calculée"
              {...register("calculatedArea", { valueAsNumber: true })}
              readOnly
              status={errors.calculatedArea ? "error" : "primary"}
              defaultValue={prefill?.properties?.calculatedArea}
            />
          </FieldContainer>
          <FieldContainer helpText={errors?.calculatedSpaces ? "This is required" : undefined}>
            <FormInput
              label="Places calculées"
              {...register("calculatedSpaces", { valueAsNumber: true })}
              status={errors.calculatedSpaces ? "error" : "primary"}
              defaultValue={prefill?.properties?.calculatedSpaces}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              label="Identifiant externe"
              {...register("externalId")}
              status={errors.externalId ? "error" : "primary"}
              defaultValue={prefill?.properties?.externalId}
            />
          </FieldContainer>
          <FieldContainer helpText={errors?.publishedDate ? "This is required" : undefined}>
            <CalendarSelector
              label="Publish date"
              selectedDate={publishedDate}
              onChangeValue={(newDate) => setPublishedDate(newDate)}
            />
          </FieldContainer>
          <FieldContainer helpText={errors?.publishedDate ? "This is required" : undefined}>
            <CalendarSelector
              label="Start date"
              selectedDate={startDate}
              onChangeValue={(newDate) => setStartDate(newDate)}
            />
          </FieldContainer>
          <FieldContainer>
            <Textarea
              label="Commentaire"
              {...register("note")}
              status={errors.note ? "error" : "primary"}
              defaultValue={prefill?.properties?.note}
            />
          </FieldContainer>

          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton onClick={dismiss} variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
