import clsx from "clsx";
import { MapType } from "types/map.type";
import * as React from "react";
import { DropdownSelect } from "components/form-control";

export type SelectMapTypeTypeProps = {
  mapType: MapType;
  onSelectMapType: (value: MapType) => void;
  className?: string;
};

const mapTypeValues: MapType[] = [MapType.MAPBOX, MapType.ESRI_MAP, MapType.GOOGLE_MAP, MapType.YANDEX_MAP];

export const SelectMapType = ({ mapType = MapType.MAPBOX, onSelectMapType, className }: SelectMapTypeTypeProps) => {
  return (
    <div className={clsx("w-40 transition-opacity h-11 bg-default p-1 mt-16 mb-2 rounded-md shadow-lg", className)}>
      <DropdownSelect
        className="border-primary border-solid"
        value={mapType}
        options={mapTypeValues}
        onChangeValue={(value) => onSelectMapType(value as MapType)}
      />
    </div>
  );
};
