import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { UserIcon } from "../icons";
import { useApolloClient } from "@apollo/client";
import { ImageLazyLoading } from "../image-lazy-loading";
import { useLogout } from "store/hooks";

export const UserView = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const { user } = useAuth0();
  const logout = useLogout();
  const client = useApolloClient();

  const handleLogout = async () => {
    await client.resetStore();
    await logout();
  };

  return (
    <div className="relative inline-flex p-13px">
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-end">
          <MenuButton
            ref={trigger}
            className="inline-flex justify-center items-center"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
          >
            <UserIcon className="w-6 h-6 rounded-full" />
          </MenuButton>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            className="absolute right-0 mt-2 origin-top-right divide-y divide-primary divide-opacity-30
           shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20 w-60 bg-primary text-center rounded-b-2xl"
          >
            <div className="px-1 py-2 flex flex-col items-center">
              <div className="px-1 py-2">
                <MenuItem>
                  <ImageLazyLoading
                    src={user?.picture}
                    className="space-y-2 w-74 h-74 flex items-center rounded-full"
                    alt="avatar"
                  />
                </MenuItem>
              </div>
              <div className="py-2">
                <MenuItem>
                  <span className="py-2 text-white text-xs">{user?.name}</span>
                </MenuItem>
              </div>
            </div>
            <div className="px-1 py-4">
              <MenuItem>
                <button className="text-white hover:text-red-700 text-medium" onClick={handleLogout}>
                  Logout
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
};
