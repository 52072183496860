import { useEffect, useState } from "react";
import { AppMode } from "../types";

/**
 * Only for multiEdit mode of Map
 * **/
export const usePressKeyboard = ({ appMode }: { appMode: AppMode }) => {
  const [isSpaceBarPressed, setIsSpaceBarPressed] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [isAltPressed, setIsAltPressed] = useState(false);

  useEffect(() => {
    /**
     * Press SPACE bar to move the map
     * **/
    const handleKeyDownSpaceBar = (event: any) => {
      if (event.keyCode === 32 && !isSpaceBarPressed) {
        setIsSpaceBarPressed(true);
        setIsAltPressed(false);
        setIsShiftPressed(false);
      }
    };
    const handleKeyUpSpaceBar = (event: any) => {
      if (event.keyCode === 32 && isSpaceBarPressed) {
        setIsSpaceBarPressed(false);
      }
    };

    if (!["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode)) {
      window.removeEventListener("keydown", handleKeyDownSpaceBar);
      window.removeEventListener("keyup", handleKeyUpSpaceBar);
      return;
    }

    window.addEventListener("keydown", handleKeyDownSpaceBar);
    window.addEventListener("keyup", handleKeyUpSpaceBar);

    return () => {
      window.removeEventListener("keydown", handleKeyDownSpaceBar);
      window.removeEventListener("keyup", handleKeyUpSpaceBar);
      setIsAltPressed(false);
      setIsShiftPressed(false);
      setIsSpaceBarPressed(false);
    };
  }, [appMode, isSpaceBarPressed]);

  useEffect(() => {
    /**
     * Press Alt key to select multi object
     * Press Shift key to select single object
     * **/
    const handleClickWithKeyboardUpMultiEdit = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setIsAltPressed(false);
      }
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    const handleClickWithKeyboardDownMultiEdit = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setIsAltPressed(true);
        if (isShiftPressed) {
          setIsShiftPressed(false);
        }
      }
      if (event.key === "Shift") {
        setIsShiftPressed(true);
        if (isAltPressed) {
          setIsAltPressed(false);
        }
      }
    };

    if (!["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode)) {
      window.removeEventListener("keydown", (evt) => handleClickWithKeyboardDownMultiEdit(evt));
      window.removeEventListener("keyup", (evt) => handleClickWithKeyboardUpMultiEdit(evt));
      return;
    }

    window.addEventListener("keydown", (evt) => handleClickWithKeyboardDownMultiEdit(evt));
    window.addEventListener("keyup", (evt) => handleClickWithKeyboardUpMultiEdit(evt));
    return () => {
      window.removeEventListener("keydown", (evt) => handleClickWithKeyboardDownMultiEdit(evt));
      window.removeEventListener("keyup", (evt) => handleClickWithKeyboardUpMultiEdit(evt));
      setIsAltPressed(false);
      setIsShiftPressed(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMode]);

  return {
    isSpaceBarPressed,
    isShiftPressed,
    isAltPressed,
  };
};
