import { flattenedArray } from "./array";

const regexMap = new Map<string, RegExp>();

export const hasClassName = (className: string | undefined, ...preFixes: string[]) => {
  if (!className) {
    return false;
  }
  const arrayClass = preFixes.join(",");

  const cachedRegex = regexMap.get(arrayClass);
  if (cachedRegex) {
    return cachedRegex.test(className);
  }

  const regexPattern = flattenedArray(preFixes.map((prefix) => [`^${prefix}`, `\\s${prefix}`])).join("|");

  const regex = new RegExp(regexPattern);

  regexMap.set(arrayClass, regex);

  return regex.test(className);
};
