import { useState, ChangeEvent, useMemo } from "react";
import { useForm } from "react-hook-form";
import { IPrivateParking } from "types";
import { IconButton, InputMoney, Label, MacButton } from "components";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { Checkbox, FieldContainer, FormInput } from "components/form-control";
import { InputNumber } from "components/form-control/input-number";
import { useDebounce } from "hooks/lib-ui";
import { Autocomplete } from "components/form-control";
import { usePlacesQuery } from "services/web/map.queries";
import { addressPosition } from "services/web/mapbox.service";
import useToggle from "hooks/use-toggle";
import { Transition } from "@headlessui/react";
import { AddIcon, ArrowIcon, DeleteIcon, EditIcon } from "components/icons";
import clsx from "clsx";
import { noop } from "helper/utils";
import { PrivateParkingModalState } from "modules/offstreet-parking/private-parking.const";
import { TimeSpanListing } from "components/time-span";

interface ICoordinates {
  latitude: number;
  longitude: number;
}

interface OffstreetParkingPromptProps {
  coordinates?: ICoordinates;
  privateParking: IPrivateParking;
  onSave: (input: IPrivateParking) => void;
  parkingModalState: keyof typeof PrivateParkingModalState;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof PrivateParkingModalState>>;
  onClose: () => void;
  setPrivateParkingDetail: (value: IPrivateParking) => void;
}

export const PrivateParkingModal = ({
  coordinates,
  privateParking,
  parkingModalState,
  setParkingModalState,
  onClose,
  onSave,
  setPrivateParkingDetail,
}: OffstreetParkingPromptProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPrivateParking>({
    defaultValues: privateParking,
    mode: "onChange",
  });
  const [toggleMoreOptions, setToggleMoreOptions] = useToggle(false);
  const [value, setValue] = useState<string>(privateParking?.address);
  const [otherAddresses, setOtherAddresses] = useState<string[]>(privateParking?.otherAdresses || []);
  const [openingHours, setOpeningHours] = useState(privateParking.openingHours || []);
  const [selectedIndexOtherAddress, setSelectedIndexOtherAddress] = useState<number | undefined>();

  const currentAddress = useMemo(() => {
    if (typeof selectedIndexOtherAddress === "number") {
      return otherAddresses[selectedIndexOtherAddress];
    }
    return value;
  }, [value, selectedIndexOtherAddress, otherAddresses]);

  const searchString = useDebounce(currentAddress);

  const handleSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setValue(value);
  };
  const { data, isLoading } = usePlacesQuery({
    keyWord: searchString,
    coordinates: coordinates,
  });

  const listPlace = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((item: addressPosition) => {
        return {
          value: `${item.latitude}:${item.longitude}`,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const handleSelectPlace = (value: string, label: string) => {
    setValue(label);
    const addressPoint = value.split(":");
    setPrivateParkingDetail({
      ...privateParking,
      location: { type: "Point", coordinates: [Number(addressPoint[0]), Number(addressPoint[1])] },
    });
  };

  const handleAddNewAddress = () => {
    setOtherAddresses((prev) => [...prev, ""]);
  };

  const handleRemoveOtherAddress = (idx: number) => {
    const addresses = otherAddresses.filter((_, index) => index !== idx);
    setOtherAddresses(addresses);
  };

  const handleClose = () => {
    setOtherAddresses([]);
    onClose();
  };

  const handleMinimizeModal = () => {
    const rootDocument = document.getElementById("root");
    rootDocument?.removeAttribute("inert");
    setParkingModalState(PrivateParkingModalState.MINIMIZE);
  };

  const handleShowModal = () => {
    setParkingModalState(PrivateParkingModalState.SHOW);
  };

  const saveParking = (input: IPrivateParking) => {
    const formattedInput = {
      ...input,
      maxHeight: Number(input.maxHeight),
      levels: Number(input.levels),
      otherAdresses: otherAddresses.filter((item) => !!item).map((item) => item),
      location: privateParking.location,
      openingHours: openingHours,
      options: {
        ...input.options,
        evChargers: Number(input.options?.evChargers),
        disablesPlaces: Number(input?.options?.disablesPlaces),
        motorcyclePlaces: Number(input?.options?.motorcyclePlaces),
        bicyclePlaces: Number(input.options?.bicyclePlaces),
      },
    };
    onSave({ ...formattedInput });
    setOtherAddresses([]);
  };

  const modalMinimize = parkingModalState === PrivateParkingModalState.MINIMIZE;

  return (
    <Modal
      isOpen
      className={modalMinimize ? "my-0" : "my-10 overflow-hidden"}
      overlayClassName={modalMinimize ? "hidden" : "block"}
      wrapperContentClassName={
        modalMinimize
          ? "inset-auto absolute bottom-12 right-4 w-fit px-2 py-1 rounded-md shadow-md bg-white animate-bounce"
          : "fixed inset-0"
      }
    >
      <form onSubmit={handleSubmit(saveParking)}>
        <div
          role="button"
          tabIndex={0}
          className={clsx(
            "flex gap-2 items-center text-sm font-medium cursor-pointer",
            modalMinimize ? "block" : "hidden",
          )}
          onKeyDown={noop}
          onClick={handleShowModal}
        >
          <span>Back to edit private parking</span>
          <ArrowIcon className="w-3 h-3 rotate-90" />
        </div>
        <div className={modalMinimize ? "hidden" : "block"}>
          <ModalContent
            className="w-128 h-[calc(100vh-5em)] rounded-2xl overflow-auto px-3 pb-0 lg:px-5"
            header={
              <>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {privateParking.id ? "Edit " : "Add "} a private parking
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Private Parking information</p>
              </>
            }
          >
            <FieldContainer
              helpText={errors.name ? "This is required" : undefined}
              status={errors.name ? "error" : "primary"}
            >
              <FormInput
                {...register("name", { required: true })}
                status={errors.name ? "error" : "primary"}
                label="Parking Name"
                defaultValue={privateParking.name}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.address ? "This is required" : undefined}
              status={errors.address ? "error" : "primary"}
              className="mb-2"
            >
              <Autocomplete
                panelClassName="shadow-md absolute z-50"
                label="Parking Address"
                variant="underline"
                {...register("address", { required: true })}
                onChangeValue={handleSelectPlace}
                value={value}
                onChange={handleSearch}
                optionData={listPlace}
                isLoading={isLoading}
                status={errors.address ? "error" : "primary"}
                onClick={() => setSelectedIndexOtherAddress(undefined)}
              />
            </FieldContainer>
            <FieldContainer className="flex gap-2">
              <Label className="text-base font-semibold">
                Location:{" "}
                {privateParking?.location && (
                  <span
                    role="button"
                    tabIndex={0}
                    className="text-blue-500 font-medium cursor-pointer"
                    onKeyDown={noop}
                    onClick={handleMinimizeModal}
                  >
                    {privateParking?.location.coordinates.toString()}
                  </span>
                )}
              </Label>
              <EditIcon className="w-5 h-5 cursor-pointer fill-primary" onClick={handleMinimizeModal} />
            </FieldContainer>
            <FieldContainer className="flex flex-col mb-8">
              <Label className="text-base">Other Addresses</Label>
              {otherAddresses.map((item, index) => (
                <div className="flex" key={`otherAddresses_${index}`}>
                  <div className="flex-1">
                    <Autocomplete
                      panelClassName="shadow-md absolute z-50"
                      className="mb-3"
                      placeholder={`Address ${index + 1}`}
                      variant="underline"
                      onChangeValue={(value, label) => {
                        const addresses = [...otherAddresses];
                        addresses[index] = label;
                        setOtherAddresses(addresses);
                      }}
                      onChange={(e) => {
                        const addresses = [...otherAddresses];
                        addresses[index] = e.target.value;
                        setOtherAddresses(addresses);
                      }}
                      optionData={listPlace}
                      isLoading={isLoading}
                      value={item}
                      onClick={() => setSelectedIndexOtherAddress(index)}
                    />
                  </div>
                  <IconButton className="w-6 h-6">
                    <DeleteIcon className="w-4 h-4" onClick={() => handleRemoveOtherAddress(index)} />
                  </IconButton>
                </div>
              ))}
              <IconButton className="w-8 h-8">
                <AddIcon className="h-6 w-6" fill="black" onClick={handleAddNewAddress} />
              </IconButton>
            </FieldContainer>
            <TimeSpanListing
              label="Opening hours"
              labelClassName="block leading-5 mb-1 text-sm text-base"
              timeSpans={openingHours}
              setTimeSpans={setOpeningHours}
              suffixClassName="w-8 h-8"
              suffixIcon={<AddIcon fill="black" className="h-6 w-6 cursor-pointer" />}
              options={{
                showDesignatedPeriod: true,
                showDesignatedPeriodExcept: true,
                showSelectDate: true,
                showTimeOfDay: true,
              }}
            />
            <FieldContainer
              helpText={errors?.totalPlaces ? "This must be a number" : undefined}
              status={errors.totalPlaces ? "error" : "primary"}
            >
              <InputNumber
                {...register("totalPlaces")}
                status={errors.totalPlaces ? "error" : "primary"}
                label="Parking capacity"
                min={0}
                defaultValue={privateParking.totalPlaces}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors?.pricePerHour ? "This must be a number" : undefined}
              status={errors?.pricePerHour ? "error" : "primary"}
            >
              <InputMoney
                min={0}
                label="Price per hour"
                {...register("pricePerHour")}
                status={errors?.pricePerHour ? "error" : "primary"}
                defaultValue={privateParking.pricePerHour}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.externalId ? "This is required" : undefined}
              status={errors.externalId ? "error" : "primary"}
            >
              <FormInput
                {...register("externalId")}
                status={errors.externalId ? "error" : "primary"}
                label="External ID"
                defaultValue={privateParking.externalId}
              />
            </FieldContainer>
            <FieldContainer>
              <InputNumber
                {...register("maxHeight")}
                label="Max Height"
                min={0}
                defaultValue={privateParking.maxHeight}
              />
            </FieldContainer>
            <FieldContainer>
              <InputNumber {...register("levels")} label="Levels" min={0} defaultValue={privateParking.levels} />
            </FieldContainer>
            <FieldContainer>
              <FormInput {...register("structure")} label="Structure" defaultValue={privateParking.structure} />
            </FieldContainer>
            <Transition
              show={toggleMoreOptions}
              enterFrom="transform scale-95 opacity-0 max-h-0"
              enterTo="transform scale-100 opacity-100 max-h-[1000px]"
              leaveFrom="transform scale-100 opacity-100 max-h-[1000px]"
              leaveTo="transform scale-95 opacity-0 max-h-0"
            >
              <div className="transition-all duration-500 overflow-hidden">
                <FieldContainer className="mt-8">
                  <InputNumber
                    {...register("options.evChargers")}
                    label="Number of EV Chargers"
                    min={0}
                    defaultValue={privateParking.options?.evChargers}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.disablesPlaces")}
                    label="Number of disabled spots"
                    min={0}
                    defaultValue={privateParking.options?.disablesPlaces}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.motorcyclePlaces")}
                    label="Number of places for motorcycles"
                    min={0}
                    defaultValue={privateParking.options?.motorcyclePlaces}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.bicyclePlaces")}
                    label="Number of places for Bicycle"
                    min={0}
                    defaultValue={privateParking.options?.bicyclePlaces}
                  />
                </FieldContainer>
                <FieldContainer className="mb-2">
                  <Checkbox
                    {...register("options.toilets")}
                    defaultChecked={privateParking.options?.toilets}
                    label="Has toilets"
                  />
                  <Checkbox
                    {...register("options.disabledAccess")}
                    label="Disabled persons access"
                    defaultChecked={privateParking.options?.disabledAccess}
                  />
                  <Checkbox
                    {...register("options.motorcycleAccess")}
                    label="Motorcycle Access"
                    defaultChecked={privateParking.options?.motorcycleAccess}
                  />
                  <Checkbox
                    {...register("options.bicycleAccess")}
                    label="Bicycle Access"
                    defaultChecked={privateParking.options?.bicycleAccess}
                  />
                </FieldContainer>
              </div>
            </Transition>
            <div
              className="flex gap-1 items-center"
              role="button"
              tabIndex={0}
              onKeyDown={noop}
              onClick={setToggleMoreOptions}
            >
              <span className="text-sm text-gray-900 cursor-pointer">
                {toggleMoreOptions ? "Hide" : "More options"}
              </span>
              <ArrowIcon
                className={clsx("transform", toggleMoreOptions ? "rotate-90" : "-rotate-90")}
                width={10}
                height={10}
              />
            </div>
            <ModalFooter>
              <MacButton type="submit" variant="primary">
                Save
              </MacButton>
              <MacButton onClick={handleClose} variant="secondary">
                Cancel
              </MacButton>
            </ModalFooter>
          </ModalContent>
        </div>
      </form>
    </Modal>
  );
};
