export const regimeParticulierValues = [
  "PAYANT",
  "PMR",
  "GRATUIT",
  "AUTOCAR",
  "TROTTINETTE",
  "LIVRAISON PERMANENTE",
  "LIVRAISON PÉRIODIQUE",
  "VÉLIB",
  "ÉLECTRIQUE",
  "TRANSPORT DE FOND",
  "MOTOS",
  "TAXI",
  "VÉLO",
  "POLICE",
  "DÉPOSE MINUTE",
  "DEUX ROUES",
  "AUTRES",
];

export enum ParkingAngle {
  parallel = "parallel",
  perpendicular = "perpendicular",
  angled = "angled",
}

export enum ETypePlace {
  PAID = "Paid",
  NOT_PAID = "Not paid",
}
export const listTypeOfPlace = [ETypePlace.PAID, ETypePlace.NOT_PAID];
export const defaultTimeOfDay = { from: "00:00", to: "00:00" };
