import { IconButton } from "components";
import { CalendarSelector, FieldContainer } from "components/form-control";
import { InputNumber } from "components/form-control/input-number";
import { AddIcon, DeleteIcon } from "components/icons";
import { add } from "date-fns";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CityInput } from "types";

export const DecreeAnalysisCountListing = () => {
  const { control, register, watch } = useFormContext<CityInput>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options.decreeAnalysisCount",
  });

  const addDecreeAnalysisItem = () => {
    const startDate = new Date();
    const endDate = add(new Date(), { years: 1 });
    append({ startDate, endDate, count: 0 });
  };

  return (
    <div className="w-full flex flex-col mt-6">
      <div className="flex flex-row mb-2">
        <h1 className="font-bold text-sm">Decree Analysis Count</h1>
        <IconButton className="w-6 h-6 ml-1" onClick={addDecreeAnalysisItem}>
          <AddIcon className="w-4 h-4 text-gray-400" stroke="black" />
        </IconButton>
      </div>
      <div className="grid grid-cols-1 mb-2 gap-2">
        {fields.map((item, idx) => {
          const maxDate = watch(`options.decreeAnalysisCount.${idx}.endDate`);
          const minDate = watch(`options.decreeAnalysisCount.${idx}.startDate`);

          return (
            <div key={`decree-analysis-item-${item.id}`} className="w-full flex flex-row gap-x-2 items-end">
              <FieldContainer className="basis-1/3 mb-2 border-b border-primary">
                <Controller
                  name={`options.decreeAnalysisCount.${idx}.startDate`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CalendarSelector
                      className="border-none"
                      label="Start date"
                      maxDate={maxDate}
                      selectedDate={value}
                      onChangeValue={(newDate) => onChange(newDate)}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer className="basis-1/3 mb-2 border-b border-primary">
                <Controller
                  name={`options.decreeAnalysisCount.${idx}.endDate`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CalendarSelector
                      className="border-none"
                      label="End date"
                      minDate={minDate}
                      selectedDate={value}
                      onChangeValue={(newDate) => onChange(newDate)}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer className="basis-1/3 mb-2 border-b">
                <InputNumber
                  {...register(`options.decreeAnalysisCount.${idx}.count`, { valueAsNumber: true })}
                  placeholder="Count"
                  min={0}
                  label="Count"
                  defaultValue={item.count}
                />
              </FieldContainer>
              <IconButton className="w-6 h-6 mb-2 basis-1/12" onClick={() => remove(idx)}>
                <DeleteIcon className="w-4 h-4 text-gray-400" stroke="black" />
              </IconButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};
