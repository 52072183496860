import {
  bearing,
  getCoord,
  getCoords,
  lineIntersect,
  lineString,
  point,
  polygon,
  segmentReduce,
  transformTranslate,
} from "@turf/turf";
import { distanceUnit } from "constants/mapbox.const";
import { Feature, LineString, Polygon } from "geojson";

export const lineToRectangle = (line: Feature<LineString>, thickness: number): Feature<Polygon> | null => {
  if (line === null) {
    return null;
  }

  const polygonCoords = segmentReduce<
    | {
        coordsTop: number[][];
        coordsBottom: number[][];
      }
    | undefined
  >(
    line.geometry as LineString,
    (accumulator, currentSegment) => {
      const point1 = point(getCoords(currentSegment as any)[0]);
      const point2 = point(getCoords(currentSegment as any)[1]);

      const angle = bearing(point1, point2);

      const offsetLine1 = transformTranslate(currentSegment as any, thickness, angle + 90, {
        units: distanceUnit,
      });
      const offsetLine2 = transformTranslate(currentSegment as any, -thickness, angle + 90, {
        units: distanceUnit,
      });

      const line1Coords = getCoords(offsetLine1);
      const line2Coords = getCoords(offsetLine2);

      // We check if it intersects with other line, if true we remove the two last points and replace them with the intersection points
      if ((accumulator as any).coordsTop.length > 0) {
        const oldLine1 = lineString((accumulator as any).coordsTop);
        const intersects = lineIntersect(oldLine1, offsetLine1);
        if (intersects.features.length > 0) {
          line1Coords[0] = getCoord(intersects.features[0]);
          (accumulator as any).coordsTop.pop();
        }
      }

      if ((accumulator as any).coordsBottom.length > 0) {
        const oldLine2 = lineString((accumulator as any).coordsBottom);
        const intersects = lineIntersect(oldLine2, offsetLine2);
        if (intersects.features.length > 0) {
          line2Coords[0] = getCoord(intersects.features[0]);
          (accumulator as any).coordsBottom.pop();
        }
      }
      (accumulator as any).coordsTop.push(...line1Coords);
      (accumulator as any).coordsBottom.push(...line2Coords);

      return accumulator;
    },
    { coordsTop: [], coordsBottom: [] },
  );

  if (polygonCoords === undefined) {
    return null;
  }

  const rectangleCoords = [
    ...polygonCoords.coordsTop,
    ...polygonCoords.coordsBottom.reverse(),
    polygonCoords.coordsTop[0],
  ];

  return polygon([rectangleCoords]);
};
