import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQueryListUserReport } from "./use-query-user-report";
import { CityContext } from "contexts/city-context-provider";
import { UserReportLayer } from "../layers/UserReportCompositeLayer";
import { getQueryParams } from "helper/utils";
import WebMercatorViewport from "viewport-mercator-project";
import { bbox } from "@turf/turf";
import { PaginatedUserReportArgs, UserReport } from "types/user-report.type";
import { SortDirection } from "components/data-table";
import useToggle from "hooks/use-toggle";

const paramsDefault: PaginatedUserReportArgs = {
  cityId: "",
  pageIndex: 1,
  pageSize: 10,
  sortField: "createdAt",
  sortOrder: SortDirection.ASC,
};

export const useUserReport = () => {
  const { currentCity, viewport, setViewport } = useContext(CityContext);
  const [userReportParams, setUserReportParams] = useState<PaginatedUserReportArgs>({
    ...paramsDefault,
    cityId: "",
  });
  const { data, isLoadingUserReports } = useQueryListUserReport(userReportParams);
  const [isOpenUserReport, setIsOpenUserReport] = useToggle(false);

  const [selectedUserReport, setSelectedUserReport] = useState<UserReport | null>(null);
  const wrapMapRef = useRef<any>();

  const userReportData = useMemo(() => {
    if (selectedUserReport) {
      return [selectedUserReport];
    }
    return [];
  }, [selectedUserReport]);

  const moveMapToSelectedUserReport = (userReport: UserReport) => {
    /**
     * move map to selected user report
     * **/
    const { view } = getQueryParams();
    if (userReport) {
      if (userReport.features.features.length > 0) {
        const [minLng, minLat, maxLng, maxLat] = bbox(userReport.features);
        const newViewport = new WebMercatorViewport({
          ...viewport,
          height: wrapMapRef.current?.clientHeight,
          width: wrapMapRef.current?.clientWidth,
        });
        const { longitude, latitude, zoom } = newViewport.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 2 },
        );
        setViewport({
          ...viewport,
          latitude,
          longitude,
          zoom: Math.min(zoom, 20),
          padding: {
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
          },
        });
      }
    } else {
      if (view) {
        const currentView = view?.split(",");
        setViewport((prevState) => ({
          ...prevState,
          latitude: Number(currentView[0]),
          longitude: Number(currentView[1]),
          zoom: Number(currentView[2]),
        }));
      }
    }
  };

  useEffect(() => {
    if (currentCity?.id) {
      setUserReportParams({ ...paramsDefault, cityId: currentCity.id });
    }
  }, [currentCity]);

  const handleSelectUserReport = (input: UserReport) => {
    if (input.id === selectedUserReport?.id) {
      setSelectedUserReport(null);
    } else {
      moveMapToSelectedUserReport(input);
      setSelectedUserReport({ ...input });
    }
  };

  const userReportLayer = new UserReportLayer({
    id: "user-report",
    data: userReportData,
  });

  return {
    handleSelectUserReport,
    selectedUserReport,
    isOpenUserReport,
    setIsOpenUserReport,
    userReportList: data,
    isLoadingUserReports,
    userReportLayer,
    wrapMapRef,
    setUserReportParams,
    userReportParams,
  };
};
