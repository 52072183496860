import { PolicyRuleInput } from "types";
import React from "react";
import { FieldContainer } from "components/form-control";
import { IconButton, Label } from "components";
import { DeleteIcon } from "components/icons";
import { AddIcon } from "assets";
import { RuleItem } from "./RuleItem";
import { defaultRule } from "../policies.const";

type RuleListingProps = {
  rules: PolicyRuleInput[];
  setRules: React.Dispatch<React.SetStateAction<PolicyRuleInput[]>>;
};
export const RuleListing = ({ rules = [], setRules }: RuleListingProps) => {
  const onChangeValue = (value: PolicyRuleInput, index: number) => {
    const newTimeSpans = [...rules];
    newTimeSpans[index] = { ...value };
    setRules(() => [...newTimeSpans]);
  };

  const addRules = () => {
    setRules([...rules, defaultRule]);
  };

  const removeRule = (index: number) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules([...newRules]);
  };

  return (
    <FieldContainer>
      <Label>Rules</Label>
      {rules.map((rule, ruleIndex) => (
        <div className="flex items-center" key={`rule-${ruleIndex}`}>
          <RuleItem
            onChangeValue={(input: PolicyRuleInput) => onChangeValue(input, ruleIndex)}
            rule={rule}
            name={`rule - ${ruleIndex}`}
          />
          <div className="mt-3">
            <IconButton className="w-6 h-6" onClick={() => removeRule(ruleIndex)}>
              <DeleteIcon className="w-4 h-4 text-gray-400" />
            </IconButton>
          </div>
        </div>
      ))}
      <div className="flex items-center">
        <IconButton className="w-6 h-6" onClick={addRules}>
          <AddIcon className="w-4 h-4 text-gray-400" />
        </IconButton>
      </div>
    </FieldContainer>
  );
};
