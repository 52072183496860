import * as React from "react";
import clsx from "clsx";
import { hasClassName } from "helper/check-class";

export type InputButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  type?: "button" | "submit" | "reset";
  className?: string;
};

export const InputButton = React.forwardRef<HTMLButtonElement, InputButtonProps>(function InputButton(
  { type = "button", className, ...buttonProps },
  ref,
) {
  return (
    <button
      type={type}
      {...buttonProps}
      className={clsx(
        "flex items-center min-w-0",
        "pr-2.5 pl-5",
        !hasClassName(className, "h-") && "h-45px",
        !hasClassName(className, "w-") && "w-233px",
        buttonProps.disabled
          ? "bg-gray-200 cursor-default"
          : ["bg-primary", !hasClassName("active:") && "active:shadow-outline"],
        className,
      )}
      ref={ref}
    />
  );
});
