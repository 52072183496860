import { IconButton, Modal, ModalContent } from "components";
import { ArrowIcon, CircleIcon } from "components/icons";
import { FILES_ICON } from "configs/files";
import InvalidImage from "/images/files/invalid_file.webp";
import { useState } from "react";

interface ReportFilesProps {
  isOpen: boolean;
  onDismiss?: () => void;
  fileUrls: string[];
  header?: string;
}

const itemPerPage = 6;

const getFileExtension = (url: string) => {
  const extension = url.split(".").pop() || "";
  const imageTypes = ["jpg%22", "jpeg%22", "png%22", "gif%22", "svg%22"];
  return imageTypes.includes(extension) ? "image" : "pdf";
};

export const ReportFilesModal = ({ isOpen, onDismiss, fileUrls, header }: ReportFilesProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const totalPages = Math.ceil(fileUrls?.length / itemPerPage);
  const navigateToNextPage = () => {
    setCurrentPageIndex((prevIndex) => prevIndex + 1);
  };
  const navigateToPreviousPage = () => {
    setCurrentPageIndex((prevIndex) => prevIndex - 1);
  };

  const onImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imageElement = event.currentTarget;
    imageElement.src = InvalidImage;
  };

  const fileUrlsOnCurrentPage = fileUrls.slice(currentPageIndex * itemPerPage, (currentPageIndex + 1) * itemPerPage);

  return (
    <Modal isOpen={isOpen} className="min-h-96">
      <ModalContent header={header} className="h-full">
        <IconButton className="right-4 absolute top-4" onClick={onDismiss}>
          <CircleIcon className="w-8 h-8" />
        </IconButton>
        <div className="grid grid-cols-3 gap-4 px-6">
          {fileUrlsOnCurrentPage.map((fileUrl) => {
            const fileType = getFileExtension(fileUrl);
            const isPDFFile = fileType === "pdf";
            return (
              <div className="flex justify-center items-center border-gray-300 border-2 h-40" key={fileUrl}>
                {isPDFFile && <img src={FILES_ICON["pdf"]} alt={fileUrl} className="w-20" />}
                {!isPDFFile && <img onError={onImageError} src={fileUrl} alt="user report file" className="w-20" />}
              </div>
            );
          })}
        </div>
        <IconButton
          className="absolute right-5 top-1/2 disabled:fill-gray-400 rotate-180"
          disabled={currentPageIndex === totalPages - 1}
          onClick={navigateToNextPage}
        >
          <ArrowIcon className="w-4 h-4" />
        </IconButton>
        <IconButton
          className="absolute left-2 md:left-4 lg:left-6 top-1/2 disabled:fill-gray-400"
          disabled={currentPageIndex === 0}
          onClick={navigateToPreviousPage}
        >
          <ArrowIcon className="w-4 h-4" />
        </IconButton>
      </ModalContent>
    </Modal>
  );
};
