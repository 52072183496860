import { useContext, useEffect, useMemo, useState } from "react";
import { CityContext } from "../contexts/city-context-provider";
import { useDebounce } from "./lib-ui";
import { DocumentNode, useQuery } from "@apollo/client";
import { useSearchFilterCurbZone } from "./use-search-filter-curb-zone";
import { Range } from "react-date-range";
import { convertDateToUTC } from "../helper/date-time";
import { RestrictionAndCurbZoneResponse } from "services/web/api-restriction.type";
import { showGlobalLoading, hideGlobalLoading } from "./use-global-loading";

export type FetchDisabledCurbZoneParams = { queryString: DocumentNode; selectedDate?: Range };

export const useFetchRestriction = ({ queryString, selectedDate }: FetchDisabledCurbZoneParams) => {
  const { currentCity } = useContext(CityContext);
  const [isResetData, setIsResetData] = useState<boolean>(false);
  const [bounding, setBounding] = useState<number[][]>();
  const nearBy = useDebounce(bounding, 500);
  const { filter, changeFilter } = useSearchFilterCurbZone();

  const variables = useMemo(() => {
    /**
     * Format datetime by city's iana
     * */
    const startDate = selectedDate?.startDate ? convertDateToUTC(selectedDate.startDate) : undefined;
    const endDate = selectedDate?.endDate ? convertDateToUTC(selectedDate.endDate) : undefined;

    return {
      filterInputCurbZone: {
        cityId: currentCity?.id ?? "",
        boundingbox: nearBy,
        policies: filter.selectedPolicies.length > 0 ? filter.selectedPolicies : undefined,
      },
      filterInputRestriction: {
        cityId: currentCity?.id ?? "",
        boundingbox: nearBy,
        policies: filter.selectedPolicies.length > 0 ? filter.selectedPolicies : undefined,
        startDate,
        endDate,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearBy, filter.selectedPolicies, selectedDate]);

  const { data = { disabledCurbZonesByBounding: [], curbZones: [] }, loading } =
    useQuery<RestrictionAndCurbZoneResponse>(queryString, {
      variables,
      skip: !currentCity?.id || !nearBy,
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  return {
    setIsResetData,
    variables,
    currentCity,
    loading,
    nearBy,
    data,
    isResetData,
    bounding,
    setBounding,
    filter,
    changeFilter,
  };
};
