import clsx from "clsx";
import { InputButtonProps } from "./input-button";
import { hasClassName } from "helper/check-class";
import { Calendar } from "react-date-range";
import { fr as languageFr } from "date-fns/locale";
import { formatDate, parseISOString, timeToString } from "helper/date-time";
import { Label } from "../label";
import { CalendarIcon } from "components/icons";
import { Popover, PopoverTrigger, PopoverContent } from "components/popover";
import { useMemo } from "react";
import { TimeInput } from "./time-input";
import { formatISO, format } from "date-fns";

export interface DateRangeInputProps extends InputButtonProps {
  label?: string;
  selectedDate?: Date | string | null;
  onChangeValue: (newDate: Date) => void;
  placeholder?: string;
  className?: string;
  minDate?: Date | string | undefined;
  maxDate?: Date | string | undefined;
  showTime?: boolean;
}

export const CalendarSelector = ({
  selectedDate,
  minDate,
  maxDate,
  label,
  onChangeValue,
  placeholder = "Select date",
  className,
  showTime = false,
  ...buttonProps
}: DateRangeInputProps) => {
  const { name } = { ...buttonProps };

  const handleChangeDate = (input: Date) => {
    const dateInput = formatISO(input, { representation: "date" });
    const timeInput = selectedDate ? format(new Date(selectedDate), "HH:mm") : "00:00";
    const newDateTime = new Date(`${dateInput}T${timeInput}`);
    onChangeValue(newDateTime);
  };

  const convertDate = (input: Date | string | undefined) => {
    if (!input) {
      return undefined;
    }
    if (typeof input === "string") {
      return new Date(input);
    }
    return input;
  };

  const newDate = useMemo((): Date => {
    if (selectedDate && typeof selectedDate === "string") {
      return parseISOString(selectedDate);
    }
    if (typeof selectedDate === "object") {
      return selectedDate as Date;
    }

    return new Date();
  }, [selectedDate]);

  const times = useMemo(() => {
    if (!newDate) {
      return "";
    }
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    return timeToString(hours, minutes);
  }, [newDate]);

  const changeTime = (value: string) => {
    if (!selectedDate) {
      return;
    }
    const newDate = new Date(selectedDate);
    if (value === "null") {
      newDate.setHours(0, 0, 0, 0);
      onChangeValue(newDate);
    } else {
      const dateInput = formatISO(new Date(selectedDate), { representation: "date" });
      if (!isNaN(newDate.getTime())) {
        onChangeValue(new Date(`${dateInput}T${value}:00`));
      }
    }
  };

  return (
    <div className="flex items-end relative">
      <Popover>
        <PopoverTrigger>
          <Label htmlFor={name ?? ""} className="form-label-focus">
            {label}
          </Label>
          <div
            className={clsx(
              "form-input flex items-center min-w-0",
              "h-45px pr-2.5 pl-5 rounded-t-small",
              !hasClassName(className, "w-") && "w-360px",
              buttonProps.disabled
                ? "bg-gray-200 cursor-default"
                : ["bg-primary", !hasClassName("active:") && "active:shadow-outline"],
              className,
            )}
          >
            <CalendarIcon className="w-4 h-4 mr-3" />
            {selectedDate ? (
              <span className="flex-1 truncate text-left text-medium capitalize">{formatDate(selectedDate)}</span>
            ) : (
              <span className="text-lightgrey">{placeholder}</span>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="z-50">
          <Calendar
            onChange={(item: Date) => {
              handleChangeDate(item);
              close();
            }}
            locale={languageFr}
            date={newDate}
            minDate={convertDate(minDate)}
            maxDate={convertDate(maxDate)}
          />
        </PopoverContent>
      </Popover>
      {showTime && <TimeInput timeInputClassName="p-2" times={times} onChangeValue={changeTime} disabled={!newDate} />}
    </div>
  );
};
