import { gql } from "@apollo/client";

export const QUERY_CURB_AREAS = gql`
  query curbAreas($cityId: String!) {
    curbAreas(cityId: $cityId) {
      id
      name
      note
      geometry
      publishedDate
    }
  }
`;

export const QUERY_ADD_CURB_AREA = gql`
  mutation createCurbArea($curbAreaInput: CreateCurbAreaInput!) {
    createCurbArea(curbAreaInput: $curbAreaInput) {
      id
      name
      note
      geometry
      publishedDate
      lastUpdatedDate
    }
  }
`;

export const QUERY_UPDATE_CURB_AREA = gql`
  mutation updateCurbArea($curbAreaInput: UpdateCurbAreaInput!) {
    updateCurbArea(curbAreaInput: $curbAreaInput) {
      id
      name
      note
      geometry
      publishedDate
      lastUpdatedDate
    }
  }
`;

export const QUERY_REMOVE_CURB_AREA = gql`
  mutation deleteCurbArea($id: String!) {
    deleteCurbArea(id: $id) {
      id
    }
  }
`;
