import { useApolloClient, useMutation } from "@apollo/client";
import { useFetchCurbArea } from "hooks/use-curb-area.hook";
import {
  QUERY_CURB_AREAS,
  QUERY_ADD_CURB_AREA,
  QUERY_UPDATE_CURB_AREA,
  QUERY_REMOVE_CURB_AREA,
} from "services/web/api-curb-area.const";
import { CurbArea } from "types/curb-area.type";
import {
  CreateCurbAreaInput,
  CreateCurbAreaResponse,
  RemoveCurbAreaInput,
  RemoveCurbAreaResponse,
  UpdateCurbAreaInput,
  UpdateCurbAreaResponse,
} from "services/web/api-curb-area.type";
import { useState } from "react";
import { findObject } from "helper/array";

interface CurbAreasCache {
  curbAreas: CurbArea[] | [];
}

export const useQueryCurbArea = () => {
  const client = useApolloClient();
  const [isResetData, setIsResetData] = useState<boolean>(false);

  const { variables, currentCity, loading, curbAreas } = useFetchCurbArea();

  const getCachedCurbArea = (): CurbArea[] => {
    const cachedCurbArea = client.cache.readQuery({ query: QUERY_CURB_AREAS, variables }) as CurbAreasCache;
    return cachedCurbArea.curbAreas;
  };

  const resetState = () => {
    setIsResetData(true);
  };

  const [createCurbArea] = useMutation<CreateCurbAreaResponse>(QUERY_ADD_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const newState = [...preState, { ...response.createCurbArea }];
      client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
    },
    onError: resetState,
  });

  const [updateCurbArea] = useMutation<UpdateCurbAreaResponse>(QUERY_UPDATE_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const findIndex = findObject(preState, "id", response.updateCurbArea?.id);
      if (findIndex > -1) {
        const newState = [...preState];
        newState[findIndex] = response.updateCurbArea;
        client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
      }
    },
    onError: resetState,
  });

  const [removeCurbArea] = useMutation<RemoveCurbAreaResponse>(QUERY_REMOVE_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const newState = preState.filter((item) => item.id !== response.deleteCurbArea?.id);
      client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
    },
    onError: resetState,
  });

  const handleAddCurbArea = async (input: CreateCurbAreaInput) => {
    const response = await createCurbArea({ variables: input });
    return response.data;
  };

  const handleUpdateCurbArea = async (input: UpdateCurbAreaInput) => {
    const response = await updateCurbArea({ variables: input });
    return response.data;
  };

  const handleRemoveCurbArea = async (input: RemoveCurbAreaInput) => {
    const response = await removeCurbArea({ variables: input });
    return response.data;
  };
  return {
    curbAreas,
    loading,
    currentCity,
    handleAddCurbArea,
    handleUpdateCurbArea,
    handleRemoveCurbArea,
    isResetData,
    setIsResetData,
  };
};
