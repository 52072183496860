import clsx from "clsx";
import { ArrowIcon } from "components/icons";
import { noop } from "helper/utils";

export enum CSSAlignement {
  left = "left",
  center = "center",
  right = "right",
}

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export type HeaderItem<T = any> = {
  title: string;
  align: CSSAlignement;
  sortable?: boolean;
  orderBy?: T;
  sortDirection?: SortDirection;
  className?: string;
};

export type TableHeaderProps<T = any> = {
  columnName: HeaderItem<T>[];
  className?: string;
  onSort?: (orderBy: T, direction: SortDirection) => void;
};

export const TableHeader = ({ columnName, className, onSort }: TableHeaderProps) => {
  const toggleSort = (orderBy?: string, direction?: SortDirection) => {
    if (typeof onSort === "undefined" || !orderBy || !direction) {
      return;
    }
    if (direction === SortDirection.ASC) {
      onSort(orderBy, SortDirection.DESC);
    } else {
      onSort(orderBy, SortDirection.ASC);
    }
  };
  return (
    <thead>
      <tr className={className}>
        {columnName.map((column, index) => (
          <th
            className={clsx("px-6 py-3 border-b border-gray-300 leading-4 tracking-wider", column.className)}
            key={`${column}-${index}`}
          >
            <span className={clsx("flex items-center gap-2", classByAlign[column.align])}>
              <span className="block">{column.title}</span>
              {column?.sortable && (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={noop}
                  className="block cursor-pointer"
                  onClick={() => toggleSort(column.orderBy, column.sortDirection)}
                >
                  <ArrowIcon
                    className={clsx("w-2 h-2", column.sortDirection === SortDirection.ASC ? "-rotate-90" : "rotate-90")}
                  />
                </span>
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const classByAlign: Record<CSSAlignement, string> = {
  left: "justify-start",
  right: "justify-end",
  center: "justify-center",
};
