import clsx from "clsx";
import { ComponentPropsWithoutRef, Fragment, ReactNode, useRef } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { hasClassName } from "helper/check-class";
import { twMerge } from "tailwind-merge";

export interface IDialogProps extends ComponentPropsWithoutRef<"div"> {
  className?: string;
  overlayClassName?: string;
  wrapperContentClassName?: string;
  children: ReactNode;
  isOpen: boolean;
}

export const Modal = ({
  isOpen = false,
  className,
  wrapperContentClassName,
  overlayClassName,
  children,
}: IDialogProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog initialFocus={dialogRef} as="div" className="relative z-50" onClose={() => null}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={clsx("fixed inset-0 bg-black/30 bg-opacity-25", overlayClassName)} />
        </TransitionChild>

        <div className={twMerge("fixed inset-0 overflow-y-auto", wrapperContentClassName)}>
          <div className={clsx("flex justify-center mx-auto", hasClassName(className, "my-") ? className : "my-10vh")}>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
