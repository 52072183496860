import { useContext, useMemo, useState } from "react";
import { DropdownMultiSelect } from "components/form-control";
import { usePolicies } from "modules/policies/hooks/use-policies";
import { AddIcon } from "assets";
import { AddPolicyModal } from "modules/policies/components/AddPolicyModal";
import useToggle from "hooks/use-toggle";
import { Policy } from "types";
import { defaultPolicy } from "modules/policies/policies.const";
import { CreatePolicyResponse } from "services/web/api-policy.type";
import { CityContext } from "contexts/city-context-provider";

export interface PolicyComponentProps {
  curbPolicies: string[];
  setCurbPolicies: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PolicyView = ({ curbPolicies, setCurbPolicies }: PolicyComponentProps) => {
  const { policyList } = usePolicies();
  const { currentCity } = useContext(CityContext);
  const [openAddPolicyModal, setOpenAddPolicyModal] = useToggle(false);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(defaultPolicy);

  const handleSelectPolicies = (input: string[]) => {
    setCurbPolicies(input);
  };
  const closeAddPolicyModal = () => {
    setOpenAddPolicyModal();
    setSelectedPolicy(defaultPolicy);
  };

  const listPolicies = useMemo((): any[] => {
    if (policyList.length === 0) {
      return [];
    }
    return policyList.map((item) => ({ value: item.curbPolicyId, label: item.name || "" }));
  }, [policyList]);

  const handleSubmitNewPolicy = (input: CreatePolicyResponse) => {
    const { curbPolicyId } = input.createPolicy;
    if (curbPolicyId) {
      setCurbPolicies((prevState) => [...prevState, curbPolicyId]);
    }
  };

  return (
    <>
      <div className="relative">
        <DropdownMultiSelect
          options={listPolicies}
          values={curbPolicies}
          onChangeValues={handleSelectPolicies}
          className="w-36"
          placeholder="Policies"
        />
        <AddIcon
          className="h-5 w-5 absolute right-0 top-2.5 cursor-pointer"
          fill="white"
          onClick={setOpenAddPolicyModal}
        />
      </div>
      {openAddPolicyModal && currentCity && (
        <AddPolicyModal
          currentCity={currentCity}
          policyData={selectedPolicy}
          onClose={closeAddPolicyModal}
          onSubmit={handleSubmitNewPolicy}
        />
      )}
    </>
  );
};
