import { LogoIcon } from "../components/icons";
import { MacButton } from "../components";
import { useLogout } from "../store/hooks";
import { NavLink } from "react-router-dom";
import { routes } from "../routes/constants";

export default function ErrorPage() {
  const logout = useLogout();

  return (
    <div className="flex height-100vh">
      <div className="flex-grow flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="mb-8">
            <LogoIcon className="h-16" fill="#292A2F" />
          </div>
          <ContentMessage />
          <MacButton variant="error" onClick={logout}>
            Logout
          </MacButton>
        </div>
      </div>
    </div>
  );
}

const ContentMessage = () => {
  return (
    <>
      <span>No error!</span>
      <NavLink to={routes.root.path} className="block mb-4 hover:underline">
        Back to the homepage
      </NavLink>
    </>
  );
};
