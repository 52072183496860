import { forwardRef, useState, useEffect, ReactNode } from "react";
import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { borderStatus, FieldStatus, textStatus } from "./field.type";
import { callAll, isNil } from "helper/utils";
import { Label } from "../label";

export interface FormInputProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: string | undefined;
  status?: FieldStatus;
  onChangeValue?: (value: string) => void;
  classLabel?: string;
  iconStart?: ReactNode;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(function FormInput(
  { status = "primary", classLabel, className, onChange, onChangeValue, label, iconStart, ...inputProps },
  forwardedRef,
) {
  const [value, setValue] = useState<any>(" ");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { name, readOnly } = { ...inputProps };
  const getLabelClass = clsx(
    "text-sm",
    isFocused ? "form-label-focus" : "form-label-nonfocus",
    readOnly && "text-disabled",
    textStatus[status],
    classLabel,
  );
  useEffect(() => {
    setValue(inputProps.defaultValue || inputProps.value);
  }, [inputProps.value, inputProps.defaultValue]);
  useEffect(() => {
    if (!isNil(value) && !!String(value).trim()) {
      setIsFocused(true);
    }
  }, [value]);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };
  return (
    <div
      className={clsx(
        "relative w-full",
        !hasClassName(className, "border-none") && "border-b",
        (readOnly || inputProps.disabled) && !hasClassName(className, "bg-")
          ? "border-dotted text-disabled"
          : "border-solid",
        status ? borderStatus[status] : "border-primary",
      )}
    >
      <Label htmlFor={name || ""} className={getLabelClass}>
        {label}
      </Label>
      <div className="flex items-center">
        {iconStart && <div className="mr-3">{iconStart}</div>}
        <input
          {...inputProps}
          className={clsx(
            !hasClassName(className, "w-") && "w-full",
            className,
            textStatus[status],
            "block pt-2.5 pb-1.5 text-sm focus-visible:outline-none z-1 bg-transparent border-0",
          )}
          onChange={callAll(
            (ev) => setValue(ev.target.value),
            onChange,
            onChangeValue && ((ev) => onChangeValue(ev.target.value)),
          )}
          ref={forwardedRef}
          onFocus={callAll(inputProps.onFocus, handleFocus)}
          onBlur={callAll(inputProps.onBlur, handleBlur)}
        />
      </div>
    </div>
  );
});
