import { gql } from "@apollo/client";

export const QUERY_PARKING_LOT = gql`
  query parkingLots($cityId: String!) {
    parkingLots(cityId: $cityId) {
      id
      geometry
      position
      name
      address
      note
      cityId
      curbZoneIds
    }
  }
`;

export const QUERY_ADD_PARKING_LOT = gql`
  mutation createParkingLot($parkingLotInput: CreateParkingLotInput!) {
    createParkingLot(parkingLotInput: $parkingLotInput) {
      id
      geometry
      position
      name
      address
      note
      cityId
      curbZoneIds
    }
  }
`;

export const QUERY_UPDATE_PARKING_LOT = gql`
  mutation updateParkingLot($parkingLotInput: UpdateParkingLotInput!) {
    updateParkingLot(parkingLotInput: $parkingLotInput) {
      id
      geometry
      position
      name
      address
      note
      cityId
      curbZoneIds
    }
  }
`;

export const QUERY_DELETE_PARKING_LOT = gql`
  mutation deleteParkingLot($id: String!) {
    deleteParkingLot(id: $id) {
      id
    }
  }
`;
