import { gql } from "@apollo/client";

export const QUERY_USER_REPORT_BY_CITY = gql`
  query GetUserReportByCityId(
    $cityId: String!
    $pageIndex: Int!
    $pageSize: Int!
    $filter: [FilterInput!]
    $sortOrder: SortOrder
    $sortField: String
  ) {
    getUserReportsByCityId(
      cityId: $cityId
      pageIndex: $pageIndex
      pageSize: $pageSize
      filter: $filter
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      results {
        id
        cityId
        attachedFilesUrls
        features
        note
        isDone
      }
      totalPages
    }
  }
`;

export const UPDATE_USER_REPORT = gql`
  mutation UpdateUserReport($reportInput: UpdateUserReportInput!) {
    updateUserReport(reportInput: $reportInput) {
      id
      isDone
    }
  }
`;
