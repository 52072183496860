import { DescriptionItem, DescriptionList } from "components";
import { distanceUnit } from "constants/mapbox.const";
import { moneyFormat, numberFormat } from "helper/format-data";
import * as React from "react";
import { isEmpty, toolTipStyle } from "helper/utils";
import { useMemo } from "react";
import { CurbZoneHover, DisabledHover } from "../modes/disable-parts/disabled-part.type";
import { CurbZone } from "types/curb-zone.type";
import { Restriction, RestrictionType } from "types";
import { TimeSpanItem } from "components/time-span/time-span-item";

type DisabledCurbZoneTooltipProps = {
  disabledHover: DisabledHover | null;
  left: number;
  top: number;
  curbZoneHover?: CurbZoneHover | null;
};

export const DisabledCurbZoneTooltip = (props: DisabledCurbZoneTooltipProps) => {
  const { left, top, curbZoneHover, disabledHover } = props;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const styles = toolTipStyle({ top, left, height: 284, width: 288 });

  return (
    <div style={styles} className="absolute w-72" ref={ref}>
      {curbZoneHover && <CurbZoneInfo curbZone={curbZoneHover.properties} />}
      {disabledHover && <DisabledInfo disabledPart={disabledHover.properties} />}
    </div>
  );
};

type CurbZoneInfoProps = {
  curbZone: CurbZone;
};
const CurbZoneInfo = ({ curbZone }: CurbZoneInfoProps) => {
  const firstPolicy = curbZone.curbPolicies ? curbZone?.curbPolicies[0] : null;

  let price = 0;
  if (!isEmpty(firstPolicy?.rules) && !isEmpty(firstPolicy?.rules?.[0]?.rate)) {
    price = firstPolicy?.rules?.[0]?.rate?.[0].rate || 0;
  }

  const policyNames = useMemo(() => {
    if (curbZone.curbPolicies) {
      const displayedName = curbZone.curbPolicies.reduce((names: string[], item) => {
        return [...names, item.name];
      }, []);
      return displayedName.join(", ");
    }
    return "";
  }, [curbZone.curbPolicies]);
  return (
    <div className="relative transition-opacity h-auto p-3 mt-2 mb-3 rounded-md shadow-lg z-10 bg-primary">
      <DescriptionList>
        <DescriptionItem label="Id" value={curbZone.curbZoneId} />
        <DescriptionItem label="Type de place" value={policyNames} />
        <DescriptionItem label="Forme" value={curbZone.parkingAngle} />
        <DescriptionItem label="Tarif" value={moneyFormat(price)} />
        <DescriptionItem label="Nom voie" value={curbZone?.streetName} />
        <DescriptionItem label="Longueur" value={`${numberFormat(curbZone.length)} ${distanceUnit}`} />
        <DescriptionItem label="Largeur" value={`${curbZone?.width} ${distanceUnit}`} />
        <DescriptionItem
          label="Surface calculée"
          value={`${numberFormat(curbZone?.calculatedArea)} ${distanceUnit}2`}
        />
        <DescriptionItem label="Places calculées" value={curbZone?.calculatedSpaces} />
        {curbZone?.externalId && <DescriptionItem label="Identifiant externe" value={curbZone?.externalId} />}
        {curbZone?.note && (
          <DescriptionItem
            label="Commentaire"
            value={<span dangerouslySetInnerHTML={{ __html: curbZone?.note.replace(/\n/g, "<br />") }} />}
          />
        )}
      </DescriptionList>
    </div>
  );
};

type DisabledInfoProps = {
  disabledPart: Restriction;
};

const DisabledInfo = ({ disabledPart }: DisabledInfoProps) => {
  const decreeFileNames = useMemo(() => {
    if (disabledPart?.decrees?.length) {
      return disabledPart?.decrees?.map((item) => item.name);
    }
    return [];
  }, [disabledPart]);

  return (
    <div className="relative transition-opacity h-auto p-3 mt-2 mb-3 rounded-md shadow-lg z-10 bg-primary">
      <DescriptionList>
        <DescriptionItem
          label="TimeSpans"
          value={
            <div className="flex flex-col">
              {disabledPart.timeSpans?.map((timeSpan, timeSpanIndex) => (
                <div className="flex items-center" key={`time-span-${timeSpanIndex}`}>
                  <TimeSpanItem
                    label=""
                    timeSpan={timeSpan}
                    name={`timeSpans[${timeSpanIndex}]`}
                    options={{
                      showSelectDate: true,
                    }}
                  />
                </div>
              ))}
            </div>
          }
        />
        <DescriptionItem label="Description" value={disabledPart?.description} />
        <DescriptionItem label="Decree" value={decreeFileNames.join(", ")} />
        {disabledPart.type && <DescriptionItem label="Type" value={RestrictionType[disabledPart.type]} />}
        {disabledPart.length && (
          <DescriptionItem label="Longueur" value={`${numberFormat(disabledPart.length)} ${distanceUnit}`} />
        )}
        {disabledPart.calculatedSpaces && (
          <DescriptionItem label="Places calculées" value={disabledPart.calculatedSpaces} />
        )}
      </DescriptionList>
    </div>
  );
};
