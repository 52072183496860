import * as React from "react";

export const LoadingIcon = ({ ...props }) => (
  <svg className="animate-spin h-5 w-5 inline-block" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="20" r="2" />
    <circle cx="12" cy="4" r="2" />
    <circle cx="6.343" cy="17.657" r="2" />
    <circle cx="17.657" cy="6.343" r="2" />
    <circle cx="4" cy="12" r="2.001" />
    <circle cx="20" cy="12" r="2" />
    <circle cx="6.343" cy="6.344" r="2" />
    <circle cx="17.657" cy="17.658" r="2" />
  </svg>
);
