import clsx from "clsx";
import { formatPolicyColorToString } from "helper/utils";

import { ColorInput } from "types";
import { defaultFillColor, defaultLineColor } from "../policies.const";
import { ColorInputModal } from "./ColorInputModal";
import useToggle from "hooks/use-toggle";

interface PolicyColorInputProps {
  policyColor: ColorInput;
  onSaveColor: (value: ColorInput) => void;
}

export const PolicyColorInput = ({ policyColor, onSaveColor }: PolicyColorInputProps) => {
  const { fillColor, lineColor, dashed } = policyColor;
  const [isOpenColorModal, setIsOpenColorModal] = useToggle();

  return (
    <>
      <button
        className={clsx("flex items-center h-5 w-28 border", dashed ? "border-dashed" : "border-solid")}
        style={{
          backgroundColor: formatPolicyColorToString(fillColor || defaultFillColor),
          borderColor: formatPolicyColorToString(lineColor || defaultLineColor),
        }}
        onClick={setIsOpenColorModal}
      >
        <div className="h-0.5 w-full" />
      </button>

      <ColorInputModal
        isOpen={isOpenColorModal}
        currentColorInput={policyColor}
        onDismiss={setIsOpenColorModal}
        onApply={onSaveColor}
      />
    </>
  );
};
