import { createBrowserRouter } from "react-router-dom";
import MiddleWare from "./middleware";
import { routes } from "./constants";
import { CurbZonePage } from "pages/curb-zone-page";
import { OffstreetParkingPage } from "pages/offstreet-parking";
import { DisabledParkingPage } from "pages/disabled-parking";
import { CitiesPage } from "pages/cities";
import { PoliciesPage } from "pages/policies-page";
import { NotFoundPage } from "pages/404";
import ErrorPage from "pages/error-page";

export const router = createBrowserRouter([
  {
    element: <MiddleWare />,
    children: [
      {
        path: routes.root.path,
        element: <CurbZonePage />,
      },
      {
        path: routes.offstreetParking.path,
        element: <OffstreetParkingPage />,
      },
      {
        path: routes.disabledParts.path,
        element: <DisabledParkingPage />,
      },
      {
        path: routes.cities.path,
        element: <CitiesPage />,
      },
      {
        path: routes.policies.path,
        element: <PoliciesPage />,
      },
    ],
  },
  { path: routes.notfound.path, element: <NotFoundPage /> },
  { path: routes.error.path, element: <ErrorPage /> },
]);
