import { PrivateParkingMap } from "modules/offstreet-parking";
import { Wrapper } from "components/layout";

export function OffstreetParkingPage() {
  return (
    <Wrapper title="Offstreet Parking" className="mask-background">
      <div className="relative -mt-16 map-height">
        <PrivateParkingMap />
      </div>
    </Wrapper>
  );
}
