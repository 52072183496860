import { Feature, LineString, Polygon } from "geojson";
import { TimeSpanInput } from "./policy.type";
import { Decree } from "./decree.type";

export type Restriction = {
  id?: string;
  geometry: Polygon;
  timeSpans?: TimeSpanInput[];
  description?: string;
  decreeIds?: string[];
  geoPoint2d: number[];
  editLine: Feature<LineString>;
  cityId: string;
  curbZoneId?: string;
  type?: TRestrictionType;
  width?: number;
  calculatedSpaces?: number;
  length?: number;
  streetName?: string;
  streetNumberStart?: string;
  streetNumberEnd?: string;
  decrees?: Pick<Decree, "id" | "name">[];
};

export enum DisabledCurbZoneType {
  moving = "Déménagement",
  publicDomainOccupation = "Occupation du domaine publique",
  constructionWork = "Travaux",
  oneTimeEvent = "Évenement ponctuel",
  other = "Autres",
}

export enum TrafficRestrictionType {
  trafficRestriction = "Restriction de circulation Totale",
  alternateTrafficRestriction = "Restriction de circulation Alterné",
}
export const RestrictionType = { ...DisabledCurbZoneType, ...TrafficRestrictionType };
export type TRestrictionType = keyof typeof RestrictionType;
