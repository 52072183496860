import * as turf from "@turf/turf";
import { distanceUnit } from "constants/mapbox.const";
import { Feature, LineString, Point, Polygon } from "geojson";
import { AddressType, getAddress } from "../services/web/mapbox.service";
import { CurbZone } from "../types/curb-zone.type";
import { lineToRectangle } from "./line-to-rectangle";
import { isEmpty } from "./utils";

export const getPlace = async <T = CurbZone>(input: Feature<any>): Promise<Feature<LineString, T>> => {
  const newLineParking: Feature<any> = Object.assign({}, { ...input });
  const length = turf.length(newLineParking.geometry, { units: "meters" });
  const geoPoint2d = turf.along(newLineParking.geometry, length / 2, { units: "meters" });
  const longueur = turf.length(newLineParking.geometry, { units: distanceUnit });

  const { calculatedSpaces, calculatedArea, width } = calculateArea(newLineParking);
  let address: AddressType = {
    name: newLineParking.properties?.streetName || "",
    number: newLineParking.properties?.numVoie || 0,
  };

  const streetNumberStart = newLineParking.properties?.streetNumberStart;
  const streetNumberEnd = newLineParking.properties?.streetNumberEnd;

  const startPoint = {
    type: "Point",
    coordinates: newLineParking.geometry.coordinates[0],
  } as Point;
  const newStreetNumberStart = (await getAddress(startPoint))?.number || "";

  const lengthCoordinates = newLineParking.geometry.coordinates.length - 1;
  const endPoint = {
    type: "Point",
    coordinates: newLineParking.geometry.coordinates[lengthCoordinates],
  } as Point;
  const newStreetNumberEnd = (await getAddress(endPoint))?.number || "";

  const streetNumbers = [newStreetNumberStart, newStreetNumberEnd].sort(compareStreetNumbers);

  if (!newLineParking.properties?.streetName) {
    address = await getAddress(geoPoint2d.geometry);
    address.name = capitalizeFirstLetterOfEachWord(address.name);
  }

  newLineParking.properties = {
    ...newLineParking.properties,
    streetNumberStart: isEmpty(streetNumberStart) ? streetNumbers[0] : streetNumberStart,
    streetNumberEnd: isEmpty(streetNumberEnd) ? streetNumbers[1] : streetNumberEnd,
    streetName: address.name,
    numVoie: !isNaN(Number(address.number)) ? Number(address.number) : 0,
    length: longueur.toFixed(2),
    calculatedArea: calculatedArea.toFixed(2),
    width,
    calculatedSpaces,
  };

  return newLineParking as Feature<LineString, T>;
};

export type CalculateArea = {
  calculatedArea: number;
  calculatedSpaces: number;
  width: number;
};

export const calculateArea = (curbZoneFeature: Feature<any>): CalculateArea => {
  const width = curbZoneFeature.properties?.width || 2;
  const polygonParking = convertLineToPolygon(curbZoneFeature, width);
  const calculatedArea = turf.area(polygonParking);
  const length = turf.length(curbZoneFeature.geometry, { units: distanceUnit });
  const calculatedSpaces = Math.max(1, Math.floor(length / 5));
  return {
    calculatedArea,
    calculatedSpaces,
    width,
  };
};

export const convertLineToPolygon = (lineStringFeature: Feature<LineString>, width: number) => {
  width = width || 2;
  const polygon = lineToRectangle(lineStringFeature, width / 2) as Feature<Polygon, CurbZone>;
  const length = turf.length(lineStringFeature, { units: distanceUnit });
  const geoPoint2d = turf.along(lineStringFeature.geometry, length / 2, { units: "meters" });

  polygon.id = lineStringFeature.id;
  polygon.properties = lineStringFeature.properties as CurbZone;
  polygon.properties.geoPoint2d = geoPoint2d.geometry.coordinates;
  polygon.properties.editLine = { ...lineStringFeature, properties: {} };
  return polygon;
};

const euNumberFormatter = new Intl.NumberFormat("eu", { maximumFractionDigits: 2 });

export const numberFormat = (num?: number) => (num ? euNumberFormatter.format(num) : "");

export const moneyFormat = (num?: number) =>
  num ? new Intl.NumberFormat("eu", { style: "currency", currency: "EUR" }).format(num) : "";

export const convertPolygonToCurbZone = (input: Feature<Polygon, CurbZone>, cityId: string) => {
  return {
    id: input.id,
    geometry: input.geometry,
    curbPolicyIds: input.properties.curbPolicyIds,
    publishedDate: input.properties.publishedDate,
    startDate: input.properties.startDate,
    endDate: input.properties.endDate,
    name: input.properties.name,
    streetName: input.properties.streetName,
    crossStreetStartName: input.properties.crossStreetStartName,
    crossStreetEndName: input.properties.crossStreetEndName,
    length: Number(input.properties.length),
    availableSpaceLengths: input.properties.availableSpaceLengths,
    availabilityTime: input.properties.availabilityTime,
    width: input.properties.width,
    parkingAngle: input.properties.parkingAngle,
    numSpaces: input.properties.numSpaces,
    calculatedArea: Number(input.properties.calculatedArea),
    calculatedSpaces: input.properties.calculatedSpaces,
    geoPoint2d: input.properties.geoPoint2d,
    cityId,
    externalId: input.properties.externalId,
    streetNumberStart: input.properties.streetNumberStart,
    streetNumberEnd: input.properties.streetNumberEnd,
    editLine: input.properties.editLine,
    note: input.properties.note,
  };
};

export const getEnumKeyByEnumValue = <TEnumKey extends string, TEnumVal extends string | number>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal,
): string | undefined => {
  return (Object.keys(myEnum) as TEnumKey[]).find((x) => myEnum[x] === enumValue);
};

export function splitNumberAndSuffix(streetNumber: string): [number, string] {
  const match = streetNumber.match(/^(\d+)([A-Za-z]*)$/);
  return match ? [parseInt(match[1]), match[2]] : [0, ""];
}

export function compareStreetNumbers(a: string, b: string): number {
  const [numA, suffixA] = splitNumberAndSuffix(a);
  const [numB, suffixB] = splitNumberAndSuffix(b);

  if (numA !== numB) {
    return numA - numB;
  }

  if (suffixA < suffixB) return -1;
  if (suffixA > suffixB) return 1;
  return 0;
}

export function capitalizeFirstLetterOfEachWord(text?: string): string | undefined {
  return text
    ?.split(" ") // Split the text into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter and convert the rest to lowercase
    .join(" "); // Join the array of words back into a single string
}
