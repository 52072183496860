import { CurbZone, EditableCurbZoneLayerName } from "types/curb-zone.type";
import {
  EditableGeoJsonLayer,
  FeatureOf,
  SelectionLayer,
  TranslateMode,
  ViewMode,
} from "@deck.gl-community/editable-layers";
import { Feature, FeatureCollection, LineString, Polygon, Position } from "geojson";
import { AppModeType, MapType, TooltipType, ViewportPlaceType } from "types";
import {
  ModeViewType,
  editableGeoJsonLayerModes,
  filterLineStringFromPoint,
  filterParkingIndex,
  getPolygonColor,
  toLineStringFeature,
} from "constants/map.const";
import { convertLineToPolygon, convertPolygonToCurbZone, getPlace } from "helper/format-data";
import { isEmpty, uniqBy } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { CityContext } from "contexts/city-context-provider";
import { GeoJsonLayer } from "@deck.gl/layers";
import { MapViewProps } from "../curb-zone.type";
import { copyLineString } from "helper/map-utils";
import { findObject } from "helper/array";
import { isNil } from "helper/utils";
import { useInteractMap } from "hooks/use-interact-map";
import { useQueryCurbZone } from "./use-query-curb-zone";
import useToggle from "hooks/use-toggle";
import { CurbZoneMultipleInput } from "../modes/add-curb-zone/curb-zone.type";

type UseCityParams = Pick<MapViewProps, "appMode"> & {
  isCurbZone: boolean;
};

type ChangeType = "create" | "update" | "bulkUpdate" | "delete" | "bulkDelete";

export const useCurbZone = ({ appMode, isCurbZone }: UseCityParams) => {
  const { currentCity, viewport, setViewport } = useContext(CityContext);
  const [markerPosition, setMarkerPosition] = useState<ViewportPlaceType | null>(null);
  const [openCurbZoneModal, setOpenCurbZoneModal] = useToggle(false);
  const [openCurbZoneMultipleModal, setOpenCurbZoneMultipleModal] = useToggle(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [isAltPressed, setIsAltPressed] = useState(false);
  const [isSpaceBarPressed, setIsSpaceBarPressed] = useToggle(false);

  const [curbZoneEditionData, setCurbZoneEditionData] = useState<FeatureCollection<LineString, CurbZone>>({
    type: "FeatureCollection",
    features: [],
  });

  const [currentLineParkingList, setCurrentLineParkingList] = useState<Feature<LineString, CurbZone>[]>([]);

  const [modeView, setModeView] = useState<ModeViewType>("normal");

  const [parkingPolygonData, setParkingPolygonData] = useState<Feature<Polygon, CurbZone>[]>([]);
  const [hoverInfo, setHoverInfo] = useState<TooltipType<CurbZone>>();
  const [curbZoneDetail, setCurbZoneDetail] = useState<Feature<Polygon, CurbZone>[]>([]);
  const [mapType, setMapType] = useState<MapType>(MapType.MAPBOX);
  const preParkingEdited = useRef<Feature<LineString, CurbZone>>();
  const preParkingEditedMulti = useRef<Feature<LineString, CurbZone>[]>([]);
  const currentPosition = useRef<Position>([]);
  const mapRef = useRef<any>();
  const preZooming = useRef<any>(null);
  const isCopyMode = useRef(false);

  const {
    setBounding,
    curbZones,
    loading,
    createCurbZone,
    updateCurbZone,
    removeCurbZone,
    updateMultiCurbZones,
    setIsResetData,
    bulkDeleteCurbZones,
    filter,
    changeFilter,
  } = useQueryCurbZone();

  const { onViewStateChange, handleZooming, getCurbZones } = useInteractMap({
    setBounding,
    viewport,
    preZooming,
    filter,
  });

  const currentIndexes = useMemo((): number[] => {
    if (curbZoneDetail.length === 0) {
      return [];
    }
    return filterParkingIndex(curbZoneEditionData, curbZoneDetail);
  }, [curbZoneEditionData, curbZoneDetail]);

  useEffect(() => {
    const listPolygon = curbZoneEditionData.features.map((item) => ({
      type: "Feature",
      geometry: item.properties.geometry,
      properties: item.properties,
      id: item.id,
    })) as Feature<Polygon, CurbZone>[];

    setParkingPolygonData(listPolygon);
    if (isCopyMode.current) {
      setCurbZoneDetail([listPolygon[listPolygon.length - 1]]);
      setCurrentLineParkingList([curbZoneEditionData.features[curbZoneEditionData.features.length - 1]]);
      isCopyMode.current = false;
    }
  }, [curbZoneEditionData]);

  useEffect(() => {
    if (appMode !== AppModeType.MultiEdit) {
      resetDataOfEditState();
    }
  }, [appMode]);

  useEffect(() => {
    if (isNil(currentCity)) return;
    if (currentCity?.position) {
      currentPosition.current = currentCity?.position;
    }

    resetDataOfEditState();
  }, [currentCity]);

  useEffect(() => {
    if (!currentCity || loading) {
      return;
    }
    const listParkingEdition: Feature<LineString, CurbZone>[] = [];
    curbZones.forEach((curbZone) => {
      const newParkingEdition = toLineStringFeature(curbZone);
      listParkingEdition.push(newParkingEdition);
    });

    if (currentLineParkingList.length && appMode === AppModeType.Edit) {
      const findSelectedParking = findObject(listParkingEdition, "id", currentLineParkingList[0].id);
      if (findSelectedParking > -1) {
        preParkingEdited.current = listParkingEdition[findSelectedParking];
        listParkingEdition[findSelectedParking] = currentLineParkingList[0];
      }
    }
    if (isCopyMode.current && currentLineParkingList.length > 0) {
      listParkingEdition.push(currentLineParkingList[0]);
    }
    setCurbZoneEditionData({
      type: "FeatureCollection",
      features: listParkingEdition,
    });
    setIsResetData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curbZones, loading]);

  useEffect(() => {
    if (!currentCity) {
      return;
    }
    getCurbZones(viewport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport, currentCity]);

  useEffect(() => {
    if (appMode === AppModeType.MultiEdit) {
      const handleKeyDownSpaceBar = (event: any) => {
        if (event.keyCode === 32 && !isSpaceBarPressed) {
          setIsSpaceBarPressed();
          setIsAltPressed(false);
          setIsShiftPressed(false);
        }
      };
      const handleKeyUpSpaceBar = (event: any) => {
        if (event.keyCode === 32 && isSpaceBarPressed) {
          setIsSpaceBarPressed();
        }
      };

      window.addEventListener("keydown", handleKeyDownSpaceBar);
      window.addEventListener("keyup", handleKeyUpSpaceBar);

      return () => {
        window.removeEventListener("keydown", handleKeyDownSpaceBar);
        window.removeEventListener("keyup", handleKeyUpSpaceBar);
        setIsAltPressed(false);
        setIsShiftPressed(false);
      };
    }
  }, [appMode, setIsSpaceBarPressed, isSpaceBarPressed]);

  useEffect(() => {
    /**
     * For appMode is multiEdit or edit mode
     * **/
    if (!["multiEdit", "edit"].includes(appMode)) {
      return;
    }

    const handleClickWithKeyboardDownMultiEdit = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setIsAltPressed(true);
        if (isShiftPressed) {
          setIsShiftPressed(false);
        }
      }
      if (event.key === "Shift") {
        setIsShiftPressed(true);
        if (isAltPressed) {
          setIsAltPressed(false);
        }
      }
    };

    const handleClickWithKeyboardUpMultiEdit = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setIsAltPressed(false);
      }
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    const handleClickWithKeyboardUpEdit = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    const handleClickWithKeyboardDownEdit = (event: KeyboardEvent) => {
      if (event.key === "Shift" && !isShiftPressed) {
        setIsShiftPressed(true);
      }
    };

    window.addEventListener("keydown", (evt) => {
      if (appMode === AppModeType.MultiEdit) {
        handleClickWithKeyboardDownMultiEdit(evt);
      }
      if (appMode === AppModeType.Edit) {
        handleClickWithKeyboardDownEdit(evt);
      }
    });

    window.addEventListener("keyup", (evt) => {
      if (appMode === AppModeType.Edit) {
        handleClickWithKeyboardUpEdit(evt);
      } else if (appMode === AppModeType.MultiEdit) {
        handleClickWithKeyboardUpMultiEdit(evt);
      }
    });

    return () => {
      window.removeEventListener("keydown", (evt) => {
        if (appMode === AppModeType.MultiEdit) {
          handleClickWithKeyboardDownMultiEdit(evt);
        }
        if (appMode === AppModeType.Edit) {
          handleClickWithKeyboardDownEdit(evt);
        }
      });

      window.removeEventListener("keyup", (evt) => {
        if (appMode === AppModeType.Edit) {
          handleClickWithKeyboardUpEdit(evt);
        } else if (appMode === AppModeType.MultiEdit) {
          handleClickWithKeyboardUpMultiEdit(evt);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMode]);

  const handleAddCurbZoneError = () => {
    /**
     * Remove the new curb-zone line.
     * */
    const newFeatures = curbZoneEditionData.features.toSpliced(-1, 1);
    setCurbZoneEditionData({ type: "FeatureCollection", features: newFeatures });
  };
  /** Bring together required elements and update city */
  const saveCurbZone = async (input: Feature<Polygon, CurbZone>) => {
    if (!currentCity) throw Error("Trying to update a non-existent city");
    const newCurbZone = convertPolygonToCurbZone(input, currentCity.id);
    const createCurbZoneParam = {
      curbZoneInput: newCurbZone,
    };
    if (input.id) {
      const res = await updateCurbZone({
        variables: createCurbZoneParam,
      });
      if (res.data?.updateCurbZone) {
        updateCurbZoneEditionData([res.data.updateCurbZone], "update");
      }
    } else {
      const res = await createCurbZone({
        variables: createCurbZoneParam,
      });
      if (res.data?.createCurbZone) {
        updateCurbZoneEditionData([res.data.createCurbZone], "create");
      } else {
        handleAddCurbZoneError();
      }
    }
  };

  const updateCurbZoneEditionData = (input: CurbZone[], isType: ChangeType) => {
    const newFeatures = curbZoneEditionData.features;
    if (isType === "create") {
      const newCurbZoneLineString = toLineStringFeature(input[0]);
      if (isCopyMode.current) {
        newFeatures.push(newCurbZoneLineString);
      } else {
        newFeatures[newFeatures.length - 1] = newCurbZoneLineString;
      }
    } else if (["update", "bulkUpdate"].includes(isType)) {
      for (const item of input) {
        const newCurbZone = toLineStringFeature(item);
        const fCurbZone = findObject(newFeatures, "id", newCurbZone.id);
        newFeatures[fCurbZone] = newCurbZone;
      }
    } else {
      for (const item of input) {
        const fCurbZone = findObject(newFeatures, "id", item.id);
        newFeatures.splice(fCurbZone, 1);
      }
    }

    setCurbZoneEditionData({ type: "FeatureCollection", features: newFeatures });
  };

  /** The function triggered when the editable layer is edited */
  const onEditLayer = async ({ updatedData, editType }: any) => {
    if (appMode === AppModeType.MultiEdit || !isCurbZone) {
      return;
    }
    let parkingIndex = currentIndexes[0];
    if (appMode === AppModeType.Edit) {
      /** Re-calculate the polygon when changing the shape of curb-zone */
      const newPolygon = convertLineToPolygon(
        updatedData.features[parkingIndex],
        updatedData.features[parkingIndex].properties.width,
      );
      updatedData.features[parkingIndex].properties.geometry = newPolygon.geometry;
      setCurbZoneEditionData(updatedData);
    } else {
      setCurbZoneEditionData(updatedData);
    }

    if (["finishMovePosition", "addFeature", "translated"].includes(editType)) {
      if (appMode === AppModeType.Add) {
        parkingIndex = updatedData.features.length - 1;
      }
      if (!updatedData.features[parkingIndex].properties) {
        return;
      }
      const newLineParking = await getPlace(updatedData.features[parkingIndex]);
      setCurrentLineParkingList([newLineParking]);
      if (appMode === AppModeType.Add) {
        preParkingEdited.current = Object.assign({});
        setOpenCurbZoneModal();
      } else {
        await addPropertiesAndSave(newLineParking.properties, newLineParking);
      }
    }
  };

  const editableLayerMode = useMemo(() => {
    if (isShiftPressed && appMode === AppModeType.Edit) {
      return TranslateMode;
    }
    if (["add", "edit", "multiEdit"].includes(appMode)) {
      return editableGeoJsonLayerModes[appMode];
    }
    return ViewMode;
  }, [isShiftPressed, appMode]);

  const editableLayer = new EditableGeoJsonLayer({
    id: EditableCurbZoneLayerName,
    data: { type: curbZoneEditionData.type, features: curbZoneEditionData.features as FeatureOf<LineString>[] },
    selectedFeatureIndexes: currentIndexes,
    mode: editableLayerMode,
    onEdit: onEditLayer,
    visible: appMode !== AppModeType.View,
  });
  const parkingLayer = new EditableGeoJsonLayer({
    id: "parking-layer",
    data: { type: curbZoneEditionData.type, features: curbZoneEditionData.features as FeatureOf<LineString>[] },
    mode: editableGeoJsonLayerModes["view"],
    onHover: (info: any) => {
      if (info.object && appMode === AppModeType.View) {
        setHoverInfo({
          x: info.x,
          y: info.y,
          properties: info.object.properties,
        });
      } else if (!isEmpty(hoverInfo)) {
        setHoverInfo({} as TooltipType<CurbZone>);
      }
    },
    visible: appMode === AppModeType.View,
  });

  const polygonLayer = new GeoJsonLayer({
    id: "polygon-layer",
    data: parkingPolygonData,
    stroked: true,
    getFillColor: (feature: any) => getPolygonColor(feature, "fillColor"),
    getLineColor: (feature: any) => getPolygonColor(feature, "lineColor"),
    lineWidthMaxPixels: 1,
  });

  const currentCityLayer = new GeoJsonLayer({
    id: "current-city",
    data: currentCity?.boundaries,
    filled: false,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 4,
  });

  const selectMultipleCurbZone = ({ pickingInfos }: any) => {
    if (pickingInfos.length > 0) {
      let selectedCurbZones = filterLineStringFromPoint(pickingInfos);
      if (appMode === AppModeType.MultiEdit && isAltPressed) {
        selectedCurbZones = uniqBy([...curbZoneDetail, ...selectedCurbZones], "id");
      }
      setCurbZoneDetail(selectedCurbZones);
      preParkingEditedMulti.current = selectedCurbZones;
      if (preParkingEditedMulti.current) {
        setCurrentLineParkingList(preParkingEditedMulti.current);
      }
    }
  };

  const selectionType = useMemo(() => {
    if (isShiftPressed || appMode !== AppModeType.MultiEdit) {
      return "none";
    }
    return isAltPressed ? "rectangle" : "polygon";
  }, [isAltPressed, isShiftPressed, appMode]);

  const selectionMultipleCurbZoneLayer = new SelectionLayer({
    id: "selection-rectangle",
    selectionType,
    layerIds: [EditableCurbZoneLayerName],
    onSelect: selectMultipleCurbZone,
    lineWidthMinPixels: 1,
    visible: appMode === AppModeType.MultiEdit && !isShiftPressed,
  });

  /** Add parking area property to parkingAreaProperties */
  const addPropertiesAndSave = async (promptedProperties: CurbZone, lineParking?: Feature<LineString, CurbZone>) => {
    if (openCurbZoneModal) {
      setOpenCurbZoneModal();
    }
    if (currentLineParkingList?.length === 1) {
      const parking = convertLineToPolygon(lineParking || currentLineParkingList[0], promptedProperties.width);
      parking.properties = { ...parking.properties, ...promptedProperties };
      await saveCurbZone(parking);
      if (openCurbZoneModal) {
        resetDataOfEditState();
      } else {
        setCurbZoneDetail([parking]);
        setCurrentLineParkingList([lineParking || currentLineParkingList[0]]);
        preParkingEdited.current = lineParking || currentLineParkingList[0];
      }
    }
  };

  /** Add new regulations, width and parking angle to multiple Parkings */
  const handleEditMultipleCurbZone = async (input: CurbZoneMultipleInput) => {
    setOpenCurbZoneMultipleModal();
    if (!currentCity) throw Error("Trying to update a non-existent city");
    if (currentLineParkingList) {
      const newCurrentLineParkingList = currentLineParkingList.map((line) => {
        const parking = convertLineToPolygon(line, input.width);
        parking.properties = {
          ...parking.properties,
          ...input,
        };
        return convertPolygonToCurbZone(parking, currentCity.id);
      });
      const res = await updateMultiCurbZones({ variables: { curbZoneInputs: newCurrentLineParkingList } });
      if (res.data?.bulkUpdateCurbZones) {
        updateCurbZoneEditionData(res.data.bulkUpdateCurbZones, "bulkUpdate");
      }
      resetDataOfEditState();
    }
  };

  /**
   * Remove features which indexes are present in `selectedFeatureIndexes`.
   * This may also allow to delete last added features.
   */
  const removeSelectedFeatures = () => {
    const newParkingEdition = [...curbZoneEditionData.features];
    const isEdit = curbZoneDetail[0]?.id;
    if (isEdit) {
      //Modal canceled when edit mode, revert the polygon to what it was before
      newParkingEdition[currentIndexes[0]] = preParkingEdited.current as Feature<LineString, CurbZone>;
      const newPolygon = convertLineToPolygon(
        newParkingEdition[currentIndexes[0]],
        newParkingEdition[currentIndexes[0]].properties.width,
      );
      newParkingEdition[currentIndexes[0]].properties.geometry = newPolygon.geometry;
    } else {
      // Modal canceled when in add mode, remove last added parking
      newParkingEdition.pop();
    }
    resetDataOfEditState();
    setCurbZoneEditionData({ type: "FeatureCollection", features: newParkingEdition });
    preParkingEdited.current = undefined;
    if (openCurbZoneModal) {
      setOpenCurbZoneModal();
    }
  };

  const resetDataOfEditState = () => {
    setCurbZoneDetail([]);
    preParkingEdited.current = undefined;
    setCurrentLineParkingList([]);
    isCopyMode.current = false;
  };

  const cancelEditMultiple = () => {
    if (openCurbZoneMultipleModal) setOpenCurbZoneMultipleModal();
    setCurbZoneDetail([]);
    preParkingEditedMulti.current = [] as Feature<LineString, CurbZone>[];
    setCurrentLineParkingList([] as Feature<LineString, CurbZone>[]);
  };

  const showCurbZoneDetail = useMemo(() => {
    if (appMode !== AppModeType.Edit) {
      return false;
    }
    return curbZoneDetail && curbZoneDetail.length === 1;
  }, [appMode, curbZoneDetail]);

  const showCurbZoneMultiDetail = useMemo(
    () => appMode === AppModeType.MultiEdit && curbZoneDetail && curbZoneDetail.length > 0,
    [appMode, curbZoneDetail],
  );

  const handleSelectCurbZone = (info: any) => {
    if (["add", "view"].includes(appMode)) {
      return;
    }

    if (!info.object || info.layer?.id !== EditableCurbZoneLayerName || !info.object?.id) {
      if (appMode === AppModeType.MultiEdit) {
        return;
      }
      resetDataOfEditState();
      cancelEditMultiple();
      return;
    }

    const fParking = findObject(parkingPolygonData, "id", info.object.id);
    if (appMode === AppModeType.MultiEdit && isShiftPressed && fParking > -1) {
      // If already selected we do not add it twice
      const indexOfCurrentSelection = findObject(preParkingEditedMulti.current, "id", info.object.id);
      const indexOfFirstSelection = findObject(preParkingEditedMulti.current, "id", preParkingEdited.current?.id);
      if (indexOfCurrentSelection < 0) {
        setCurbZoneDetail((state) => [...state, info.object]);
        // If we have already one parking selected we add it

        if (preParkingEdited.current && preParkingEdited.current?.id && indexOfFirstSelection < 0) {
          preParkingEditedMulti.current.push(preParkingEdited.current);
        }
        preParkingEditedMulti.current.push(curbZoneEditionData.features[fParking]);
        setCurrentLineParkingList(preParkingEditedMulti.current);
      }
    } else {
      setCurbZoneDetail([info.object]);
      preParkingEdited.current = info.object;
      setCurrentLineParkingList([info.object]);
    }
  };

  const cancelChange = () => {
    removeSelectedFeatures();
  };
  const openParkingModal = async () => {
    const newLineParking = await getPlace(preParkingEdited.current as Feature<any>);
    setCurrentLineParkingList([newLineParking]);
    setOpenCurbZoneModal();
  };

  const curbZoneLayers = [parkingLayer, editableLayer, selectionMultipleCurbZoneLayer, polygonLayer, currentCityLayer];

  const selectAddress = (viewport: ViewportPlaceType | null) => {
    setMarkerPosition(viewport);
    if (viewport) {
      setViewport((prevState) => ({ ...prevState, ...viewport, zoom: 16 }));
    }
  };

  const handleDragEnd = (event: any) => {
    if (appMode === AppModeType.MultiEdit && !isSpaceBarPressed) return;
    const { viewport } = event;
    const newPosition = [viewport?.longitude, viewport?.latitude];
    currentPosition.current = [...newPosition];
    getCurbZones(viewport);
  };

  const handleDeleteCurbZone = async (curbZoneId: string) => {
    const res = await removeCurbZone({ variables: { curbZoneId } });
    if (res.data?.deleteCurbZone) {
      updateCurbZoneEditionData([res.data.deleteCurbZone], "delete");
    }
    resetDataOfEditState();
  };

  const handleBulkDeleteCurbZones = async (input: string[]) => {
    const res = await bulkDeleteCurbZones({ variables: { curbZoneIds: input } });
    if (res.data?.bulkDeleteCurbZones) {
      updateCurbZoneEditionData(res.data.bulkDeleteCurbZones, "bulkDelete");
    }
  };

  const handleCopyCurbZone = async () => {
    const copiedCurbZone = (await copyLineString(currentLineParkingList[0])) as Feature<LineString, CurbZone>;
    isCopyMode.current = true;

    copiedCurbZone.properties.curbPolicyIds = copiedCurbZone.properties.curbPolicies.map(
      (value) => value?.curbPolicyId as string,
    );

    await addPropertiesAndSave(copiedCurbZone.properties, copiedCurbZone);
    setCurrentLineParkingList([]);
    setCurbZoneDetail([]);
  };

  return {
    markerPosition,
    setMarkerPosition,
    viewport,
    modeView,
    setModeView,
    selectAddress,
    mapType,
    setMapType,
    curbZoneLayers,
    handleDragEnd,
    handleSelectCurbZone,
    handleZooming,
    hoverInfo,
    mapRef,
    onViewStateChange,
    openCurbZoneModal,
    addPropertiesAndSave,
    currentLineParkingList,
    curbZoneDetail,
    removeSelectedFeatures,
    currentCity,
    showCurbZoneDetail,
    parkingPolygonData,
    currentIndexes,
    resetDataOfEditState,
    openParkingModal,
    handleDeleteCurbZone,
    showCurbZoneMultiDetail,
    cancelEditMultiple,
    setOpenCurbZoneMultipleModal,
    openCurbZoneMultipleModal,
    handleEditMultipleCurbZone,
    cancelChange,
    preZooming,
    handleBulkDeleteCurbZones,
    isSpaceBarPressed,
    filter,
    changeFilter,
    handleCopyCurbZone,
    isCopyMode,
  };
};
