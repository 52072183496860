import { MacButton, Confirm } from "components";
import { DataTable, TableBody, TableRow, TableCell } from "components/data-table";
import { useQueryParkingLot } from "modules/curb-zone/hooks/use-query-parking-lot";
import { useState } from "react";
import { ParkingLot } from "services/web/api-parking-lot.type";

interface ParkingLotDetailProps {
  parkingLotDetail?: ParkingLot;
  setParkingLotDetail: (params: ParkingLot | undefined) => void;
  checkCurbZoneExistBeforeUpdate: VoidFunction;
}
export const ParkingLotDetail = ({
  parkingLotDetail,
  setParkingLotDetail,
  checkCurbZoneExistBeforeUpdate,
}: ParkingLotDetailProps) => {
  const { handleDeleteParkingLot } = useQueryParkingLot();
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleEdit = () => checkCurbZoneExistBeforeUpdate();
  const handleDelete = async () => {
    if (parkingLotDetail?.id) {
      handleDeleteParkingLot({ id: parkingLotDetail.id });
      setOpenConfirm(false);
      setParkingLotDetail(undefined);
    }
  };
  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80">
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Name</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {parkingLotDetail?.name}
              </TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Address</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {parkingLotDetail?.address}
              </TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Note</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {parkingLotDetail?.note}
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between mt-4">
          <MacButton variant="error" onClick={() => setOpenConfirm(true)}>
            Delete
          </MacButton>
          <MacButton variant="secondary" onClick={handleEdit}>
            Edit
          </MacButton>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete parking lot"
          content="Are you sure that you want to delete this parking lot?"
          onCancel={() => setOpenConfirm(false)}
          onOk={handleDelete}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
