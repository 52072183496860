import { IconButton } from "components";
import {
  DataTable,
  HeaderItem,
  SortDirection,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderProps,
  TableRow,
  TableSkeleton,
} from "components/data-table";
import { Pagination } from "components/data-table/Pagination";
import { DeleteIcon, EditIcon } from "components/icons";
import { CityContext } from "contexts/city-context-provider";
import { formatDate } from "helper/date-time";
import { formatPolicyColorToString, isEmpty } from "helper/utils";
import { ComponentType, useContext, useMemo, useState } from "react";
import { Policy, initPage } from "types";
import { usePolicies } from "../hooks/use-policies";
import { defaultCells, defaultSort } from "../policies.const";
import { sortAsc, sortDesc } from "helper/array";

export type PolicyTableProps = {
  selectPolicy: (input: Policy) => void;
  onDelete: (input: Policy) => void;
};

export type SortOption = {
  orderBy: keyof Policy;
  direction: SortDirection;
};

const TableHeaderWithPolicy: ComponentType<TableHeaderProps<keyof Policy>> = TableHeader;

export const PolicyTable = ({ selectPolicy, onDelete }: PolicyTableProps) => {
  const { currentCity } = useContext(CityContext);
  const { policyList, loading } = usePolicies();
  const [currentPage, setCurrentPage] = useState({ ...initPage });
  const [headerCells, setHeaderCells] = useState<HeaderItem<keyof Policy>[]>(defaultCells);
  const [currentSort, setCurrentSort] = useState<SortOption>(defaultSort);

  const policyListSorted = useMemo(() => {
    if (loading) {
      return [];
    }

    if (currentSort.direction === SortDirection.ASC) {
      return sortAsc(Object.assign([], policyList), currentSort.orderBy);
    } else {
      return sortDesc(Object.assign([], policyList), currentSort.orderBy);
    }
  }, [loading, currentSort, policyList]);

  const policyListPaginate = useMemo(() => {
    return policyListSorted.slice(
      (currentPage.pageIndex - 1) * currentPage.pageSize,
      currentPage.pageSize * currentPage.pageIndex,
    );
  }, [policyListSorted, currentPage]);

  const onChangePage = (page: number) => {
    setCurrentPage({ ...currentPage, pageIndex: page });
  };

  if (!currentCity) {
    return <EmptyData />;
  }

  const handleSort = (orderBy: keyof Policy, direction: SortDirection) => {
    const newCells = headerCells.map((cell) => {
      if (cell.orderBy === orderBy) {
        return {
          ...cell,
          sortDirection: direction,
        };
      }
      return cell;
    });
    setCurrentSort({ orderBy, direction });
    setHeaderCells(newCells);
  };

  const totalPage = Math.ceil(policyList.length / currentPage.pageSize);

  return (
    <div className="flex flex-col w-full gap-y-2 ">
      <div className="w-full bottom-2 pb-8 h-full">
        <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
          <TableHeaderWithPolicy
            className="text-black bg-white text-sm border-b-2 border-secondary"
            columnName={headerCells}
            onSort={handleSort}
          />
          <TableBody className="min-h-fit  max-h-full">
            {loading ? (
              <TableSkeleton />
            ) : (
              <>
                {isEmpty(policyListPaginate) ? (
                  <GenNoRecord colSpan={headerCells.length} message={loading ? "Loading..." : "No results found."} />
                ) : (
                  policyListPaginate.map((policy, idx) => (
                    <TableRow className="bg-white align-middle p-4 hover:none" key={`city-list-${idx}`}>
                      <TableCell
                        key={`name-cell-${idx}`}
                        className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                      >
                        <div
                          style={{
                            borderStyle: policy.color?.dashed ? "dashed" : "solid",
                            borderWidth: policy.color?.lineColor ? "1px" : "0px",
                            borderColor: policy.color?.lineColor
                              ? formatPolicyColorToString(policy.color?.lineColor)
                              : "",
                            backgroundColor: policy.color?.fillColor
                              ? formatPolicyColorToString(policy.color?.fillColor)
                              : "",
                          }}
                          className="p-1"
                        >
                          {policy.name}
                        </div>
                      </TableCell>
                      <TableCell
                        key={`published-date-cell-${idx}`}
                        className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                      >
                        {formatDate(policy.publishedDate || "", { format: "d MMM yyyy HH:mm" })}
                      </TableCell>
                      <TableCell
                        key={`category-cell-${idx}`}
                        className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                      >
                        {policy.category || "-"}
                      </TableCell>
                      <TableCell
                        key={`upciti-cell-${idx}`}
                        className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium text-right"
                      >
                        {policy.priority}
                      </TableCell>
                      <TableCell
                        key={`action-cell-${idx}`}
                        className="px-6 py-2 whitespace-nowrap font-medium  flex flex-row gap-x-2 justify-center"
                      >
                        <IconButton
                          key={`edit-button-cell-${idx}`}
                          className="border-transparent text-secondary rounded-md w-10 h-10 hover:bg-secondary bg-primary-active p-0 m-0"
                          onClick={() => selectPolicy(policy)}
                        >
                          <EditIcon key={`edit-icon-cell-${idx}`} className="h-5 w-5" stroke="black" />
                        </IconButton>
                        <IconButton
                          key={`delete-button-cell-${idx}`}
                          className="border-transparent  rounded-md w-10 h-10 hover:bg-secondary  p-0 m-0"
                          onClick={() => onDelete(policy)}
                        >
                          <DeleteIcon key={`delete-icon-cell-${idx}`} className="h-5 w-5 " />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </>
            )}
          </TableBody>
        </DataTable>
      </div>
      <div className="mb-2 flex flex-col items-end">
        <Pagination
          onChangePage={onChangePage}
          currentPage={currentPage.pageIndex}
          perPage={currentPage.pageSize}
          totalPage={totalPage}
        />
      </div>
    </div>
  );
};

interface GenNoRecordProps {
  colSpan?: number;
  message?: string;
}

export const GenNoRecord = ({ colSpan = 5, message = "No results found" }: GenNoRecordProps) => {
  return (
    <tr className="bg-default">
      <td className="px-6 py-4 whitespace-nowrap bg-white	 text-primary" colSpan={colSpan}>
        <div className="flex items-center justify-center">{message}</div>
      </td>
    </tr>
  );
};

export const EmptyData = () => (
  <div className="flex flex-col w-full gap-y-2 ">
    <div className="w-full bottom-2 pb-8 h-full">
      <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
        <TableHeader className="text-black bg-white text-sm border-b-2 border-secondary" columnName={defaultCells} />
        <TableBody className="min-h-fit  max-h-full">
          <GenNoRecord message="Please select a city" colSpan={4} />
        </TableBody>
      </DataTable>
    </div>
  </div>
);
