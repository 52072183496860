import * as React from "react";
import { Autocomplete } from "components/form-control";
import { ChangeEvent, useMemo } from "react";
import { ViewportPlaceType } from "types/map.type";
import { useDebounce } from "hooks/lib-ui";
import { usePlacesQuery } from "services/web/map.queries";
import { addressPosition } from "services/web/mapbox.service";
import { isEmpty } from "helper/utils";

interface ICoordinates {
  latitude: number;
  longitude: number;
}

type searchPlaceProps = {
  onSelect: (input: ViewportPlaceType | null) => void;
  coordinates?: ICoordinates;
};
export const SearchPlace = ({ onSelect, coordinates }: searchPlaceProps) => {
  const [value, setValue] = React.useState<string>("");
  const searchString = useDebounce(value, 500);

  const handleSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setValue(value);
    if (isEmpty(value)) {
      onSelect(null);
    }
  };
  const { data, isLoading } = usePlacesQuery({
    keyWord: searchString,
    coordinates: coordinates,
  });
  const listPlace = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((item: addressPosition) => {
        return {
          value: `${item.latitude}:${item.longitude}`,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const handleSelectPlace = (value: string, label: string) => {
    setValue(label);
    const addressPoint = value.split(":");
    onSelect({ longitude: Number(addressPoint[0]), latitude: Number(addressPoint[1]), name: label });
  };
  return (
    <div className="w-80 transition-opacity h-11 bg-default p-1 mt-16 mb-2 rounded-md shadow-lg">
      <Autocomplete
        variant="underline"
        onChangeValue={handleSelectPlace}
        value={value}
        onChange={handleSearch}
        optionData={listPlace}
        isLoading={isLoading}
        placeholder="Search street..."
      />
    </div>
  );
};
