import React from "react";
import { MenuIcon } from "../icons";
import { UserView } from "./user-view";
import { CityListing } from "./city-listing";
import { twMerge } from "tailwind-merge";

export type HeaderProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  showCityList: boolean | undefined;
  className?: string;
};

export const Header = ({ sidebarOpen, setSidebarOpen, title, showCityList, className }: HeaderProps) => {
  return (
    <header className={twMerge(className, "sticky top-0 z-10")}>
      <div className="mt-15px mx-8">
        <div className="flex items-center justify-between h-50px">
          <div className="flex">
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon />
            </button>
            <h1 className="page-title">{title}</h1>
          </div>
          <div className="flex justify-end items-center">
            <div className="flex items-center">
              {showCityList && <CityListing />}
              <UserView />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
