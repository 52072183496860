import { SVGProps } from "react";

export const CityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffffff"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="-2.64 7.056 24 24"
    width="24"
    height="24"
    {...props}
  >
    <title>Cities</title>
    <g>
      <path d="M18.773 15.59l-4.45 -2.64c0 0 -0.005 -0.005 -0.011 -0.005c0 0 -0.005 0 -0.005 -0.005c-0.005 0 -0.005 0 -0.011 -0.005c-0.011 -0.005 -0.019 -0.011 -0.035 -0.013c-0.005 0 -0.011 -0.005 -0.011 -0.005c-0.028 -0.011 -0.061 -0.019 -0.091 -0.024c-0.005 0 -0.011 0 -0.013 0c-0.013 0 -0.024 0 -0.035 0c-0.005 0 -0.011 0 -0.011 0c-0.013 0 -0.028 0 -0.044 0.005c-0.005 0 -0.005 0 -0.011 0c-0.011 0 -0.024 0.005 -0.035 0.011c-0.005 0 -0.011 0.005 -0.013 0.005c-0.013 0.005 -0.024 0.011 -0.038 0.013c0 0 -0.005 0 -0.005 0.005c-0.013 0.005 -0.028 0.013 -0.044 0.024c0 0 0 0 -0.005 0l-3.923 2.458c-0.109 0.072 -0.181 0.192 -0.181 0.326v4.536c0 0.211 0.173 0.384 0.389 0.384s0.389 -0.173 0.389 -0.384v-4.325l3.144 -1.972v8.188L11.245 21.12c-0.013 -0.005 -0.028 -0.011 -0.038 -0.013c-0.005 0 -0.005 0 -0.011 0c-0.011 -0.005 -0.019 -0.005 -0.035 -0.011c-0.005 0 -0.011 0 -0.013 0c-0.011 0 -0.019 -0.005 -0.028 -0.005c-0.005 0 -0.011 0 -0.013 0c-0.005 0 -0.011 0 -0.013 0c-0.005 0 -0.005 0 -0.011 0s-0.011 0 -0.013 0c-0.011 0 -0.019 0 -0.024 0.005c-0.005 0 -0.011 0 -0.013 0c-0.011 0 -0.019 0.005 -0.028 0.005c-0.005 0 -0.011 0 -0.013 0.005c-0.011 0.005 -0.019 0.005 -0.028 0.011c-0.005 0 -0.005 0 -0.011 0.005c-0.011 0.005 -0.024 0.011 -0.035 0.013h-0.005l0 0l0 0l-3.619 1.909c-0.124 0.068 -0.206 0.197 -0.206 0.341V30.672c0 0.211 0.173 0.384 0.389 0.384H15.312c0.216 0 0.389 -0.173 0.389 -0.384V23.252c0 -0.154 -0.091 -0.292 -0.235 -0.355l-0.956 -0.404V13.957l3.676 2.179v0.764l-2.093 -1.147c-0.131 -0.072 -0.288 0.024 -0.288 0.168v0.211c0 0.211 0.115 0.404 0.301 0.509l2.083 1.139v0.98L16.09 17.616c-0.131 -0.072 -0.288 0.024 -0.288 0.168v0.211c0 0.211 0.115 0.404 0.301 0.509l2.083 1.139V30.288h-1.276c-0.216 0 -0.389 0.173 -0.389 0.384s0.173 0.384 0.389 0.384H18.576c0.216 0 0.389 -0.173 0.389 -0.384V15.922C18.96 15.788 18.888 15.662 18.773 15.59zM7.858 23.62l2.852 -1.501V30.288h-2.852L7.858 23.62L7.858 23.62zM14.928 30.288h-3.446V22.056l2.486 1.045c0 0 0 0 0.005 0l0.956 0.404V30.288z" />
      <path d="M11.002 13.283c0 0.211 0.173 0.384 0.389 0.384s0.389 -0.173 0.389 -0.384v-2.952c0 -0.144 -0.083 -0.277 -0.216 -0.347L5.76 7.099c-0.019 -0.011 -0.044 -0.019 -0.068 -0.028c-0.019 -0.005 -0.038 -0.011 -0.053 -0.013c-0.091 -0.013 -0.181 0.005 -0.264 0.053l-5.434 3.408c-0.109 0.072 -0.181 0.192 -0.181 0.326V30.672c0 0.211 0.173 0.384 0.389 0.384h5.434c0.216 0 0.389 -0.173 0.389 -0.384V8.064l5.036 2.501L11.002 13.283L11.002 13.283zM5.194 30.288h-4.656V14.549l2.045 -1.117c0.173 -0.096 0.283 -0.277 0.283 -0.476V12.672c0 -0.124 -0.134 -0.203 -0.244 -0.144l-2.083 1.141v-0.98L2.582 11.568c0.173 -0.096 0.283 -0.277 0.283 -0.476v-0.283c0 -0.124 -0.134 -0.203 -0.244 -0.144L0.538 11.808v-0.754l4.656 -2.915V30.288z" />
    </g>
  </svg>
);
