import * as React from "react";

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>search</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Ecran_1" transform="translate(-1111.000000, -28.000000)">
        <g id="Menu-Head" transform="translate(112.000000, 15.000000)">
          <g id="search" transform="translate(986.000000, 0.000000)">
            <rect id="Rectangle" x="0" y="0" width="50" height="50" />
            <g
              id="icon"
              transform="translate(14.000000, 14.000000)"
              stroke="#25292D"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line
                x1="20.7534727"
                y1="20.8935108"
                x2="16.7586902"
                y2="16.7640475"
                id="Path"
                transform="translate(18.756081, 18.828779) rotate(-0.949742) translate(-18.756081, -18.828779) "
              />
              <path
                d="M8.30267492,16.6053498 C12.8881157,16.6053498 16.6053498,12.8881157 16.6053498,8.30267492 C16.6053498,3.71723418 12.8881157,0 8.30267492,0 C3.71723418,0 0,3.71723418 0,8.30267492 C0,12.8881157 3.71723418,16.6053498 8.30267492,16.6053498 Z"
                id="Oval"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
