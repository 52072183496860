import { useFormContext } from "react-hook-form";
import { CityInput } from "types";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "assets";
import { DecreeAnalysisCountListing } from "./DecreeAnalysisCountListing";

export const CityOptions = () => {
  const { register, watch } = useFormContext<CityInput>();
  const enableDecreeAnalysisCount = watch("options.decreeAnalysis");
  return (
    <>
      <div className="w-full flex flex-col">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <DisclosureButton className="flex w-full justify-between items-center rounded-md shadow-lg bg-white  px-4 py-2 text-left text-sm font-medium">
                <h1 className="font-bold text-md">Options</h1>{" "}
                <ChevronDownIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-purple-500`} />
              </DisclosureButton>
              <DisclosurePanel className="px-4 pt-4 pb-2 text-sm whitespace-nowrap">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 w-full gap-4 justify-between">
                  <div className="flex flex-row gap-3 items-center">
                    <input type="checkbox" className="cursor-pointer" id="live" {...register("options.live")} />
                    <label className="cursor-pointer" htmlFor="live">
                      Live
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id="offstreet"
                      {...register("options.offstreet")}
                    />
                    <label className="cursor-pointer" htmlFor="offstreet">
                      Offstreet
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input type="checkbox" className="cursor-pointer" id="payment" {...register("options.payment")} />
                    <label className="cursor-pointer" htmlFor="payment">
                      Payment
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id="paymentStats"
                      {...register("options.paymentStats")}
                    />
                    <label className="cursor-pointer" htmlFor="paymentStats">
                      Payment stats
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id="prediction"
                      {...register("options.prediction")}
                    />
                    <label className="cursor-pointer" htmlFor="prediction">
                      Prediction
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id="mapIntegration"
                      {...register("options.mapIntegration")}
                    />
                    <label className="cursor-pointer" htmlFor="mapIntegration">
                      Map Integration
                    </label>
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id="decreeAnalysis"
                      {...register("options.decreeAnalysis")}
                    />
                    <label className="cursor-pointer" htmlFor="decreeAnalysis">
                      Decree Analysis
                    </label>
                  </div>
                </div>
                {enableDecreeAnalysisCount && <DecreeAnalysisCountListing />}
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
