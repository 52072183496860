import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { ReactNode } from "react";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingComponent } from "../components/loading-component";
import { showNotification } from "../components/notification";
import { routes } from "../routes/constants";

interface GraphQlProviderProps {
  children: ReactNode;
}

export const GraphqlProvider = ({ children }: GraphQlProviderProps) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, user, error: errorAuth0, isLoading } = useAuth0();

  const authMiddleware = setContext(async (_, { headers }) => {
    if (errorAuth0) {
      window.location.replace(routes.error.path);
      return;
    }
    let token;
    if (user) {
      const fetchAccessToken = async () => {
        try {
          token = await getAccessTokenSilently();
        } catch (e) {
          if ((e as any).error === "consent_required") {
            token = await getAccessTokenWithPopup();
          } else {
            showNotification({ variant: "error", message: (e as any).message });
            window.location.replace(routes.error.path);
          }
        }
      };
      await fetchAccessToken();
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const httpLink = new HttpLink({ uri: import.meta.env.VITE_API_SITES_BASE_URL });

  const graphQlClient = new ApolloClient({
    link: ApolloLink.from([
      authMiddleware,
      onError(({ graphQLErrors, networkError }) => {
        if (networkError) {
          showNotification({ variant: "error", message: `[Network error]: ${networkError}` });
        } else if (graphQLErrors) {
          const errorMessage = graphQLErrors[0].message;
          showNotification({ variant: "error", message: `[Input error]: ${errorMessage}` });
        }
      }),
      httpLink,
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  if (isLoading) {
    return <LoadingComponent />;
  }
  return <ApolloProvider client={graphQlClient}>{children}</ApolloProvider>;
};
