import { SortDirection } from "components/data-table";
import { useContext, useEffect, useState } from "react";
import { CityContext } from "contexts/city-context-provider";
import { useQueryListDecreeFile } from "./use-query-decree-file";
import { PaginatedDecreeArgs } from "types/decree.type";

export const paramsDefault: PaginatedDecreeArgs = {
  pageIndex: 1,
  pageSize: 10,
  sortField: "createdAt",
  sortOrder: SortDirection.DESC,
  filter: [],
};

export const useDecree = () => {
  const { currentCity } = useContext(CityContext);
  const [decreeParams, setDecreeParams] = useState<PaginatedDecreeArgs>({
    ...paramsDefault,
  });

  useEffect(() => {
    if (currentCity?.id) {
      setDecreeParams({
        ...paramsDefault,
        filter: [{ field: "cityId", value: currentCity.id }],
      });
    }
  }, [currentCity]);

  const { decreeData, isLoading, refetchDecree } = useQueryListDecreeFile({ ...decreeParams });

  return { decreeData, isLoading, setDecreeParams, refetchDecree, decreeParams };
};
