import { TimeSpanInput } from "types";
import { DaysOfWeek } from "types/policty.enum";
import { TimeSpanComponentOptionProps } from "./time-span.type";

export const defaultOptions: TimeSpanComponentOptionProps = {
  showDesignatedPeriod: false,
  showDesignatedPeriodExcept: false,
  showSelectDate: false,
  showTimeOfDay: true,
};

export const defaultTimeSpan: TimeSpanInput = {
  startDate: null,
  endDate: null,
  daysOfWeek: [],
  daysOfMonth: [],
  months: [],
  timeOfDayStart: "",
  timeOfDayEnd: "",
};

export type DaysOfWeekInput = {
  name: DaysOfWeek;
  display: string;
  isCheck: boolean;
};

export const arrDay: DaysOfWeekInput[] = [
  {
    name: DaysOfWeek.Monday,
    display: "Monday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Tuesday,
    display: "Tuesday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Wednesday,
    display: "Wednesday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Thursday,
    display: "Thursday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Friday,
    display: "Friday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Saturday,
    display: "Saturday",
    isCheck: false,
  },
  {
    name: DaysOfWeek.Sunday,
    display: "Sunday",
    isCheck: false,
  },
];
