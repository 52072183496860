import { gql } from "@apollo/client";

export const QUERY_POLICIES = gql`
  query policiesByCityId($cityId: String!) {
    policiesByCityId(cityId: $cityId) {
      id
      curbPolicyId
      cityId
      name
      priority
      category
      rules {
        activity
        maxStay
        maxStayUnit
        noReturn
        noReturnUnit
        rate {
          rate
          endDuration
          incrementAmount
          incrementDuration
          rateUnit
          startDuration
          rateUnitPeriod
        }
        userClasses
      }
      timeSpans {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
        designatedPeriod
        designatedPeriodExcept
      }
      color {
        dashed
        fillColor
        lineColor
      }
      publishedDate
    }
  }
`;

export const QUERY_CREATE_POLICY = gql`
  mutation addPolicy($createPolicyInput: CreatePolicyInput!) {
    createPolicy(createPolicyInput: $createPolicyInput) {
      id
      curbPolicyId
    }
  }
`;

export const QUERY_UPDATE_POLICY = gql`
  mutation addPolicy($updatePolicyInput: UpdatePolicyInput!) {
    updatePolicy(updatePolicyInput: $updatePolicyInput) {
      id
    }
  }
`;

export const QUERY_REMOVE_POLICY = gql`
  mutation deletePolicy($policyId: String!) {
    deletePolicy(policyId: $policyId) {
      id
    }
  }
`;
