import clsx from "clsx";
import * as React from "react";
import { DeleteIcon } from "./icons";
export interface BadgeProps extends Omit<React.ComponentPropsWithoutRef<"span">, "color" | "size"> {
  size?: "small" | "large";
  variant?: "grey" | "success" | "info" | "warning" | "error";
  onRemove?: (ev: React.MouseEvent) => void;
  children: React.ReactNode;
  weight?: "bold" | "normal";
}

export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  { size = "small", variant = "grey", children, onRemove, className, weight = "bold", ...spanProps },
  ref,
) {
  return (
    <span
      {...spanProps}
      className={clsx(
        "inline-flex items-center focus:outline-none rounded-full",
        weight === "bold" ? "font-bold" : "font-normal",
        wrapperClassByColor[variant],
        wrapperClassBySize[size],
        className,
      )}
      ref={ref}
    >
      {children}
      {onRemove && (
        <div className="ml-1.5">
          <DeleteIcon
            className={`${iconClassBySize[size]} inline-flex focus:outline-none hover:cursor-pointer`}
            onClick={onRemove}
            type="button"
          />
        </div>
      )}
    </span>
  );
});

const wrapperClassBySize: Record<NonNullable<BadgeProps["size"]>, string> = {
  small: "px-2.5 py-0.5 text-xs leading-4",
  large: "px-3 py-1 text-sm leading-5",
};

type BadgeColor = NonNullable<BadgeProps["variant"]>;

const wrapperClassByColor: Record<BadgeColor, string> = {
  success: "bg-green-100 text-green-500 focus-visible:shadow-outline-green",
  info: "bg-blue-100 text-blue-500 focus-visible:shadow-outline-orange",
  warning: "bg-yellow-100 text-yellow-500 focus-visible:shadow-outline-orange",
  error: "bg-red-100 text-red-500 focus-visible:shadow-outline-orange",
  grey: "bg-gray-200 text-gray-500 focus-visible:shadow-outline-gray",
};

const iconClassBySize: Record<NonNullable<BadgeProps["size"]>, string> = {
  small: "h-2 w-2",
  large: "h-3.5 w-3.5",
};
