import { Modal, ModalContent, ModalFooter } from "components";
import { MacButton } from "../button";

interface IConfirmProps {
  onOk?: () => void;
  onCancel?: () => void;
  title: string;
  content: string;
  butOkName?: string;
  butCancelName?: string;
  type?: "primary" | "error";
}
export const Confirm = ({
  onOk,
  onCancel,
  title,
  content,
  butOkName = "Got it",
  butCancelName = "Cancel",
  type = "primary",
}: IConfirmProps) => {
  return (
    <Modal isOpen>
      <ModalContent className="pb-0" header={title}>
        {content}
        <ModalFooter>
          {onCancel && (
            <MacButton variant="secondary" onClick={onCancel}>
              {butCancelName}
            </MacButton>
          )}
          {onOk && (
            <MacButton variant={type} onClick={onOk}>
              {butOkName}
            </MacButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
