import { ExpandMoreIcon } from "components/icons";
import { createArray, flattenedArray } from "helper/array";
import { classNames, isDefined } from "helper/utils";
import * as React from "react";

export type PaginationProps = {
  totalPage: number;
  currentPage: number;
  perPage: number;
  onChangePage: (newPage: number) => void;
  onChangePageSize?: (newPageSize: number) => void;
  className?: string;
};

const PaginationButton = ({
  disabled,
  className = "",
  ...props
}: React.ComponentPropsWithoutRef<"button"> & { className?: string; disabled?: boolean }) => (
  <button
    type="button"
    {...props}
    className={classNames(
      "relative inline-flex w-[25px] h-[23px] items-center justify-center py-2 border focus:z-10 focus:outline-none transition ease-in-out duration-150",
      !disabled && "focus-visible:border-blue-300 focus-visible:shadow-outline-blue active:bg-gray-100",
      className,
    )}
  />
);

type PageOption = {
  page: number | undefined;
  optional?: boolean;
};

const maxChunk = 3;

const getPageOptions = (current: number, max: number): Array<PageOption> => {
  if (max <= maxChunk + 2) {
    return createArray(max).map((_, i) => ({ page: i + 1 }));
  }

  if (current <= maxChunk - 1) {
    return flattenedArray([
      createArray(maxChunk).map<PageOption>((_, index) => ({
        page: index + 1,
        optional: index === maxChunk - 1,
      })),
      { page: undefined },
      { page: max },
    ]);
  }

  if (max - current <= maxChunk - 2) {
    return flattenedArray<PageOption>([
      { page: 1 },
      { page: undefined },
      createArray(maxChunk)
        .map((_, index) => ({ page: max - index, optional: index === 0 }))
        .reverse(),
    ]);
  }

  const half = Math.floor(maxChunk / 2);

  return flattenedArray<PageOption>([
    { page: 1 },
    { page: undefined },
    createArray(maxChunk).map((_, index) => ({
      page: current + index - half,
      optional: index === 0 || index === maxChunk - 1,
    })),
    { page: undefined },
    { page: max },
  ]);
};

export const Pagination = ({ totalPage = 0, currentPage = 0, onChangePage, className }: PaginationProps) => {
  if (totalPage <= 1) {
    return null;
  }

  const goNext = () => onChangePage(currentPage + 1);
  const disableNext = currentPage === totalPage;
  const goPrev = () => onChangePage(currentPage - 1);
  const disablePrev = currentPage <= 1;
  const listPage = getPageOptions(currentPage, totalPage);

  const content = (
    <>
      <div className="flex">
        <div className="flex flex-wrap justify-between items-center">
          <div className="inline-flex gap-x-2">
            <PaginationButton
              onClick={goPrev}
              className={classNames(
                "text-sm rounded-lg text-primary bg-secondary border-none",
                disablePrev && "pointer-events-none cursor-not-allowed opacity-30",
              )}
              disabled={disablePrev}
            >
              <span className="sr-only">Previous</span>
              <ExpandMoreIcon className="h-[25px] w-[23px] transform rotate-90" />
            </PaginationButton>
            {listPage.map(({ page, optional }, index) =>
              isDefined(page) ? (
                <PaginationButton
                  onClick={() => onChangePage(page)}
                  className={classNames(
                    "text-sm text-primary rounded-lg border-none",
                    optional ? "hidden lg:inline-flex" : "inline-flex",
                    page === currentPage ? classByStatus["selected"] : classByStatus["default"],
                  )}
                  key={page}
                >
                  {page}
                </PaginationButton>
              ) : (
                <span
                  className="-ml-px relative rounded-lg inline-flex items-center h-[25px] w-[23px] text-sm leading-5 font-bold text-primary"
                  key={`filler-${index}`}
                >
                  ...
                </span>
              ),
            )}
            <PaginationButton
              onClick={goNext}
              className={classNames(
                "text-sm -ml-px rounded-lg text-primary bg-secondary border-none",
                disableNext && "pointer-events-none cursor-not-allowed opacity-30",
              )}
              disabled={disableNext}
            >
              <span className="sr-only">Next</span>
              <ExpandMoreIcon className="h-[25px] w-[23px] transform -rotate-90" />
            </PaginationButton>
          </div>
        </div>
      </div>
    </>
  );

  return className ? <div className={className}>{content}</div> : content;
};

type status = "default" | "selected";

const classByStatus: Record<NonNullable<status>, string> = {
  default: "bg-secondary font-medium",
  selected: "bg-green-dark text-secondary font-bold",
};
