import { CheckedIcon, WarningIcon, CircleIcon } from "components/icons";
import * as React from "react";
import { notificationIcons } from "./icons";

export const showNotification = ({
  message,
  variant,
  duration = 6000,
}: {
  message: string;
  variant: NotificationStatus;
  duration?: number;
}) => {
  document.querySelector("#notify")!.classList.remove("hidden");
  document.querySelector("#notifyIcon")!.innerHTML = notificationIcons[variant];
  document.querySelector("#notifyTitle")!.innerHTML = message;
  if (variant !== "error") {
    setTimeout(() => {
      document.querySelector("#notify")!.classList.add("hidden");
    }, duration);
  }
};

export type NotificationProps = Omit<React.ComponentPropsWithoutRef<"div">, "title"> & {
  title?: string;
  description?: React.ReactNode;
  variant?: NotificationStatus;
};
export type NotificationStatus = "warning" | "error" | "success";

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(function Notification(
  { title = "", description, variant = "success", ...props },
  forwardedRef,
) {
  const demiss = () => {
    document.querySelector("#notify")!.classList.add("hidden");
  };

  return (
    <div id="notify" className="flex absolute top-1 right-1 z-50 hidden" ref={forwardedRef} {...props} role="alert">
      <div className="m-auto transition duration-75">
        <div className="bg-default rounded-lg border-gray-300 border p-3 shadow-lg">
          <div className="flex flex-row">
            <button id="notifyIcon" className="px-2" onClick={demiss}>
              {iconByVariant[variant]}
            </button>
            <div className="ml-2 mr-6">
              <span id="notifyTitle" className="font-semibold">
                {title}
              </span>
              {description && <span className="block text-gray-500">{description}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

type NotificationVariant = NonNullable<NotificationProps["variant"]>;

const iconByVariant: Record<NotificationVariant, React.ReactNode> = {
  success: <CheckedIcon className="h-6 w-6" />,
  warning: <WarningIcon className="h-6 w-6" />,
  error: <CircleIcon className="h-6 w-6" />,
};
