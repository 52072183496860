import clsx from "clsx";

type DataTableHtmlProps = React.ComponentPropsWithoutRef<"div"> & React.ComponentPropsWithoutRef<"td">;
interface ITableCellProps extends DataTableHtmlProps {
  children: React.ReactNode;
  className?: string;
}
export const TableCell = ({ children, className, ...props }: ITableCellProps) => {
  return (
    <td className={clsx("py-2", className)} {...props}>
      {children}
    </td>
  );
};
