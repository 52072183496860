import { Wrapper } from "components/layout";
import { PoliciesPanel } from "../modules/policies";

export function PoliciesPage() {
  return (
    <Wrapper title="Policies" className="overflow-y-scroll">
      <div className="flex flex-col gap-x-3 w-full px-8 h-full">
        <PoliciesPanel />
      </div>
    </Wrapper>
  );
}
