import React from "react";

export const PoliciesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.42 122.88" {...props}>
    <title>policies</title>
    <path
      className="cls-1"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#F3F3F4"
      d="M116.29,69.76c1.44,28.91-9.24,46-28.17,53.12C69.84,116.2,59,99.87,59.84,69.32A40.43,40.43,0,0,0,88,60.65c8.11,5.14,18.45,10,28.28,9.11ZM62.86,12.12l20.7,19.39H62.86V12.12ZM19.54,56.77A2.36,2.36,0,0,1,21.25,56H63.1a2.33,2.33,0,0,1,1.75.8,2.89,2.89,0,0,1,0,3.75v0a2.86,2.86,0,0,1-.5.42c-.8.15-1.6.27-2.4.38H21.29a2.38,2.38,0,0,1-1.76-.79,2.87,2.87,0,0,1,0-3.77Zm0,16.73a2.36,2.36,0,0,1,1.75-.81H50.42c.09,1.83.21,3.62.37,5.37H21.29a2.36,2.36,0,0,1-1.76-.79,2.87,2.87,0,0,1,0-3.77Zm0,16.72a2.38,2.38,0,0,1,1.75-.8H52.44q.57,2.77,1.29,5.37H21.29a2.33,2.33,0,0,1-1.76-.8,2.83,2.83,0,0,1-.7-1.89,2.87,2.87,0,0,1,.71-1.88Zm0-50.18a2.36,2.36,0,0,1,1.75-.81h24a2.32,2.32,0,0,1,1.74.79,2.83,2.83,0,0,1,.7,1.9,2.87,2.87,0,0,1-.7,1.88,2.38,2.38,0,0,1-1.74.8h-24a2.38,2.38,0,0,1-1.76-.79,2.86,2.86,0,0,1-.7-1.9A2.83,2.83,0,0,1,19.54,40Zm0-15.56a2.36,2.36,0,0,1,1.75-.81h13.2a2.34,2.34,0,0,1,1.73.78l0,0a2.87,2.87,0,0,1,0,3.77,2.36,2.36,0,0,1-1.74.8H21.29a2.36,2.36,0,0,1-1.76-.79,2.87,2.87,0,0,1,0-3.77Zm75.54,9.81A3.44,3.44,0,0,0,92.57,31L62.07,1.27A3.39,3.39,0,0,0,59.41,0H6.2A6.2,6.2,0,0,0,0,6.2V112.26a6.13,6.13,0,0,0,1.82,4.39,6.22,6.22,0,0,0,4.38,1.81H65.92a51.82,51.82,0,0,1-5.08-6.92H6.92V6.89h49V35a3.48,3.48,0,0,0,3.48,3.47H88.16V50.29q3.51,2.22,6.92,4.05V34.29ZM72.74,88.92l5.41-.07c1.44.24,4.4,2.88,5.44,4,.89-1.42,1.81-2.78,2.76-4.1q1.58-2.2,3.24-4.2c1.2-1.46,2.45-2.85,3.73-4.18,1.11-1.16,3.11-3.5,4.56-4.06a.27.27,0,0,1,.13,0h5.92a.35.35,0,0,1,.35.35.33.33,0,0,1-.11.25L103,78.18q-2.74,3-5.22,6.17c-1.67,2.11-3.26,4.26-4.76,6.43s-2.91,4.35-4.25,6.58-2.62,4.53-3.81,6.84l-.75,1.44a.36.36,0,0,1-.47.15.44.44,0,0,1-.16-.17l-.68-1.46a36.31,36.31,0,0,0-2.06-3.86,32.13,32.13,0,0,0-2.46-3.49,30.42,30.42,0,0,0-2.9-3.11A28.92,28.92,0,0,0,72.09,91a.35.35,0,0,1-.13-.4l.43-1.44a.36.36,0,0,1,.35-.25Z"
    />
  </svg>
);
