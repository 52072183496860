import { useContext, useEffect, useMemo, useState } from "react";
import { CityContext } from "../contexts/city-context-provider";
import { DocumentNode, useQuery } from "@apollo/client";
import { ParkingLotResponse } from "services/web/api-parking-lot.type";
import { showGlobalLoading, hideGlobalLoading } from "./use-global-loading";

export const useFetchParkingLot = ({ queryString }: { queryString: DocumentNode }) => {
  const { currentCity } = useContext(CityContext);
  const [isResetData, setIsResetData] = useState<boolean>(false);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id,
    };
  }, [currentCity]);

  const { data, loading } = useQuery<ParkingLotResponse>(queryString, {
    variables,
    skip: !currentCity?.id,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const parkingLots = useMemo(() => {
    return data?.parkingLots || [];
  }, [data]);

  return {
    setIsResetData,
    variables,
    currentCity,
    loading,
    parkingLots,
    isResetData,
  };
};
