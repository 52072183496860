import clsx from "clsx";
import { hasClassName } from "helper/check-class";

export function Label({ htmlFor, ...props }: React.ComponentProps<"label">) {
  return (
    <label
      {...props}
      className={clsx("block leading-5", !hasClassName(props.className, "mb-") && "mb-1", "text-sm", props.className)}
      htmlFor={htmlFor}
    />
  );
}
