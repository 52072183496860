import { useContext, useEffect, useMemo } from "react";
import { CityContext } from "../contexts/city-context-provider";
import { useQuery } from "@apollo/client";
import { QUERY_CURB_AREAS } from "../services/web/api-curb-area.const";
import { CurbAreasResponse } from "../services/web/api-curb-area.type";
import { showGlobalLoading, hideGlobalLoading } from "./use-global-loading";

export const useFetchCurbArea = () => {
  const { currentCity } = useContext(CityContext);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id,
    };
  }, [currentCity]);

  const { data, loading } = useQuery<CurbAreasResponse>(QUERY_CURB_AREAS, {
    variables,
    skip: !currentCity?.id,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const curbAreas = useMemo(() => {
    return data?.curbAreas || [];
  }, [data]);

  return {
    variables,
    currentCity,
    loading,
    curbAreas,
  };
};
