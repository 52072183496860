import { ReactNode } from "react";
import cx from "clsx";
import useToggle from "hooks/use-toggle";

interface TooltipProps {
  label: string;
  title?: ReactNode;
  children: ReactNode;
  offset?: number;
  pos?: "top" | "right" | "bottom" | "left";
  arrow?: boolean;
}

export const Tooltip = ({ label, children, title, pos = "bottom", arrow, offset = 10 }: TooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useToggle(false);

  const getPositionStyles = () => {
    switch (pos) {
      case "top":
        return {
          bottom: "100%",
          left: "50%",
          transform: `translate(-50%, -${offset}px)`,
        };
      case "right":
        return {
          top: "50%",
          left: "100%",
          transform: `translateY(-50%) translateX(${offset}px)`,
        };
      case "bottom":
        return {
          top: "100%",
          left: "50%",
          transform: `translate(-50%, ${offset}px)`,
        };
      case "left":
        return {
          top: "50%",
          right: "100%",
          transform: `translateY(-50%) translateX(-${offset}px)`,
        };
      default:
        return {
          bottom: "100%",
          left: "50%",
          transform: `translate(-50%, -${offset}px)`,
        };
    }
  };
  const getArrowClasses = () => {
    switch (pos) {
      case "top":
        return "before:left-1/2 before:-translate-x-1/2 before:bottom-full before:-rotate-90 after:left-1/2 after:-translate-x-1/2 after:bottom-full after:-rotate-90";
      case "bottom":
        return "before:left-1/2 before:-translate-x-1/2 before:top-full before:rotate-90 after:left-1/2 after:-translate-x-1/2 after:top-full after:rotate-90";
      case "left":
        return "before:top-1/2 before:-translate-y-1/2 before:left-full after:top-1/2 after:-translate-y-1/2 after:left-full";
      case "right":
        return "before:top-1/2 before:-translate-y-1/2 before:right-full before:rotate-180 after:top-1/2 after:-translate-y-1/2 after:right-full after:rotate-180";
      default:
        return "";
    }
  };
  const positionStyles = getPositionStyles();
  const arrowStyles = getArrowClasses();
  return (
    <div className="relative inline-block" onMouseEnter={setIsTooltipVisible} onMouseLeave={setIsTooltipVisible}>
      {children}
      {isTooltipVisible && (
        <div
          style={positionStyles}
          className="absolute min-w-max bg-gray-800 opacity-100 transition-opacity duration-200 text-white transparent text-sm rounded-md shadow-xs z-10"
        >
          <div
            className={cx(
              arrow
                ? `relative before:absolute before:border-8 before:border-transparent after:absolute  after:border-8 after:border-t-transparent after:border-r-transparent after:border-b-transparent after:border-l-slate-800 ${arrowStyles}`
                : undefined,
              title ? "p-4" : "px-3 py-2",
            )}
          >
            {title && <p className="text-lg mb-1">{title}</p>}
            <div className="text-sm">{label}</div>
          </div>
        </div>
      )}
    </div>
  );
};
