import { MacButton } from "components/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FieldContainer, FormInput, Textarea } from "components/form-control";
import { useForm } from "react-hook-form";
import { City } from "types";
import { Dispatch, SetStateAction } from "react";
import { ParkingLot } from "services/web/api-parking-lot.type";

interface ModifyParkingLotPromptProps {
  setOpenParkingLotModal: Dispatch<SetStateAction<boolean>>;
  parkingLotDetail?: ParkingLot;
  currentCity: City | null;
  resetEditState: VoidFunction;
  saveParkingLotProperties: (promptedProperties?: ParkingLotProperties) => void;
}

export type ParkingLotProperties = {
  name: string;
  note: string;
  address: string;
};
export const ModifyParkingLotPrompt = ({
  parkingLotDetail,
  saveParkingLotProperties,
  resetEditState,
}: ModifyParkingLotPromptProps) => {
  const { register, handleSubmit, reset } = useForm<ParkingLotProperties>({
    mode: "onChange",
  });

  const handleClose = () => {
    resetEditState();
    reset();
  };

  const submit = async (formValues: ParkingLotProperties) => {
    saveParkingLotProperties(formValues);
    handleClose();
  };

  return (
    <Modal isOpen>
      <ModalContent
        header={
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {parkingLotDetail?.id ? "Edit" : "Add new"} a parking lot
          </h3>
        }
      >
        <form onSubmit={handleSubmit(submit)}>
          <FieldContainer className="mt-8">
            <FormInput {...register("name", {})} defaultValue={parkingLotDetail?.name} label="Name" />
          </FieldContainer>
          <FieldContainer className="mt-8">
            <FormInput {...register("address", {})} defaultValue={parkingLotDetail?.address} label="Address" />
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Textarea {...register("note")} label="Note" defaultValue={parkingLotDetail?.note} />
          </FieldContainer>
          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton variant="primary" onClick={handleClose}>
              Close
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
