import { TimeSpanInput } from "types";
import React from "react";
import { FieldContainer } from "components/form-control";
import { IconButton, Label } from "components";
import { DeleteIcon } from "components/icons";
import { AddIcon } from "assets";
import { twMerge } from "tailwind-merge";
import { TimeSpanItem } from "./time-span-item";
import { defaultOptions, defaultTimeSpan } from "./time-span.const";
import { TimeSpanComponentProps } from "./time-span.type";
import { HintText } from "components/hint-text";
import { get } from "react-hook-form";

export const TimeSpanListing = ({
  label = "Timespan",
  suffixClassName,
  labelClassName,
  timeSpans = [],
  setTimeSpans,
  suffixIcon,
  options = defaultOptions,
  errors,
  name,
}: TimeSpanComponentProps) => {
  const onChangeValue = (value: TimeSpanInput, index: number) => {
    const newTimeSpans = [...timeSpans];
    newTimeSpans[index] = { ...value };
    setTimeSpans([...newTimeSpans]);
  };

  const addTimeSpans = () => {
    const newTimeSpans = [...timeSpans, defaultTimeSpan];
    setTimeSpans(newTimeSpans);
  };

  const removeOpenTime = (index: number) => {
    const newRegulations = [...timeSpans];
    newRegulations.splice(index, 1);
    setTimeSpans([...newRegulations]);
  };

  const error = get(errors, name);

  return (
    <FieldContainer helpText={error ? error?.message : undefined} status={error ? "error" : "primary"}>
      <Label className={labelClassName}>
        <HintText status={error ? "error" : "primary"}>{label}</HintText>
      </Label>
      {timeSpans?.map((timeSpan, timeSpanIndex) => (
        <div className="flex items-center" key={`time-span-${timeSpanIndex}`}>
          <TimeSpanItem
            label={label}
            onChangeValue={(input: TimeSpanInput) => onChangeValue(input, timeSpanIndex)}
            timeSpan={timeSpan}
            name={`timeSpans[${timeSpanIndex}]`}
            options={options}
          />
          <div className="mt-3">
            <IconButton className="w-6 h-6" onClick={() => removeOpenTime(timeSpanIndex)}>
              <DeleteIcon className="w-4 h-4 text-gray-400" />
            </IconButton>
          </div>
        </div>
      ))}
      <div className="flex items-center">
        <IconButton className={twMerge("w-6 h-6", suffixClassName)} onClick={addTimeSpans}>
          {suffixIcon ?? <AddIcon className="w-4 h-4 text-gray-400" />}
        </IconButton>
      </div>
    </FieldContainer>
  );
};
