import { RgbaColor } from "react-colorful";

import { ERole } from "store/auth/auth.type";
import { ColorRgba } from "types";

export const isPrimitive = (value: any): value is string | number | boolean => /^(b|st|n)/.test(typeof value);
export type Primitive = string | number | boolean;
export const isNil = (value: any): value is undefined | null => typeof value === "undefined" || value === null;

export const isEmpty = (value: any): boolean => {
  if (isNil(value)) {
    return true;
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return value.length === 0;
};

/**
 * @see https://malcolmkee.com/blog/typesafe-call-all
 * */
interface CallBack<Params extends any[]> {
  (...args: Params): void;
}
export const callAll =
  <Params extends any[]>(...fns: Array<CallBack<Params> | undefined>) =>
  (...args: Params) =>
    fns.forEach((fn) => typeof fn === "function" && fn(...args));

export type ToolTipStyleParams = {
  top: number;
  left: number;
  height: number;
  width: number;
  disabledPointerEvent?: boolean;
};

export const toolTipStyle = ({
  top,
  height,
  width,
  left,
  disabledPointerEvent = true,
}: ToolTipStyleParams): React.CSSProperties => {
  const newStyles = { zIndex: 1, pointerEvents: disabledPointerEvent && "none", left } as React.CSSProperties;
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  if (windowHeight - top > height) {
    newStyles.top = top - 10;
  } else if (top > height) {
    newStyles.bottom = windowHeight - top;
  } else if (windowHeight - height < 0) {
    newStyles.top = 85;
  } else {
    newStyles.top = windowHeight - height;
  }

  if (windowWidth - left > width + 80) {
    newStyles.left = left;
  } else {
    newStyles.left = left - width;
  }
  return newStyles;
};

export const getParameterByIndex = (index: number): string => {
  const pathname = window.location.pathname.replace("/", "");
  const arrPath = pathname.split("/");
  if (arrPath.length - 1 < index) {
    return "";
  }
  return "/" + arrPath[index];
};

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
}

export const isDefined = <T>(value: T | undefined): value is T => typeof value !== "undefined";

export function classNames(...classes: (false | null | undefined | string)[]): string {
  return classes.filter(Boolean).join(" ");
}

export const levenshteinDistance = (str1 = "", str2 = "") => {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator, // substitution
      );
    }
  }
  return track[str2.length][str1.length];
};

export function parseQueryString(queryString: string) {
  const query: { [key: string]: string | string[] | undefined } = {};
  const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

type Noop = () => void;
export const noop: Noop = () => {
  /**/
};

export function stringifyQueryString(input: { [key: string]: string | string[] | undefined }) {
  return Object.entries(input)
    .reduce((previousValue: string[], [k, v]) => {
      if (isEmpty(v)) {
        return previousValue;
      }
      return [...previousValue, `${k}=${v}`];
    }, [])
    .join("&");
}

export function checkPermission(routeRoles: ERole[], userRoles: ERole[]) {
  return routeRoles.some((item) => userRoles.includes(item));
}

export const formatRGBAColorToString = (color: RgbaColor) =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a ?? 1})`;

export const formatPolicyColorToString = (color: ColorRgba) =>
  `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] !== undefined ? color[3] / 255 : 1})`;

export const formatArrayToRgbaColor = (colors?: ColorRgba) => {
  if (!colors) return { r: 0, g: 0, b: 0, a: 1 };
  return {
    r: colors[0],
    g: colors[1],
    b: colors[2],
    a: colors[3] !== undefined ? Number((colors[3] / 255).toFixed(2)) : 1,
  };
};

export const convertHexToRgba = (hexCode: string) => {
  let hex = hexCode.replace("#", "");
  let opacity = 1;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (hex.length === 8) {
    opacity = parseInt(hex.substring(6, 8), 16);
  }

  opacity = Number((opacity / 255).toFixed(2));

  return {
    r,
    g,
    b,
    a: opacity,
  };
};

export const convertRgbaToHex = (rgba: RgbaColor) => {
  let r = (+rgba.r).toString(16);
  let g = (+rgba.g).toString(16);
  let b = (+rgba.b).toString(16);
  let a = (+(rgba.a * 255)).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;
  if (a.length === 1) a = "0" + a;

  return "#" + r + g + b + a;
};

export const getQueryParams = <
  Params extends { [key: string]: string | string[] | undefined } = {
    [key: string]: string | undefined;
  },
>() => {
  const { search } = window.location;
  const params = parseQueryString(search);
  return params as Params;
};
