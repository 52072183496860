import { DisabledCurbZoneMap } from "modules/disabled-curb-zone";
import { Wrapper } from "components/layout";

export function DisabledParkingPage() {
  return (
    <Wrapper title="Disabled Parking" className="mask-background">
      <DisabledCurbZoneMap />
    </Wrapper>
  );
}
