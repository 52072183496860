import { DescriptionItem, DescriptionList } from "components";
import { distanceUnit } from "constants/mapbox.const";
import { moneyFormat, numberFormat } from "helper/format-data";
import * as React from "react";
import { isEmpty, toolTipStyle } from "helper/utils";
import { CurbZone } from "types/curb-zone.type";
import { useMemo } from "react";

interface curbZoneTooltipProps extends React.ComponentPropsWithoutRef<"div"> {
  left: number;
  top: number;
  curbZone: CurbZone;
}

export const CurbZoneTooltip = (props: curbZoneTooltipProps) => {
  const { left, top, curbZone } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const firstPolicy = curbZone.curbPolicies ? curbZone?.curbPolicies[0] : null;

  let price = 0;
  if (!isEmpty(firstPolicy?.rules) && !isEmpty(firstPolicy?.rules?.[0]?.rate)) {
    price = firstPolicy?.rules?.[0]?.rate?.[0].rate || 0;
  }

  const styles = toolTipStyle({ top, left, height: 284, width: 288 });

  const policyNames = useMemo(() => {
    if (curbZone.curbPolicies) {
      const displayedName = curbZone.curbPolicies.reduce((names: string[], item) => {
        return [...names, item.name];
      }, []);
      return displayedName.join(", ");
    }
    return "";
  }, [curbZone.curbPolicies]);

  return (
    <div style={styles} className="absolute w-72" ref={ref}>
      <div className="relative transition-opacity h-auto p-3 mt-2 mb-3 rounded-md shadow-lg z-10 bg-primary">
        <DescriptionList>
          <DescriptionItem label="Id" value={curbZone.curbZoneId} />
          <DescriptionItem label="Type de place" value={policyNames} />
          <DescriptionItem label="Forme" value={curbZone.parkingAngle} />
          <DescriptionItem label="Tarif" value={moneyFormat(price)} />
          <DescriptionItem label="Nom voie" value={curbZone?.streetName} />
          <DescriptionItem label="Longueur" value={`${numberFormat(curbZone.length)} ${distanceUnit}`} />
          <DescriptionItem label="Largeur" value={`${curbZone?.width} ${distanceUnit}`} />
          <DescriptionItem
            label="Surface calculée"
            value={`${numberFormat(curbZone?.calculatedArea)} ${distanceUnit}2`}
          />
          <DescriptionItem label="Places calculées" value={curbZone?.calculatedSpaces} />
          {curbZone?.externalId && <DescriptionItem label="Identifiant externe" value={curbZone?.externalId} />}
          {curbZone?.note && (
            <DescriptionItem
              label="Commentaire"
              value={<span dangerouslySetInnerHTML={{ __html: curbZone?.note.replace(/\n/g, "<br />") }} />}
            />
          )}
        </DescriptionList>
      </div>
    </div>
  );
};
