import * as React from "react";
import { callAll, isNil, noop } from "helper/utils";
import { IconButton } from "../icon-button";
import { SearchIcon } from "assets";
import { TextInput, TextInputProps } from "./text-input";

export interface SearchInputProps extends Omit<TextInputProps, "value"> {
  value?: string;
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  { onChangeValue = noop, className, value: parentValue, onKeyDown, ...props },
  forwardedRef,
) {
  const [value, setValue] = React.useState(parentValue || "");
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!isNil(parentValue)) {
      setValue(parentValue);
    }
  }, [parentValue]);

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    onChangeValue(newValue);
  };

  return (
    <>
      <div className="flex">
        <div className="flex-1 relative">
          <TextInput
            value={value}
            onChangeValue={setValue}
            className={className}
            {...props}
            ref={forwardedRef}
            onKeyDown={callAll((ev) => {
              if (ev.key === "Enter" && value) {
                ev.stopPropagation();
                handleValueChange(value);
              } else if (ev.key === "Escape" && value) {
                ev.stopPropagation();
                handleValueChange("");
              }
            }, onKeyDown)}
          />
          {value && (
            <div className="absolute right-0 inset-y-0 pr-1 pl-1 flex items-center">
              <IconButton
                onClick={() => {
                  handleValueChange("");
                  if (searchInputRef.current) {
                    searchInputRef.current.focus();
                  }
                }}
              >
                <span>x</span>
              </IconButton>
            </div>
          )}
        </div>
        <IconButton onClick={() => onChangeValue(value)} className="pr-3 pl-3">
          <SearchIcon className="w-5 h-5" />
        </IconButton>
      </div>
    </>
  );
});
