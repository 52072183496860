import { useCallback, useState } from "react";

// Toggles between true or false
function useToggle(initialValue = false): [boolean, () => void] {
  const [toggle, setToggle] = useState(initialValue);

  return [toggle, useCallback(() => setToggle((status) => !status), [])];
}

export default useToggle;
