import { countrySymbol, MAPBOX_ACCESS_TOKEN, MAPBOX_API } from "constants/mapbox.const";
import { getData } from "helper/api";
import { Point } from "geojson";

export const getAddress = async (point: Point): Promise<AddressType> => {
  const placeName = encodeURIComponent(`${point.coordinates[0]},${point.coordinates[1]}.json`);
  const mapboxRequestUrl = `${MAPBOX_API}${placeName}?access_token=${MAPBOX_ACCESS_TOKEN}`;

  try {
    const mapboxResponse = (await getData(mapboxRequestUrl)) as any;
    const address: AddressType = {};
    for (const mapboxFeature of mapboxResponse.features) {
      if (mapboxFeature.id.includes("address.")) {
        address.name = mapboxFeature.text;
        address.number = mapboxFeature.address;
      } else if (mapboxFeature.id.includes("postcode.")) {
        address.postcode = mapboxFeature.text;
      } else if (mapboxFeature.id.includes("place.")) {
        address.city = mapboxFeature.text;
      }
    }

    return address;
  } catch {
    return {};
  }
};

export const getPlacesByText = async ({ keyWord, coordinates }: placeFilter): Promise<addressPosition[]> => {
  const placeName = encodeURIComponent(`${keyWord}.json`);
  const proximity = coordinates ? `proximity=${coordinates?.longitude},${coordinates?.latitude}&` : "";
  const mapboxRequestUrl = `${MAPBOX_API}${placeName}?language=${countrySymbol}&${proximity}access_token=${MAPBOX_ACCESS_TOKEN}`;
  try {
    const mapboxResponse = (await getData(mapboxRequestUrl)) as any;
    const address: Array<addressPosition> = [];
    for (const mapboxFeature of mapboxResponse.features) {
      if (
        mapboxFeature.id.includes("address.") ||
        mapboxFeature.id.includes("place.") ||
        mapboxFeature.id.includes("poi.")
      ) {
        address.push({
          name: mapboxFeature.place_name,
          latitude: mapboxFeature.center[0],
          longitude: mapboxFeature.center[1],
          wikidata: mapboxFeature.properties.wikidata,
          text: mapboxFeature.text,
        });
      }
    }
    return address;
  } catch {
    return [];
  }
};

export type addressPosition = {
  name: string;
  latitude: number;
  longitude: number;
  wikidata?: string;
  text?: string;
};

export type AddressType = {
  name?: string;
  number?: string;
  postcode?: string;
  city?: string;
};
export type placeFilter = {
  coordinates?: Omit<addressPosition, "name">;
  keyWord: string;
};
