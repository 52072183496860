import { MultiSelectSuggestions } from "components/form-control";
import { useEffect, useState } from "react";
import { arrUserClasses } from "../policies.const";

export type UserClassesProps = {
  userClasses: string[];
  setUserClasses: React.Dispatch<React.SetStateAction<string[]>>;
};

export const UserClasses = ({ userClasses, setUserClasses }: UserClassesProps) => {
  const [searchString, setSearchString] = useState("");
  const [options, setOptions] = useState<string[] | undefined>([]);

  useEffect(() => {
    if (searchString) {
      setOptions(undefined);

      const requestId = window.setTimeout(() => {
        const newOptions = arrUserClasses.filter(
          (p) => userClasses.indexOf(p) === -1 && p.toLowerCase().indexOf(searchString) > -1,
        );
        setOptions(newOptions);
      }, 500);

      return () => window.clearTimeout(requestId);
    } else {
      setOptions(arrUserClasses);
    }
  }, [searchString, userClasses]);

  const checkDuplicated = (value: string) => {
    if (!userClasses.length) {
      return true;
    }
    return userClasses?.indexOf(value) === -1;
  };

  return (
    <MultiSelectSuggestions
      suggestions={options}
      values={userClasses}
      onChangeValues={setUserClasses}
      onInputValueChange={setSearchString}
      className="w-36"
      validateBeforeAdd={checkDuplicated}
      placeholder="User classes"
    />
  );
};
