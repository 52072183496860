import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { QUERY_USER_REPORT_BY_CITY, UPDATE_USER_REPORT } from "services/web/api-user-report.const";
import { PaginatedUserReportArgs, PaginatedUserReports, UserReportResponse } from "types/user-report.type";

export const useQueryListUserReport = (input: PaginatedUserReportArgs) => {
  const {
    data = { getUserReportsByCityId: { results: [], totalPages: 0 } },
    loading,
    refetch,
  } = useQuery<UserReportResponse>(QUERY_USER_REPORT_BY_CITY, {
    variables: { ...input, sortOrder: input.sortOrder?.toUpperCase() },
    skip: !input.cityId,
  });
  return {
    data: data.getUserReportsByCityId,
    isLoadingUserReports: loading,
    refetch,
  };
};

export const useUpdateUserReport = (input: PaginatedUserReportArgs) => {
  const client = useApolloClient();

  const userReportQueryParams = {
    query: QUERY_USER_REPORT_BY_CITY,
    variables: {
      ...input,
      sortOrder: input.sortOrder?.toUpperCase(),
    },
  };
  const getCachedUserReport = (): PaginatedUserReports => {
    const cachedCity = client.cache.readQuery(userReportQueryParams) as UserReportResponse;
    return cachedCity.getUserReportsByCityId;
  };

  const [updateUserReport] = useMutation(UPDATE_USER_REPORT, {
    onCompleted: (response) => {
      const userReportResponse = getCachedUserReport();
      const newUserReportListing = userReportResponse.results.map((item) => {
        if (item.id === response.updateUserReport.id) {
          return { ...item, isDone: response.updateUserReport.isDone };
        }
        return item;
      });
      client.cache.writeQuery({
        ...userReportQueryParams,
        data: {
          getUserReportsByCityId: {
            results: newUserReportListing,
            totalPages: userReportResponse.totalPages,
          },
        },
      });
    },
  });

  return {
    updateUserReport,
  };
};
