import { Confirm, IconButton } from "components";
import {
  DataTable,
  TableBody,
  TableRow,
  TableCell,
  TableHeader,
  HeaderItem,
  CSSAlignement,
} from "components/data-table";
import { Pagination } from "components/data-table/Pagination";
import { DeleteIcon, EditIcon } from "components/icons";
import { useState } from "react";
import { City } from "types";
import { AddCity } from "./add-city/Panel";
import { PaginatedCitiesProps, usePaginatedCitiesActions } from "./hooks/paginated-cities.hook";
import { TableSkeleton } from "components/data-table";
import { isEmpty } from "helper/utils";

const HeaderCells: HeaderItem[] = [
  { title: "Ville", align: CSSAlignement.left },
  { title: "Code postal", align: CSSAlignement.left },
  { title: "Fournisseur de données", align: CSSAlignement.left },
  { title: "Action", align: CSSAlignement.center },
];

export const CityTable = ({ currentPage, setCurrentPage, cityName }: PaginatedCitiesProps) => {
  const [selectedCity, setSelectedCity] = useState<City>();
  const { cities, loading, deleteCity, refetch } = usePaginatedCitiesActions({
    setCurrentPage,
    currentPage,
    cityName,
  });
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const handleRemove = async () => {
    if (selectedCity) {
      await deleteCity({ variables: { cityId: selectedCity.id } });
      setOpenConfirm(false);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedCity(undefined);
  };

  const onChangePage = (page: number) => {
    refetch({ pageIndex: page });
    setCurrentPage({ ...currentPage, pageIndex: page });
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-2 h-full overflow-y-auto">
        <div className="w-full bottom-2 pb-8 h-full">
          <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
            <TableHeader className="text-black bg-white text-sm border-b-2 border-secondary" columnName={HeaderCells} />
            <TableBody className="min-h-fit  max-h-full">
              {loading ? (
                <TableSkeleton />
              ) : (
                <>
                  {isEmpty(cities?.paginatedCities.results) ? (
                    <GenNoRecord colSpan={HeaderCells.length} message={loading ? "Loading..." : "No results found."} />
                  ) : (
                    cities?.paginatedCities.results.map((item: City, idx: number) => (
                      <TableRow
                        className="bg-white align-middle cursor-pointer p-4 hover:none"
                        key={`city-list-${idx}`}
                      >
                        <TableCell
                          key={`name-cell-${idx}`}
                          className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          key={`zipcode-cell-${idx}`}
                          className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                        >
                          {item.zipCode}
                        </TableCell>
                        <TableCell
                          key={`upciti-cell-${idx}`}
                          className="px-6 py-2 whitespace-nowrap text-primary text-sm font-medium"
                        >
                          {item?.details?.dataProvider?.name}
                        </TableCell>
                        <TableCell
                          key={`action-cell-${idx}`}
                          className="px-6 py-2 whitespace-nowrap font-medium  flex flex-row gap-x-2 justify-center"
                        >
                          <IconButton
                            onClick={() => {
                              setSelectedCity(item);
                              setOpenEdit(true);
                            }}
                            key={`edit-button-cell-${idx}`}
                            className="border-transparent text-secondary rounded-md w-10 h-10 hover:bg-secondary bg-primary-active p-0 m-0"
                          >
                            <EditIcon key={`edit-icon-cell-${idx}`} className="h-5 w-5" stroke="black" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpenConfirm(true);
                              setSelectedCity(item);
                            }}
                            key={`delete-button-cell-${idx}`}
                            className="border-transparent  rounded-md w-10 h-10 hover:bg-secondary  p-0 m-0"
                          >
                            <DeleteIcon key={`delete-icon-cell-${idx}`} className="h-5 w-5 " />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </DataTable>
        </div>
        <div className="mb-2 flex flex-col items-end">
          {cities && (
            <Pagination
              onChangePage={onChangePage}
              currentPage={currentPage.pageIndex}
              perPage={currentPage.pageSize}
              totalPage={cities.paginatedCities.totalPages}
            />
          )}
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete city"
          content="Are you sure that you want to delete this city?"
          onCancel={handleCloseConfirm}
          onOk={handleRemove}
          butOkName="Delete"
          type="error"
        />
      )}
      {openEdit && selectedCity && (
        <AddCity
          onDismiss={setOpenEdit}
          open={openEdit}
          city={selectedCity}
          {...{ cityName, setCurrentPage, currentPage }}
        />
      )}
    </>
  );
};

interface GenNoRecordProps {
  colSpan?: number;
  message?: string;
}

export const GenNoRecord = ({ colSpan = 3, message = "No results found" }: GenNoRecordProps) => {
  return (
    <tr className="bg-default">
      <td className="px-6 py-4 whitespace-nowrap bg-white	 text-primary" colSpan={colSpan}>
        <div className="flex items-center justify-center">{message}</div>
      </td>
    </tr>
  );
};
