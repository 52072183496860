import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "helper/utils";
import { fetchCityInfo } from "services/common/osm";
import { getPlacesByText } from "services/web/mapbox.service";
import { CitiesFilter, PlaceFilter } from "types";

export const usePlacesQuery = (filter: PlaceFilter) => {
  return useQuery({
    queryKey: ["searchPlaces", filter],
    queryFn: () => {
      return getPlacesByText(filter);
    },
    enabled: !isEmpty(filter.keyWord),
  });
};

export const useCityQuery = (filter: CitiesFilter) => {
  return useQuery({
    queryKey: ["searchPlaces", filter],
    queryFn: () => {
      return fetchCityInfo(filter);
    },
    enabled: !isEmpty(filter.name),
  });
};
