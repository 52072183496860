import * as React from "react";
import TimePicker from "react-time-picker";
import { ClockIcon } from "assets";
import { FieldStatus } from "./field.type";
import { twMerge } from "tailwind-merge";

export interface TimeInputProps extends React.ComponentPropsWithoutRef<"input"> {
  status?: FieldStatus;
  times: string;
  onChangeValue: (value: string) => void;
  wrapperClass?: string;
  name?: string;
  timeInputClassName?: string;
  disabled?: boolean;
}

export const TimeInput = React.forwardRef<HTMLInputElement, TimeInputProps>(function TimeInput(
  { onChangeValue, times, wrapperClass, timeInputClassName, disabled, name, ...props },
  forwardedRef,
) {
  return (
    <div className={wrapperClass} {...props}>
      <div
        className={twMerge(
          "relative border-b border-gray-300 focus-visible:outline-none focus:outline-none flex",
          timeInputClassName,
        )}
      >
        <div
          className="absolute inset-y-0 left-0 px-1.5 my-1 ml-1 rounded-full flex items-center pointer-events-none"
          aria-hidden
        >
          <ClockIcon className="w-4 h-4 text-gray-400" />
        </div>
        <div className="pl-8 text-base">
          <TimePicker
            onChange={(value) => onChangeValue(String(value))}
            value={times}
            format="HH:mm"
            disableClock={true}
            name={name}
            disabled={disabled}
            className="flex items-center"
            {...forwardedRef}
          />
        </div>
      </div>
    </div>
  );
});
