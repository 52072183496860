import clsx from "clsx";
import { hasClassName } from "helper/check-class";
type DataTableHtmlProps = React.ComponentPropsWithoutRef<"div"> & React.ComponentPropsWithoutRef<"tr">;
interface ITableCellProps extends DataTableHtmlProps {
  children: React.ReactNode;
  className?: string;
}
export const TableRow = ({ children, className, ...props }: ITableCellProps) => {
  return (
    <tr className={clsx(!hasClassName(className, "hover:") && "hover:bg-primary-active", className)} {...props}>
      {children}
    </tr>
  );
};
