import { Confirm, MacButton } from "components";
import {
  MapStyle,
  CurbZoneDetail,
  CurbZoneMultiDetail,
  SearchPlace,
  SelectMapType,
  SwitchModeView,
  UserReportListing,
} from "./components";
import MapToolBox from "./map-tool-box";
import { ModeViewType, curbAreaMode, parkingLotMode } from "constants/map.const";
import { CurbZoneMultiplePrompt } from "./modes/add-curb-zone/curb-zone-multiple-prompt";
import { CurbZonePropertiesPrompt } from "./modes/add-curb-zone/curb-zone-properties-prompt";
import { MapViewProps } from "./curb-zone.type";
import { useCurbZone } from "./hooks/use-curb-zone";
import { SearchFilterCurbZone } from "../../components/search-and-filter";
import { ModifyCurbAreaPrompt } from "./components/curb-area/modify-curb-area-prompt";
import { CurbAreaDetail } from "./components/curb-area/curb-area-detail";
import { useMemo } from "react";
import { useCurbArea } from "./hooks/use-curb-area";
import { useParkingLot } from "./hooks/use-parking-lot";
import { ModifyParkingLotPrompt } from "./components/parking-lot/modify-parking-lot-prompt";
import { ParkingLotDetail } from "./components/parking-lot/parking-lot-detail";
import { useUserReport } from "./hooks/use-user-report";
import Split from "react-split";
import { AppModeType } from "types";

export const CurbZoneMap = ({ appMode, setAppMode }: MapViewProps) => {
  const isCurbZone = useMemo(() => {
    return !curbAreaMode.includes(appMode) && !parkingLotMode.includes(appMode);
  }, [appMode]);
  const {
    markerPosition,
    viewport,
    modeView,
    setModeView,
    selectAddress,
    mapType,
    setMapType,
    handleDragEnd,
    handleSelectCurbZone,
    handleZooming,
    hoverInfo,
    mapRef,
    onViewStateChange,
    openCurbZoneModal,
    addPropertiesAndSave,
    currentLineParkingList,
    curbZoneDetail,
    removeSelectedFeatures,
    currentCity,
    showCurbZoneDetail,
    openParkingModal,
    handleDeleteCurbZone,
    showCurbZoneMultiDetail,
    cancelEditMultiple,
    setOpenCurbZoneMultipleModal,
    openCurbZoneMultipleModal,
    handleEditMultipleCurbZone,
    preZooming,
    handleBulkDeleteCurbZones,
    isSpaceBarPressed,
    filter,
    changeFilter,
    curbZoneLayers,
    handleCopyCurbZone,
    isCopyMode,
  } = useCurbZone({
    appMode,
    isCurbZone,
  });

  const {
    handleSelectUserReport,
    selectedUserReport,
    userReportList,
    isLoadingUserReports,
    wrapMapRef,
    userReportLayer,
    setUserReportParams,
    userReportParams,
    isOpenUserReport,
    setIsOpenUserReport,
  } = useUserReport();

  const {
    curbAreaEditionData,
    setCurbAreaEditionData,
    curbAreaDetail,
    setCurbAreaDetail,
    showCurbAreaDetail,
    openCurbAreaModal,
    setOpenCurbAreaModal,
    curbAreaLayers,
    cancelCurbAreaChange,
    handleSelectCurbArea,
    saveCurbAreaProperties,
  } = useCurbArea({ appMode });

  const {
    parkingLotLayers,
    openParkingLotModal,
    parkingLotDetail,
    showParkingLotDetail,
    setParkingLotDetail,
    setOpenParkingLotModal,
    showWarning,
    resetEditParkingLotState,
    handleSelectParkingLot,
    saveParkingLotProperties,
    checkCurbZoneExistBeforeUpdate,
  } = useParkingLot({
    appMode,
  });

  const mergedLayers = [...curbAreaLayers, ...parkingLotLayers, ...curbZoneLayers, userReportLayer];

  const handleConfirmOk = () => resetEditParkingLotState();

  const handleSelectLayer = (info: any) => {
    if (curbAreaMode.includes(appMode)) {
      handleSelectCurbArea(info);
    }
    if (parkingLotMode.includes(appMode)) {
      handleSelectParkingLot(info);
    }
    handleSelectCurbZone(info);
  };

  return (
    <div className="h-full top-0 w-full absolute">
      <Split
        className="split z-50 h-full"
        direction="vertical"
        minSize={0}
        sizes={[isOpenUserReport ? 50 : 100, isOpenUserReport ? 50 : 0]}
      >
        <div className="relative overflow-hidden">
          <div className="relative h-full" role="none" ref={wrapMapRef}>
            <div className="absolute top-0 left-6 z-10 flex">
              <SearchPlace onSelect={selectAddress} coordinates={viewport} />
              <SelectMapType mapType={mapType} onSelectMapType={(value) => setMapType(value)} className="ml-2" />
              {currentCity && <SearchFilterCurbZone filter={filter} onChangeFilter={changeFilter} />}
            </div>
            <div>
              <MapStyle
                markerPosition={markerPosition}
                mapType={mapType}
                modeView={modeView}
                layers={mergedLayers}
                handleDragEnd={handleDragEnd}
                handleSelectLayer={handleSelectLayer}
                handleZooming={(event) => handleZooming(event, preZooming.current)}
                hoverInfo={hoverInfo}
                mapRef={mapRef}
                onViewStateChange={onViewStateChange}
                viewport={viewport}
                appMode={appMode}
                isSpaceBarPressed={isSpaceBarPressed}
              />
            </div>

            {mapType === "Map box" && (
              <SwitchModeView modeView={modeView} selectModeView={(mode: ModeViewType) => setModeView(mode)} />
            )}
            {appMode === AppModeType.MultiEdit && (
              <div className="absolute p-4 bottom-2 left-24 ml-6 bg-default rounded-md shadow-lg">
                <p>Press SPACE bar to move the map.</p>
                <p>Press SHIFT button to select one curb-zone.</p>
                <p>Press ALT button to select multiple curb-zones.</p>
              </div>
            )}

            {appMode === AppModeType.Edit && (
              <div className="absolute p-4 bottom-2 left-24 ml-6 bg-default rounded-md shadow-lg">
                <p>Press SHIFT button to move selected curb-zone.</p>
              </div>
            )}
            <div className="absolute top-36 left-8 z-5">
              <MapToolBox appMode={appMode} setAppMode={setAppMode} />
            </div>

            <div className="absolute bottom-10 right-8 z-5">
              <MacButton onClick={() => setIsOpenUserReport()}>
                {isOpenUserReport ? "Hide" : "Show"} User Reports
              </MacButton>
            </div>

            {openCurbZoneModal && (
              <CurbZonePropertiesPrompt
                registerProperties={addPropertiesAndSave}
                prefill={(currentLineParkingList[0] || curbZoneDetail[0]) ?? undefined}
                id={currentLineParkingList[0]?.id as string}
                cancel={removeSelectedFeatures}
              />
            )}
            {currentCity
              ? showCurbZoneDetail && (
                  <CurbZoneDetail
                    cityId={currentCity.id}
                    curbZone={curbZoneDetail[0]?.properties}
                    resetState={removeSelectedFeatures}
                    openCurbZoneModal={openParkingModal}
                    handleRemoveCurbZone={handleDeleteCurbZone}
                    copyCurbZone={handleCopyCurbZone}
                    title={isCopyMode.current ? "Duplicated a Curb Zone" : null}
                  />
                )
              : null}
            {currentCity
              ? showCurbZoneMultiDetail && (
                  <CurbZoneMultiDetail
                    curbZones={curbZoneDetail}
                    resetState={cancelEditMultiple}
                    openCurbZoneMultiModal={setOpenCurbZoneMultipleModal}
                    handleRemoveMultiCurbZone={handleBulkDeleteCurbZones}
                  />
                )
              : null}
            {openCurbZoneMultipleModal && (
              <CurbZoneMultiplePrompt
                numberOfParking={curbZoneDetail?.length}
                cancel={cancelEditMultiple}
                handleEditMultipleCurbZone={handleEditMultipleCurbZone}
              />
            )}
            {openCurbAreaModal && (
              <ModifyCurbAreaPrompt
                appMode={appMode}
                saveCurbAreaProperties={saveCurbAreaProperties}
                setOpenCurbAreaModal={setOpenCurbAreaModal}
                curbAreaEditionData={curbAreaEditionData}
                setCurbAreaEditionData={setCurbAreaEditionData}
                curbAreaDetail={curbAreaDetail}
                cancelCurbAreaChange={cancelCurbAreaChange}
              />
            )}
            {curbAreaDetail && showCurbAreaDetail && (
              <CurbAreaDetail
                curbAreaDetail={curbAreaDetail}
                setCurbAreaDetail={setCurbAreaDetail}
                setOpenCurbAreaModal={setOpenCurbAreaModal}
              />
            )}
            {openParkingLotModal && (
              <ModifyParkingLotPrompt
                saveParkingLotProperties={saveParkingLotProperties}
                setOpenParkingLotModal={setOpenParkingLotModal}
                currentCity={currentCity}
                resetEditState={resetEditParkingLotState}
                parkingLotDetail={parkingLotDetail}
              />
            )}
            {parkingLotDetail && showParkingLotDetail && (
              <ParkingLotDetail
                parkingLotDetail={parkingLotDetail}
                setParkingLotDetail={setParkingLotDetail}
                checkCurbZoneExistBeforeUpdate={checkCurbZoneExistBeforeUpdate}
              />
            )}
            {showWarning && <Confirm title="Warning" content="Have not any curb zone" onOk={handleConfirmOk} />}
          </div>
        </div>
        <div className="overflow-y-auto z-40">
          <UserReportListing
            isLoading={isLoadingUserReports}
            userReportList={userReportList}
            onSelectUserReport={handleSelectUserReport}
            selectedUserReport={selectedUserReport}
            userReportParams={userReportParams}
            setUserReportParams={setUserReportParams}
          />
        </div>
      </Split>
    </div>
  );
};
