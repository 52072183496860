import { useCombobox, useMultipleSelection } from "downshift";
import { Badge, BadgeProps } from "../badge";
import { SpinIcon } from "../icons";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { callAll, isDefined, isPrimitive } from "helper/utils";
import { Label } from "../label";
import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { useEffect, useState, ComponentProps, useRef, useMemo } from "react";
import { Fragment } from "react";

export type OptionData<T> = {
  value: T;
  label: string;
};

export type DropdownMultiSelectProps<T = string> = {
  values: T[];
  onChangeValues: (values: T[], label: string) => void;
  badgeColor?: BadgeProps["variant"];
  options?: OptionData<T>[];
  onInputValueChange?: (inputValue: string) => void;
  label?: string;
  size?: BadgeProps["size"];
} & Omit<ComponentProps<"input">, "onChange" | "onKeyDown" | "value" | "size">;

export const DropdownMultiSelect = <T extends string>({
  label,
  size = "large",
  values,
  onChangeValues,
  badgeColor = "grey",
  className,
  options,
  onInputValueChange,
  onFocus,
  ...inputProps
}: DropdownMultiSelectProps<T>) => {
  const [textInputValue, setTextInputValue] = useState("");
  const { name } = inputProps;
  const [listOptions, setListOptions] = useState<OptionData<T>[]>([]);

  const onInputChange = (value: string) => {
    setTextInputValue(value);
    if (onInputValueChange) {
      onInputValueChange(value.trim());
    }
  };

  const optionData = useMemo(
    () =>
      (options as any[]).map(
        (opt) =>
          (isPrimitive(opt)
            ? {
                value: opt,
                label: String(opt),
              }
            : opt) as OptionData<T>,
      ),
    [options],
  );

  const selectedItems = useMemo(
    () => (optionData ? values.map((v) => optionData.find((opt) => opt.value === v)).filter(isDefined) : []),
    [values, optionData],
  );

  useEffect(() => {
    setListOptions([]);
    const requestId = window.setTimeout(() => {
      const newOptions = optionData.filter(
        (p) => selectedItems.indexOf(p) === -1 && p.label.toLowerCase().indexOf(textInputValue.toLowerCase()) > -1,
      );
      setListOptions(newOptions);
    }, 500);

    return () => window.clearTimeout(requestId);
  }, [textInputValue, selectedItems, optionData]);

  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onSelectedItemsChange: ({ selectedItems }) => {
      if (selectedItems) {
        onChangeValues(
          selectedItems.map((i) => i.value),
          selectedItems.map((i) => i.label).join(", "),
        );
      }
    },
  });

  const { isOpen, getInputProps, getItemProps, getMenuProps, highlightedIndex, openMenu } = useCombobox({
    inputValue: textInputValue,
    selectedItem: null,
    itemToString: (i) => (i ? i.label : ""),
    items: listOptions ? listOptions : [],
    stateReducer: (state, { type, changes }) => {
      if (type === useCombobox.stateChangeTypes.InputKeyDownEnter || type === useCombobox.stateChangeTypes.ItemClick) {
        return {
          ...changes,
          isOpen: state.isOpen,
        };
      }

      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          onInputChange(inputValue || "");

          break;

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            onInputChange("");
            addSelectedItem(selectedItem);
          }
          break;

        default:
          break;
      }
    },
  });

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Label htmlFor={name || ""} className="form-label-focus">
        {label}
      </Label>
      <div className="w-full max-w-sm">
        <Popover className="relative">
          {() => (
            <>
              <PopoverButton
                className={`
                ${isOpen ? "" : "text-opacity-90"}
                group inline-flex`}
              >
                <div
                  className="flex space-y-1 items-center flex-wrap form-input cursor-text overflow-x-auto"
                  ref={divRef as any}
                >
                  {selectedItems.map((selectedItem, index) => (
                    <Badge
                      key={`selectedItem-${index}`}
                      {...getSelectedItemProps({
                        selectedItem,
                        index,
                      })}
                      size={size}
                      variant={badgeColor}
                      onRemove={(e) => {
                        e.stopPropagation();
                        removeSelectedItem(selectedItem);
                      }}
                      className="mr-1"
                    >
                      {selectedItem.label}
                    </Badge>
                  ))}

                  <input
                    {...getInputProps(
                      getDropdownProps({
                        onFocus: callAll(onFocus, () => {
                          if (!isOpen) {
                            openMenu();
                          }
                        }),
                        ...inputProps,
                      }),
                    )}
                    className={clsx(
                      "min-w-24 focus:outline-none bg-transparent border-0 p-0",
                      !hasClassName(className, "w-") && "flex-1",
                      className,
                    )}
                    tabIndex={-1}
                  />
                </div>
              </PopoverButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute z-10 mt-2">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div
                      {...getMenuProps(
                        {
                          className: "text-sm rounded-md bg-white shadow-xs max-h-52 overflow-auto",
                        },
                        { suppressRefError: true },
                      )}
                    >
                      {isOpen &&
                        (listOptions && options ? (
                          listOptions.length === 0 ? (
                            <div className="px-4 py-2">No results found.</div>
                          ) : (
                            listOptions.map((option, idx) => {
                              return (
                                <div
                                  key={`${option}-${idx}`}
                                  {...getItemProps({
                                    item: option,
                                    index: idx,
                                    className: clsx(
                                      "px-4 py-2",
                                      idx === highlightedIndex && "text-neutral-800 bg-neutral-100 cursor-pointer",
                                    ),
                                  })}
                                >
                                  {option.label}
                                </div>
                              );
                            })
                          )
                        ) : (
                          <div className="text-center p-3">
                            <SpinIcon className="w-5 h-5 animate-spin inline-block" />
                          </div>
                        ))}
                    </div>
                  </div>
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};
