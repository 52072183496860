import { useState } from "react";
import { useForm } from "react-hook-form";
import { MacButton } from "components";
import { Modal, ModalContent, ModalFooter } from "components";
import { CurbZoneMultipleInput, CurbZoneMultiplePromptProps } from "./curb-zone.type";
import { PolicyView } from "./policy-view";
import { CurbZone } from "types/curb-zone.type";
import { DropdownSelect, FieldContainer, FormInput } from "components/form-control";
import { ParkingAngle } from "./curb-zone.const";

export const CurbZoneMultiplePrompt = ({
  numberOfParking,
  cancel,
  handleEditMultipleCurbZone,
}: CurbZoneMultiplePromptProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CurbZone>();
  const [curbPolicies, setCurbPolicies] = useState<string[]>([]);

  const saveParking = (input: CurbZoneMultipleInput) => {
    handleEditMultipleCurbZone({ ...input, curbPolicyIds: curbPolicies });
  };

  return (
    <Modal isOpen className="my-0 h-full max-w-lg flex items-center content-center">
      <ModalContent
        className="pb-0 rounded-2xl"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Curb Zone</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Currently selecting {numberOfParking} Curb Zones</p>
          </>
        }
      >
        <form onSubmit={handleSubmit(saveParking)}>
          <PolicyView curbPolicies={curbPolicies} setCurbPolicies={setCurbPolicies} />
          <FieldContainer className="mt-8" helpText={errors.parkingAngle ? "This is required" : undefined}>
            <DropdownSelect
              label="Forme"
              className="border-primary border-solid"
              options={Object.values(ParkingAngle)}
              {...register("parkingAngle", { required: true })}
              defaultValue={ParkingAngle.parallel}
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.width ? "This is required" : undefined}
            status={errors?.width ? "error" : "primary"}
          >
            <FormInput
              label="Largeur"
              {...register("width", { valueAsNumber: true, required: true })}
              status={errors.width ? "error" : "primary"}
            />
          </FieldContainer>

          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton onClick={cancel} variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
