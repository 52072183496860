import { gql } from "@apollo/client";

export const QUERY_CURB_ZONES = gql`
  query curbZones($filterInput: SearchCurbZoneInput!) {
    curbZones(filterInput: $filterInput) {
      calculatedArea
      calculatedSpaces
      cityId
      curbPolicies {
        id
        curbPolicyId
        name
        rules {
          rate {
            rate
            rateUnit
          }
        }
        color {
          dashed
          fillColor
          lineColor
        }
      }
      editLine
      externalId
      geoPoint2d
      geometry
      id
      curbZoneId
      length
      name
      note
      parkingAngle
      publishedDate
      startDate
      streetName
      streetNumberStart
      streetNumberEnd
      width
    }
  }
`;

export const QUERY_CREATE_CURB_ZONE = gql`
  mutation addCurbZone($curbZoneInput: CreateCurbZoneInput!) {
    createCurbZone(curbZoneInput: $curbZoneInput) {
      calculatedArea
      calculatedSpaces
      cityId
      curbPolicies {
        id
        curbPolicyId
        name
        rules {
          rate {
            rate
            rateUnit
          }
        }
        color {
          dashed
          fillColor
          lineColor
        }
      }
      editLine
      externalId
      geoPoint2d
      geometry
      id
      curbZoneId
      length
      name
      note
      parkingAngle
      publishedDate
      startDate
      streetName
      streetNumberEnd
      streetNumberStart
      width
    }
  }
`;

export const QUERY_UPDATE_CURB_ZONE = gql`
  mutation updateCurbZone($curbZoneInput: UpdateCurbZoneInput!) {
    updateCurbZone(curbZoneInput: $curbZoneInput) {
      calculatedArea
      calculatedSpaces
      cityId
      curbPolicies {
        id
        curbPolicyId
        name
        rules {
          rate {
            rate
            rateUnit
          }
        }
        color {
          dashed
          fillColor
          lineColor
        }
      }
      curbPolicyIds
      editLine
      externalId
      geoPoint2d
      geometry
      id
      curbZoneId
      length
      name
      note
      parkingAngle
      publishedDate
      startDate
      streetName
      streetNumberEnd
      streetNumberStart
      width
    }
  }
`;

export const QUERY_BULK_UPDATE_CURB_ZONE = gql`
  mutation updateCurbZone($curbZoneInputs: [UpdateCurbZoneInput!]!) {
    bulkUpdateCurbZones(curbZoneInputs: $curbZoneInputs) {
      calculatedArea
      calculatedSpaces
      cityId
      curbPolicies {
        id
        curbPolicyId
        name
        rules {
          rate {
            rate
            rateUnit
          }
        }
        color {
          dashed
          fillColor
          lineColor
        }
      }
      curbPolicyIds
      editLine
      externalId
      geoPoint2d
      geometry
      id
      curbZoneId
      length
      name
      note
      parkingAngle
      publishedDate
      startDate
      streetName
      streetNumberEnd
      streetNumberStart
      width
    }
  }
`;

export const QUERY_REMOVE_CURB_ZONE = gql`
  mutation deleteCurbZone($curbZoneId: String!) {
    deleteCurbZone(curbZoneId: $curbZoneId) {
      id
    }
  }
`;

export const QUERY_REMOVE_CURB_ZONES = gql`
  mutation bulkDeleteCurbZones($curbZoneIds: [String!]!) {
    bulkDeleteCurbZones(curbZoneIds: $curbZoneIds) {
      id
    }
  }
`;
