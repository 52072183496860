import { useState } from "react";
import { Sidebar } from "./sidebar/sidebar";
import { Header } from "./header";
import { twMerge } from "tailwind-merge";
import { LoadingComponentType } from "./global-loading";

type WrapperProps = {
  children: React.ReactNode;
  title: string;
  className?: string;
  showCityList?: boolean;
};

export const Wrapper = ({ children, title, className, showCityList = true }: WrapperProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex height-100vh overflow-hidden bg-menu">
      <LoadingComponentType type="progress" />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div
        className={twMerge(
          "flex flex-col flex-1 overflow-hidden bg-default lg:rounded-l-large md:rounded-none relative",
          className,
        )}
      >
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} title={title} showCityList={showCityList} />
        <main className="h-screen">{children}</main>
      </div>
    </div>
  );
};
