import { useCallback } from "react";
import { MapRef } from "react-map-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

export const useAddControlMapbox = () => {
  const mapRefCallback = useCallback((ref: MapRef | null) => {
    if (ref !== null) {
      const map = ref;
      const language = new MapboxLanguage();
      if (map) {
        map.getMap().addControl(language);
      }
    }
  }, []);
  return { mapRefCallback };
};
