import { DisabledCurbZoneType, TrafficRestrictionType, TRestrictionType } from "types";

export const restrictionType = Object.entries(DisabledCurbZoneType).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const trafficRestrictionType = Object.entries(TrafficRestrictionType).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const isTrafficRestriction = (type?: TRestrictionType) => {
  if (!type) {
    return false;
  }
  return Object.keys(TrafficRestrictionType).includes(type);
};
