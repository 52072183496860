import { useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import { getDateRangeByType, DateRangeType, Ranges } from "helper/date-time";
import { fr as languageFr } from "date-fns/locale";
import { isSameDay } from "date-fns";
import { MacButton } from "../button";

export type DateRangePickerProps = {
  startDate?: Date;
  endDate?: Date;
  onSelectDate: (date: Range, type: DateRangeType) => void;
  minDate?: Date;
  maxDate?: Date;
  selectedType: DateRangeType;
  onClose?: () => void;
};

export const DateRangePickerSelector = ({
  startDate = new Date(),
  endDate = new Date(),
  minDate,
  maxDate,
  onSelectDate,
  selectedType = "custom",
  onClose,
}: DateRangePickerProps) => {
  const [selectEnd, setSelectEnd] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<Range[]>([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const handleSetRanges = (type: DateRangeType) => {
    const result = getDateRangeByType(type);
    setSelectedDateRange([{ ...selectedDateRange[0], startDate: result.from, endDate: result.to }]);
    if (onSelectDate) {
      onSelectDate({ startDate: result.from, endDate: result.to }, type);
    }
  };

  const selectDate = (range: RangeKeyDict) => {
    setSelectedDateRange([range.selection]);
    if (selectEnd) {
      const selected = { ...range.selection };
      if (
        range.selection?.startDate &&
        range.selection?.endDate &&
        isSameDay(range.selection.startDate, range.selection.endDate)
      ) {
        const fullYear = range.selection.endDate.getFullYear();
        const month = range.selection.endDate.getMonth();
        const date = range.selection.endDate.getDate();
        selected.endDate = new Date(fullYear, month, date, 23, 59, 59);
        selected.startDate = new Date(fullYear, month, date, 0, 0, 0);
      }
      onSelectDate(selected, "custom");
    } else {
      setSelectEnd(true);
    }
  };

  const onClear = () => {
    onSelectDate({ startDate: undefined, endDate: undefined }, "custom");
  };

  return (
    <div className="flex">
      <div className="rdrDefinedRangesWrapper show rounded-bl-2xl bg-primary flex-col items-stretch justify-center">
        <div className="rdrStaticRanges">
          {Ranges.map((item, key) => (
            <button
              type="button"
              className={`rdrStaticRange ${item.name === selectedType && "active"}`}
              key={`date-range-${key}`}
              onClick={() => handleSetRanges(item.name)}
            >
              <span className="rdrStaticRangeLabel">{item.display}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="relative flex">
        <DateRangePicker
          months={2}
          onChange={(range: any) => selectDate(range)}
          showPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={selectedDateRange}
          direction="horizontal"
          className="bg-primary rounded-br-2xl text-secondary"
          locale={languageFr}
          minDate={minDate}
          maxDate={maxDate}
          weekStartsOn={1}
        />
        <div className="w-full flex justify-between absolute bottom-0 p-2">
          <MacButton variant="primary" className="hover:bg-primary-active" onClick={onClose}>
            Close
          </MacButton>
          <MacButton variant="primary" className="hover:bg-primary-active" onClick={onClear}>
            Clear
          </MacButton>
        </div>
      </div>
    </div>
  );
};
