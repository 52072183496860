import { Wrapper } from "components/layout";
import { CitiesPanel } from "modules/cities";

export const CitiesPage = () => {
  return (
    <Wrapper title="Cities" showCityList={false} className="overflow-y-auto">
      <div className="flex flex-col gap-x-3 w-full px-8 h-full">
        <CitiesPanel />
      </div>
    </Wrapper>
  );
};
