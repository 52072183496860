import * as React from "react";
import { FieldStatus } from "./field.type";
import { CalendarIcon } from "assets";
import { DaysOfWeek } from "types/city.const";
import { TimesOfDayInput } from "types";
import { useState } from "react";
import { hasClassName } from "helper/check-class";
import clsx from "clsx";
import { callAll } from "helper/utils";
import { DayOfWeekModal } from "./day-of-week-modal";
import { InputButtonProps, InputButton } from "./input-button";

export const defaultTimeOfDay = { from: "00:00", to: "00:00" };

export interface DayOfWeekProps extends InputButtonProps {
  status?: FieldStatus;
  daysOfWeek?: DaysOfWeek[];
  timesOfDay?: TimesOfDayInput[];
  onChangeValue: (dayOfWeek: DaysOfWeek[], timeOfDay: TimesOfDayInput[]) => void;
  className?: string;
  name?: string;
  placeholder?: string;
}

export const DayOfWeek = React.forwardRef<HTMLInputElement, DayOfWeekProps>(function DayOfWeek(
  {
    onChangeValue,
    daysOfWeek = [DaysOfWeek.Mo],
    timesOfDay = [defaultTimeOfDay],
    className,
    placeholder,
    ...buttonProps
  },
  ref,
) {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const displayValue = React.useMemo(() => formatTimeSpan(daysOfWeek, timesOfDay), [daysOfWeek, timesOfDay]);
  return (
    <>
      <InputButton
        className={clsx(
          "border-solid border-primary focus-visible:outline-none focus:outline-none form-input flex items-center min-w-0 bg-transparent mt-3",
          !hasClassName(className, "w-") && "w-full",
          className,
        )}
        {...buttonProps}
        onClick={callAll(buttonProps.onClick, () => setShowPopup(true))}
      >
        <CalendarIcon className="w-4 h-4 mr-3 text-gray-400 " />
        <span className="h-5 flex-1 truncate text-left">
          {displayValue || <span className="text-lightgrey">{placeholder}</span>}
        </span>
        <input type="hidden" name={buttonProps.name} ref={ref} />
      </InputButton>
      {showPopup && (
        <DayOfWeekModal
          isOpen={showPopup}
          initialDayOfWeek={daysOfWeek}
          initialTimeOfDay={timesOfDay}
          onApply={callAll(onChangeValue, () => setShowPopup(false))}
          onDismiss={() => setShowPopup(false)}
        />
      )}
    </>
  );
});

const formatTimeSpan = (daysOfWeek: DaysOfWeek[], timesOfDay: TimesOfDayInput[]) => {
  const flatTimes = timesOfDay.map((item) => item.from + " - " + item.to);
  return (
    <>
      {daysOfWeek.join(", ")} - {flatTimes.join(", ")}
    </>
  );
};
