import { useContext, useRef } from "react";

import { CityContext } from "contexts/city-context-provider";
import { Filter } from "components/search-and-filter/SearchFilter.type";
import { ViewState } from "react-map-gl";
import { interactionStateType } from "../types";
import { isNil } from "../helper/utils";
import { useQueryParamByViewPort } from "./use-set-query-params";

type UseCityParams = {
  viewport: ViewState;
  setBounding: React.Dispatch<React.SetStateAction<number[][] | undefined>>;
  preZooming: React.MutableRefObject<any>;
  filter?: Filter;
};

export const useInteractMap = ({ setBounding, viewport, preZooming, filter }: UseCityParams) => {
  const { currentCity, setViewport } = useContext(CityContext);
  const isZooming = useRef(false);
  const setQueryParamByViewport = useQueryParamByViewPort();

  const getCurbZones = (viewState: any) => {
    if (!currentCity || !viewState) return;
    setQueryParamByViewport({
      viewState,
      viewport,
      setBounding,
      cityID: currentCity.id,
      policies: filter?.selectedPolicies,
    });
  };

  const handleZooming = (input: interactionStateType, preZooming: any) => {
    if (isNil(input.isZooming)) {
      return;
    }
    if (input.isZooming) {
      isZooming.current = true;
    } else if (isZooming.current) {
      isZooming.current = false;
    } else {
      return;
    }
    if (preZooming && !isZooming.current) {
      isZooming.current = false;
      setViewport((prevState) => ({
        ...prevState,
        bearing: preZooming.bearing,
        longitude: preZooming.longitude,
        latitude: preZooming.latitude,
        zoom: preZooming.zoom,
      }));
    }
  };

  const onViewStateChange = ({ viewState }: any) => {
    preZooming.current = viewState;
  };

  return {
    handleZooming,
    onViewStateChange,
    getCurbZones,
  };
};
