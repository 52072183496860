export interface IRoute {
  path: string;
  display: string;
}

export interface IRoutes {
  root: IRoute;
  disabledParts: IRoute;
  offstreetParking: IRoute;
  cities: IRoute;
  notfound: IRoute;
  error: IRoute;
  policies: IRoute;
  curbArea: IRoute;
}

export const routes: IRoutes = {
  root: {
    path: "/",
    display: "Home Page",
  },
  disabledParts: {
    path: "/disabled-parts",
    display: "Disabled Parts",
  },
  offstreetParking: {
    path: "/offstreet-parking",
    display: "Offstreet Parking",
  },
  cities: {
    path: "/cities",
    display: "Cities",
  },
  notfound: {
    path: "/404",
    display: "Not found",
  },
  error: {
    path: "/error",
    display: "Error page",
  },
  policies: {
    path: "/policies",
    display: "Policies",
  },
  curbArea: {
    path: "/curb-area",
    display: "Curb Area",
  },
};
