import { LoadingComponent, PrivatePage } from "components";
import { ReactElement, useCallback, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import { routes } from "./constants";
import { checkPermission } from "../helper/utils";
import { findObject } from "../helper/array";
import { menuItems } from "../components/sidebar/menu-items";
import { showNotification } from "components/notification";
import { CityContextProvider } from "../contexts/city-context-provider";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0Config } from "../configs/auth-config";
import { ERole } from "../store/auth/auth.type";

export default function MiddleWare(): ReactElement {
  const { user, error, isLoading } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const validateRole = useCallback(() => {
    if (!user) {
      return;
    }
    const userRoles = user[`${auth0Config.namespace}/roles`] as ERole[];
    const fRoute = findObject(menuItems, "path", pathname);
    if (fRoute === -1) {
      navigate(routes.notfound.path);
      return;
    }
    const hasPermission = checkPermission(menuItems[fRoute].roles, userRoles);
    if (!hasPermission) {
      showNotification({
        variant: "error",
        message: `You are logged in as a ${userRoles.join(", ") || "None"}. Please contact our administrator`,
      });
      navigate(routes.error.path);
    }
  }, [user, navigate, pathname]);

  useEffect(() => {
    if (error) {
      navigate(routes.error.path);
      return;
    }
    validateRole();
  }, [error, validateRole, navigate]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <CityContextProvider>
      <PrivatePage component={Outlet} />
    </CityContextProvider>
  );
}
