import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { BoldSearchIcon, ChevronDownIcon } from "components/icons";
import { Fragment, useState } from "react";
import { Checkbox } from "components/form-control/check-box";
import {
  arrDecreeAnalysedValues,
  arrDecreeStatusValues,
  DecreeAnalyzed,
  DecreeFilter,
  DecreeStatus,
} from "./decree.const";
import { FormInput } from "components/form-control";
import { useDebouncedCallback } from "hooks/use-debounce-callback";

export type variant = "primary" | "secondary" | "outline";

export type FilterDecreeProps = {
  filter: DecreeFilter;
  onChange: (input: DecreeFilter) => void;
};

export const FilterDecree = ({ onChange, filter }: FilterDecreeProps) => {
  const [value, setValue] = useState<string>("");

  const changeFilter = (val: DecreeStatus | DecreeAnalyzed, type: keyof Omit<DecreeFilter, "searchString">) => {
    const statusExists = filter[type].findIndex((item) => item === val);
    const newStatus = [...filter[type]];
    if (statusExists > -1) {
      newStatus.splice(statusExists, 1);
      onChange({ ...filter, [type]: newStatus });
    } else {
      onChange({ ...filter, [type]: [...newStatus, val] });
    }
  };

  const handleSearch = useDebouncedCallback((value: string) => {
    onChange({ ...filter, searchString: value });
  });

  const handleSearchChange = (value: string) => {
    setValue(value);
    handleSearch(value);
  };

  return (
    <div className="px-4 flex">
      <div className="px-3 flex items-end">
        <FormInput
          onChangeValue={handleSearchChange}
          value={value}
          placeholder="Recherche de décrets"
          iconStart={<BoldSearchIcon />}
          className="w-44"
        />
      </div>
      <div className="flex">
        <Popover className="relative">
          {({ open }) => (
            <>
              <PopoverButton
                className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md px-3 py-2 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="text-medium text-primary">Filter decree</span>
                <ChevronDownIcon
                  className={`${open && "rotate-180 transform"}
                  ml-2 transition duration-150 ease-in-out`}
                  aria-hidden="true"
                />
              </PopoverButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute left-0 z-10 -translate-x-1/2 transform px-4 sm:px-0">
                  <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 flex w-64">
                    <div className="relative bg-white py-sm pl-5 pr-6 pb-2 overflow-y-auto">
                      {arrDecreeStatusValues.map((item, itemIdx) => (
                        <Checkbox
                          checked={filter.status.includes(item)}
                          onChangeValue={() => changeFilter(item, "status")}
                          label={item}
                          value={item}
                          key={`multi-select-${itemIdx}`}
                        />
                      ))}
                    </div>
                    <div className="relative bg-white py-sm pl-5 pr-6 pb-2 overflow-y-auto">
                      {arrDecreeAnalysedValues.map((item, itemIdx) => (
                        <Checkbox
                          checked={filter.analyzed.includes(item)}
                          onChangeValue={() => changeFilter(item, "analyzed")}
                          label={item}
                          value={item}
                          key={`multi-select-${itemIdx}`}
                        />
                      ))}
                    </div>
                  </div>
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};
