import { Feature, LineString, Polygon } from "geojson";
import { Policy } from "./policy.type";
import { CardinalDirection, ParkingAngle } from "./curb-zone.const";

export type LocationReference = {
  source: string;
  refId: string;
  start: number;
  end: number;
  side?: string;
};

export type PreviousPolicy = {
  curbPolicyIds: string[];
  startDate: Date;
  endDate: Date;
};

export type Update = {
  userId: string;
  at: Date;
};

export type CurbZone = Feature<Polygon> & {
  id: string | undefined;
  curbZoneId: string | undefined;
  geometry: Polygon;
  curbPolicyIds: string[];
  curbPolicies: Policy[];
  prevPolicies?: PreviousPolicy[];
  publishedDate: Date;
  lastUpdatedDate: Date;
  prevCurbZoneIds?: string[];
  startDate: Date;
  endDate?: Date;
  locationReferences?: LocationReference[];
  name?: string;
  userZoneId?: string;
  streetName?: string;
  crossStreetStartName?: string;
  crossStreetEndName?: string;
  length?: number;
  availableSpaceLengths?: number[];
  availabilityTime?: Date;
  width: number;
  parkingAngle?: ParkingAngle;
  numSpaces?: number;
  streetSide?: CardinalDirection;
  median?: boolean;
  entireRoadway?: boolean;
  curbAreaIds?: string[];
  curbSpaceIds?: string[];
  updates?: Update[];
  calculatedArea: number;
  calculatedSpaces: number;
  geoPoint2d: number[];
  cityId: string;
  externalId?: string;
  editLine: Feature<LineString>;
  note?: string;
  streetNumberStart?: string;
  streetNumberEnd?: string;
};

export const EditableCurbZoneLayerName = "draw-curb-zone-layer";
