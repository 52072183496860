import { useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "hooks/lib-ui";
import { IPrivateParking, PrivateParkingsResponse } from "types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  QUERY_ADD_PRIVATE_PARKING,
  QUERY_PRIVATE_PARKING,
  QUERY_REMOVE_PRIVATE_PARKING,
  QUERY_UPDATE_PRIVATE_PARKING,
} from "services/web/api-cities.const";
import { CityContext } from "contexts/city-context-provider";
import { showGlobalLoading, hideGlobalLoading } from "hooks/use-global-loading";

interface PrivateParkingCache {
  privateParkingsByBounding: IPrivateParking[] | [];
}

export const usePrivateParking = () => {
  const { currentCity } = useContext(CityContext);
  const [bounding, setBounding] = useState<number[][]>();
  const client = useApolloClient();
  const nearBy = useDebounce(bounding, 500);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id || "",
      boundingbox: nearBy,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearBy]);
  const { data, loading } = useQuery<PrivateParkingsResponse>(QUERY_PRIVATE_PARKING, {
    variables,
    skip: !currentCity?.id || !nearBy,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const getCachedParking = (): IPrivateParking[] => {
    const cachedParking = client.cache.readQuery({ query: QUERY_PRIVATE_PARKING, variables }) as PrivateParkingCache;
    return cachedParking.privateParkingsByBounding;
  };

  const [createPrivateParking] = useMutation(QUERY_ADD_PRIVATE_PARKING, {
    onCompleted: (response) => {
      const preState = getCachedParking();
      const newState = [...preState, { ...response.addPrivateParkingToCity, disabledParts: [] }];
      client.cache.writeQuery({
        query: QUERY_PRIVATE_PARKING,
        variables,
        data: { privateParkingsByBounding: newState },
      });
    },
  });

  const [updatePrivateParking] = useMutation(QUERY_UPDATE_PRIVATE_PARKING, {
    onCompleted: (response) => {
      const preState = getCachedParking();
      const newState = preState.map((item) => {
        if (item.id === response.updatePrivateParking?.id) {
          return response.updatePrivateParking;
        }
        return item;
      });
      client.cache.writeQuery({
        query: QUERY_PRIVATE_PARKING,
        variables,
        data: { privateParkingsByBounding: newState },
      });
    },
  });

  const [removePrivateParking] = useMutation(QUERY_REMOVE_PRIVATE_PARKING, {
    onCompleted: (response) => {
      const preState = getCachedParking();
      const newState = preState.filter((item) => item.id !== response.removePrivateParking?.id);
      client.cache.writeQuery({
        query: QUERY_PRIVATE_PARKING,
        variables,
        data: { privateParkingsByBounding: newState },
      });
    },
  });

  const privateParking = useMemo(() => {
    return data?.privateParkingsByBounding || [];
  }, [data]);

  return {
    nearBy,
    setBounding,
    privateParking,
    loading,
    createPrivateParking,
    updatePrivateParking,
    removePrivateParking,
  };
};
