import React, { forwardRef } from "react";
import clsx from "clsx";

export interface ImageLazyLoadingProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  VisibleByDefault?: React.ElementType;
}

export const ImageLazyLoading = forwardRef<HTMLImageElement, ImageLazyLoadingProps>(function ImageLazyLoading(
  { src, VisibleByDefault, className, alt },
  ref,
) {
  const imgEl = React.useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  const onImageLoaded = () => setLoaded(true);

  React.useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", onImageLoaded);
      return () => imgElCurrent.removeEventListener("load", onImageLoaded);
    }
  }, []);

  return (
    <div ref={ref}>
      {!loaded && (VisibleByDefault ? <VisibleByDefault /> : <ImgSkeleton className={className} />)}
      <img
        ref={imgEl}
        src={src}
        style={loaded ? { display: "inline-block" } : { display: "none" }}
        className={className}
        alt={alt || "macaron image"}
      />
    </div>
  );
});

export type ImgSkeletonProps = {
  className?: string | undefined;
};

export const ImgSkeleton = ({ className }: ImgSkeletonProps) => (
  <div>
    <div className="animate-pulse flex">
      <div className={clsx("bg-slate-200", className)} />
    </div>
  </div>
);
