import { CSSAlignement, HeaderItem, SortDirection } from "components/data-table";
import { DecreeCategory } from "enums/decree.enum";
import { Decree } from "types/decree.type";

export type SortDecreeOption = {
  orderBy: keyof Decree;
  direction: SortDirection;
};

export const defaultDecreeCells: HeaderItem<keyof Decree>[] = [
  { title: "Nom", align: CSSAlignement.left, orderBy: "name", sortable: true, sortDirection: SortDirection.ASC },
  {
    title: "Date de réception",
    align: CSSAlignement.left,
    orderBy: "createdAt",
    sortable: true,
    sortDirection: SortDirection.ASC,
  },
  {
    title: "Analise",
    align: CSSAlignement.center,
    orderBy: "analyzed",
    sortable: true,
    sortDirection: SortDirection.ASC,
    className: "w-20",
  },
  { title: "Traité", align: CSSAlignement.center, className: "w-20" },
  { title: "Voir", align: CSSAlignement.center, className: "w-20" },
  { title: "Edit", align: CSSAlignement.center, className: "w-20" },
];

export const defaultDecreeSort: SortDecreeOption = {
  orderBy: "createdAt",
  direction: SortDirection.DESC,
};

export enum DecreeStatus {
  DONE = "Traité",
  NOT_DONE = "Non traité",
}

export enum DecreeAnalyzed {
  ANALYSED = "Analise",
  NOT_ANALYSED = "Non analise",
}

export const arrDecreeStatusValues = Object.values(DecreeStatus) as DecreeStatus[];
export const arrDecreeAnalysedValues = Object.values(DecreeAnalyzed) as DecreeAnalyzed[];

export type DecreeFilter = {
  status: DecreeStatus[];
  analyzed: DecreeAnalyzed[];
  searchString: string;
};

export const defaultDecreeFilter: DecreeFilter = {
  status: [],
  analyzed: [],
  searchString: "",
};

export const decreeCategoryType = Object.entries(DecreeCategory).map(([key, value]) => ({
  value: key,
  label: value,
}));
