import { SVGProps } from "react";

export const ChevronDownIcon = ({ color = "#292A2F", ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.99989 5.8C5.59156 5.8 5.18322 5.6425 4.87406 5.33333L1.07072 1.53C0.901556 1.36083 0.901556 1.08083 1.07072 0.911665C1.23989 0.742498 1.51989 0.742498 1.68906 0.911665L5.49239 4.715C5.77239 4.995 6.22739 4.995 6.50739 4.715L10.3107 0.911665C10.4799 0.742498 10.7599 0.742498 10.9291 0.911665C11.0982 1.08083 11.0982 1.36083 10.9291 1.53L7.12572 5.33333C6.81656 5.6425 6.40822 5.8 5.99989 5.8Z"
      fill={color}
    />
  </svg>
);
