import {
  DataTable,
  HeaderItem,
  SortDirection,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderProps,
  TableRow,
  TableSkeleton,
} from "components/data-table";
import { defaultUserReportCells } from "./user-report.const";
import { ComponentType, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Pagination } from "components/data-table/Pagination";
import { CityContext } from "contexts/city-context-provider";
import { Checkbox } from "components/form-control";
import { isEmpty } from "helper/utils";
import { ReportFilesModal } from "./file-viewer-modal";
import { FileIcon } from "components/icons";
import {
  PaginatedUserReportArgs,
  PaginatedUserReports,
  UpdateUserReportInput,
  UserReport,
} from "types/user-report.type";
import { useUpdateUserReport } from "../hooks/use-query-user-report";

interface UserReportListingProps {
  isLoading: boolean;
  userReportList: PaginatedUserReports;
  selectedUserReport: UserReport | null;
  onSelectUserReport: (input: UserReport) => void;
  setUserReportParams: Dispatch<SetStateAction<PaginatedUserReportArgs>>;
  userReportParams: PaginatedUserReportArgs;
  close?: () => void;
}
const TableHeaderWithUserReport: ComponentType<TableHeaderProps<keyof UserReport>> = TableHeader;
export const UserReportListing = ({
  isLoading,
  userReportList,
  onSelectUserReport,
  selectedUserReport,
  setUserReportParams,
  userReportParams,
}: UserReportListingProps) => {
  const [headerCells, setHeaderCells] = useState<HeaderItem<keyof UserReport>[]>(defaultUserReportCells);
  const [files, setFiles] = useState<string[]>([]);
  const { currentCity } = useContext(CityContext);

  const { updateUserReport } = useUpdateUserReport({ ...userReportParams });

  const userReportListSorted: UserReport[] = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return userReportList.results;
  }, [isLoading, userReportList]);

  if (!currentCity) {
    return <EmptyData />;
  }

  const handleSort = (orderBy: keyof UserReport, direction: SortDirection) => {
    const newCells = headerCells.map((cell) => {
      if (cell.orderBy === orderBy) {
        return {
          ...cell,
          sortDirection: direction,
        };
      }
      return cell;
    });
    setUserReportParams((prevState) => ({ ...prevState, sortField: orderBy, sortOrder: direction }));
    setHeaderCells(newCells);
  };

  const onChangePage = (page: number) => {
    setUserReportParams((prevState) => ({ ...prevState, pageIndex: page }));
  };

  const changeStatus = async (input: UserReport) => {
    const newUserReport: UpdateUserReportInput = {
      id: input.id,
      isDone: !input.isDone,
    };
    const variables = {
      reportInput: newUserReport,
    };
    await updateUserReport({ variables });
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-2 bg-white p-7">
        <div className="flex justify-between mb-11 items-center">
          <span className="font-bold text-lg">User Report</span>
        </div>
        <div className="w-full h-full">
          <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
            <TableHeaderWithUserReport
              className="text-black bg-white text-sm border-b-2 border-secondary"
              columnName={headerCells}
              onSort={handleSort}
            />
            <TableBody className="min-h-fit  max-h-full">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <>
                  {isEmpty(userReportListSorted) ? (
                    <GenNoRecord
                      colSpan={headerCells.length}
                      message={isLoading ? "Loading..." : "No results found."}
                    />
                  ) : (
                    userReportListSorted.map((item, idx) => {
                      return (
                        <TableRow
                          className={`hover:none ${selectedUserReport?.id === item.id ? "primary-active text-white" : "bg-white"}`}
                          key={`disabled-list-${idx}`}
                        >
                          <TableCell className="text-sm px-6 py-3 justify-end w-44">{item.id}</TableCell>
                          <TableCell
                            className="text-sm px-6 py-3 cursor-pointer"
                            onClick={() => onSelectUserReport(item)}
                          >
                            {item.note}
                          </TableCell>
                          <TableCell
                            className={`text-sm px-6 py-3 flex justify-center items-center w-44 ${item.attachedFilesUrls?.length && "cursor-pointer"}`}
                            onClick={() => {
                              if (item.attachedFilesUrls?.length) {
                                setFiles(item.attachedFilesUrls);
                              }
                            }}
                          >
                            {item.attachedFilesUrls?.length ? (
                              <FileIcon
                                className={`${selectedUserReport?.id === item.id ? "text-gray-200" : "text-gray-900"}`}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell className="text-sm px-6 py-3 justify-end w-44">
                            <div className="flex justify-center">
                              <Checkbox
                                wrapperClass="w-4 h-4"
                                variant={selectedUserReport?.id === item.id ? "secondary" : "primary"}
                                checked={item.isDone}
                                onChange={() => changeStatus(item)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </>
              )}
            </TableBody>
          </DataTable>
        </div>
        <div className="mb-2 flex flex-col items-end">
          <Pagination
            onChangePage={onChangePage}
            currentPage={userReportParams.pageIndex}
            perPage={userReportParams.pageSize}
            totalPage={userReportList.totalPages}
          />
        </div>
      </div>
      {files.length > 0 && (
        <ReportFilesModal
          header="Report files"
          fileUrls={files}
          isOpen={!!files.length}
          onDismiss={() => setFiles([])}
        />
      )}
    </>
  );
};

interface GenNoRecordProps {
  colSpan?: number;
  message?: string;
}

export const GenNoRecord = ({ colSpan = 5, message = "No results found" }: GenNoRecordProps) => {
  return (
    <tr className="bg-default">
      <td className="px-6 py-4 whitespace-nowrap bg-white	 text-primary" colSpan={colSpan}>
        <div className="flex items-center justify-center">{message}</div>
      </td>
    </tr>
  );
};

export const EmptyData = () => (
  <div className="flex flex-col w-full gap-y-2 bg-white p-7">
    <div className="flex justify-between mb-11">
      <span className="font-bold text-lg">Report</span>
    </div>
    <div className="w-full bottom-2 pb-8 h-full">
      <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
        <TableHeader
          className="text-black bg-white text-sm border-b-2 border-secondary"
          columnName={defaultUserReportCells}
        />
        <TableBody className="min-h-fit  max-h-full">
          <GenNoRecord message="Please select a city" colSpan={5} />
        </TableBody>
      </DataTable>
    </div>
  </div>
);
