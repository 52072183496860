interface TableSkeletonProps {
  rows?: number;
  colSpan?: number;
}
export const TableSkeleton = ({ rows = 5, colSpan = 5 }: TableSkeletonProps) => {
  return (
    <tr className="bg-white">
      <td className="px-6 py-4 whitespace-nowrap bg-white text-black" colSpan={colSpan}>
        <div className="animate-pulse flex flex-row gap-x-2 items-center w-full">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-2 bg-secondary rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-5 gap-4">
                {Array(rows)
                  .fill(1)
                  .map((_: number, idxRow: number) => {
                    return (
                      <div key={`row-${idxRow}`} className="">
                        {Array(colSpan)
                          .fill(1)
                          .map((_: number, idxCol: number) => {
                            return (
                              <div
                                key={`row-${idxRow}-col-${idxCol}`}
                                className="h-2 bg-secondary rounded col-span-1 mb-2"
                              ></div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
              <div className="h-2 bg-secondary rounded"></div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
