import { useCombobox, useMultipleSelection } from "downshift";
import * as React from "react";
import { Badge, BadgeProps } from "../badge";
import { SpinIcon } from "../icons";
import { isDefined } from "helper/utils";
import { Label } from "../label";
import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { Fragment } from "react";

export interface MultiInputWithSuggestionsProps
  extends Omit<React.ComponentProps<"input">, "onChange" | "onKeyDown" | "value" | "size"> {
  values: string[];
  onChangeValues: (values: string[]) => void;
  badgeColor?: BadgeProps["variant"];
  size?: BadgeProps["size"];
  validateBeforeAdd?: (value: string) => boolean;
  suggestions?: string[] | undefined;
  onInputValueChange: (value: string) => void;
  label?: string;
}

/**
 * Select with tags, transform input to tag (scroll the menu).
 * User can use: tab, enter, comma to add an item.
 * **/
export const MultiSelectSuggestions = ({
  size = "large",
  badgeColor = "grey",
  onChangeValues,
  validateBeforeAdd,
  className,
  suggestions,
  onInputValueChange,
  values: providedValues,
  label,
  ...inputProps
}: MultiInputWithSuggestionsProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const divRef = React.useRef<HTMLDivElement>(null);
  const { name } = inputProps;

  const { getSelectedItemProps, getDropdownProps, selectedItems } = useMultipleSelection({
    selectedItems: providedValues,
    onSelectedItemsChange: ({ selectedItems }) => {
      onChangeValues(selectedItems as string[]);
    },
  });
  const addSelectedItem = (value: string) => {
    onChangeValues(selectedItems.concat(value));
  };
  const removeSelectedItem = (value: string) => {
    onChangeValues(selectedItems.filter((val) => val !== value));
  };

  const addItem = (value: string) => {
    const val = value.trim();
    if (val && (!validateBeforeAdd || validateBeforeAdd(val))) {
      addSelectedItem(val);
      setInputValue("");
    }
  };

  const handleInputChange = (value: string | undefined) => {
    if (isDefined(value)) {
      if (!/^,+$/.test(value) && value[value.length - 1] === ",") {
        addItem(value.slice(0, -1));
      } else {
        setInputValue(value);
      }
    }
  };

  const { isOpen, getInputProps, getItemProps, getMenuProps, highlightedIndex } = useCombobox({
    inputValue,
    selectedItem: null,
    items: suggestions || [],
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          handleInputChange(inputValue);
          onInputValueChange(inputValue || "");

          break;

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            addItem(selectedItem);
          }
          break;

        default:
          break;
      }
    },
  });

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Tab" && inputValue) {
      ev.preventDefault();
      addItem(inputValue);
    } else if (ev.key === "Enter" && inputValue && highlightedIndex === -1) {
      ev.preventDefault();
      addItem(inputValue);
    }
  };

  return (
    <div>
      <Label htmlFor={name || ""} className="form-label-focus">
        {label}
      </Label>
      <div className="w-full max-w-sm">
        <Popover className="relative">
          {() => (
            <>
              <PopoverButton
                className={`
                ${isOpen ? "" : "text-opacity-90"}
                group inline-flex`}
              >
                <div
                  className="flex space-y-1 items-center flex-wrap form-input cursor-text overflow-x-auto"
                  ref={divRef as any}
                >
                  {selectedItems.map((selectedItem, index) => (
                    <Badge
                      key={`selectedItem-${index}`}
                      {...getSelectedItemProps({
                        selectedItem,
                        index,
                      })}
                      size={size}
                      variant={badgeColor}
                      onRemove={(e) => {
                        e.stopPropagation();
                        removeSelectedItem(selectedItem);
                      }}
                      className="mr-1"
                    >
                      {selectedItem}
                    </Badge>
                  ))}

                  <input
                    {...getInputProps(
                      getDropdownProps({
                        onKeyDown: handleKeyDown,
                        ...inputProps,
                      }),
                    )}
                    className={clsx(
                      "min-w-24 w-full focus:outline-none bg-transparent border-0",
                      !hasClassName(className, "w-") && "flex-1",
                      className,
                    )}
                  />
                </div>
              </PopoverButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute z-10 mt-2">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div
                      {...getMenuProps(
                        {
                          className: "text-sm rounded-md bg-white shadow-xs max-h-52 overflow-auto",
                        },
                        { suppressRefError: true },
                      )}
                    >
                      {suggestions ? (
                        suggestions.map((suggestion, index) => (
                          <div
                            key={`${suggestion}-${index}`}
                            {...getItemProps({
                              item: suggestion,
                              index: index,
                              className: clsx(
                                "px-4 py-2",
                                index === highlightedIndex && "text-neutral-800 bg-neutral-100 cursor-pointer",
                              ),
                            })}
                          >
                            {suggestion}
                          </div>
                        ))
                      ) : (
                        <div className="text-center p-3">
                          <SpinIcon className="w-5 h-5 animate-spin inline-block" />
                        </div>
                      )}
                    </div>
                  </div>
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};
