import { MacButton } from "../button";
import { Modal, ModalContent, ModalFooter } from "components";
import { DaysOfWeek } from "types/city.const";
import { TimesOfDayInput } from "types";
import { isEmpty } from "../../helper/utils";
import { Checkbox } from "./check-box";
import { useMemo, useState } from "react";
import { FieldContainer } from "./field-container";
import { TimeInput } from "./time-input";
import { IconButton } from "../icon-button";
import { AddIcon } from "../../assets";
import { DeleteIcon } from "components/icons";
import { Label } from "../label";

export interface DayOfWeekModalProps {
  initialDayOfWeek: DaysOfWeek[];
  initialTimeOfDay: TimesOfDayInput[];
  isOpen: boolean;
  onDismiss?: () => void;
  onApply: (dayOfWeek: DaysOfWeek[], timeOfDay: TimesOfDayInput[]) => void;
  header?: string;
}

const arrDay = [
  {
    name: DaysOfWeek.Mo,
    display: "Monday",
  },
  {
    name: DaysOfWeek.Tu,
    display: "Tuesday",
  },
  {
    name: DaysOfWeek.We,
    display: "Wednesday",
  },
  {
    name: DaysOfWeek.Th,
    display: "Thursday",
  },
  {
    name: DaysOfWeek.Fr,
    display: "Friday",
  },
  {
    name: DaysOfWeek.Sa,
    display: "Saturday",
  },
  {
    name: DaysOfWeek.Su,
    display: "Sunday",
  },
];

export const DayOfWeekModal = ({
  initialDayOfWeek,
  initialTimeOfDay,
  isOpen,
  onDismiss,
  onApply,
  header = "Select day of week",
}: DayOfWeekModalProps) => {
  const [dayOfWeek, setDayOfWeek] = useState(() => syncDayOfWeek(initialDayOfWeek));
  const [timeOfDay, setTimeOfDay] = useState(() => initialTimeOfDay);
  const onChangeDayOfWeek = (index: number) => {
    const newDayOfWeek = dayOfWeek;
    newDayOfWeek[index].isCheck = !newDayOfWeek[index].isCheck;
    setDayOfWeek([...newDayOfWeek]);
  };
  const getCurrentDay = useMemo(() => {
    const days: DaysOfWeek[] = [];
    for (const item of dayOfWeek) {
      if (item.isCheck) {
        days.push(item.name);
      }
    }
    return days;
  }, [dayOfWeek]);

  const handleChangeTimes = ({ input, index, name }: { input: string; index: number; name: string }) => {
    const newTimesOfDay = [...timeOfDay];
    const newTimes = input === "null" ? "" : input;
    if (name === "from") {
      newTimesOfDay[index] = {
        ...newTimesOfDay[index],
        from: newTimes,
      };
    } else {
      newTimesOfDay[index].to = newTimes;
    }
    setTimeOfDay([...newTimesOfDay]);
  };
  const addTimeOfDay = () => {
    const newTimeOfDay = [...timeOfDay, { from: "00:00", to: "00:00" }];
    setTimeOfDay(newTimeOfDay);
  };
  const removeTimeOfDay = (index: number) => {
    const newTimeOfDay = [...timeOfDay];
    newTimeOfDay.splice(index, 1);
    setTimeOfDay([...newTimeOfDay]);
  };
  return (
    <Modal isOpen={isOpen}>
      <ModalContent header={header}>
        <form>
          <FieldContainer className="mt-8">
            <Label>Day</Label>
            <div className="flex">
              {dayOfWeek.map((day, index) => (
                <Checkbox
                  label={day.display}
                  onChangeValue={() => onChangeDayOfWeek(index)}
                  checked={day.isCheck}
                  key={day.name}
                  wrapperClass="mr-2"
                />
              ))}
            </div>
          </FieldContainer>
          <FieldContainer>
            <Label>Times</Label>
            <>
              {timeOfDay.map((item, index) => {
                return (
                  <div key={`time-of-day${index}`} className="flex items-center mt-3">
                    <TimeInput
                      times={item.from}
                      key={`time-from-${index}`}
                      onChangeValue={(value: string) => handleChangeTimes({ input: value, index: index, name: "from" })}
                    />
                    <span className="ml-3">-</span>
                    <TimeInput
                      times={item.to}
                      key={`time-to-${index}`}
                      onChangeValue={(value: string) => handleChangeTimes({ input: value, index: index, name: "to" })}
                      wrapperClass="ml-3"
                    />
                    {index !== 0 && (
                      <div className="flex items-center mt-3">
                        <IconButton
                          className="w-6 h-6"
                          onClick={() => removeTimeOfDay(index)}
                          disabled={timeOfDay.length === 1}
                        >
                          <DeleteIcon className="w-4 h-4 text-gray-400" />
                        </IconButton>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="flex items-center mt-3">
                <IconButton className="w-6 h-6" onClick={addTimeOfDay}>
                  <AddIcon className="w-4 h-4 text-gray-400" />
                </IconButton>
              </div>
            </>
          </FieldContainer>

          <ModalFooter>
            <MacButton
              variant="primary"
              disabled={isEmpty(getCurrentDay)}
              onClick={(ev) => {
                ev.preventDefault();
                if (!isEmpty(getCurrentDay) || !isEmpty(timeOfDay)) {
                  onApply(getCurrentDay, timeOfDay);
                }
              }}
            >
              Apply
            </MacButton>
            <MacButton onClick={onDismiss} className="mr-2" variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

function syncDayOfWeek(list: DaysOfWeek[]) {
  const arrayDay = [];
  for (const item of arrDay) {
    arrayDay.push({ ...item, isCheck: list.includes(item.name) });
  }
  return arrayDay;
}
