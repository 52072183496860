import { SVGProps } from "react";

export const BoldSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4045 9.46275L10.722 11.7773C11.0145 12.0743 11.487 12.0743 11.7795 11.7773C12.0735 11.481 12.0735 11.0017 11.7795 10.7048L9.4695 8.39475C10.122 7.51875 10.5097 6.43275 10.5097 5.25525C10.5097 2.35275 8.157 0 5.25525 0C2.35275 0 0 2.35275 0 5.25525C0 8.15775 2.35275 10.5105 5.25525 10.5105C6.4365 10.5105 7.527 10.1205 8.4045 9.46275ZM5.2545 9.009C3.18225 9.009 1.5015 7.329 1.5015 5.25525C1.5015 3.18225 3.1815 1.5015 5.25525 1.5015C7.32825 1.5015 9.00825 3.1815 9.00825 5.25525C9.00825 7.32825 7.32825 9.009 5.256 9.009H5.2545Z"
      fill="#41434C"
    />
  </svg>
);
