import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { RgbaColor, RgbaColorPicker, HexColorInput } from "react-colorful";
import clsx from "clsx";

import { convertHexToRgba, convertRgbaToHex, formatRGBAColorToString } from "helper/utils";
import { PresetColorByPolicy } from "modules/policies/policies.const";
import { Tooltip } from "./tooltip";

interface ColorPickerProps {
  color: RgbaColor;
  onChange: (color: RgbaColor) => void;
  className?: string;
  popoverClassName?: string;
  presetColors?: PresetColorByPolicy[];
}

export const ColorPicker = ({ color, onChange, className, popoverClassName, presetColors }: ColorPickerProps) => {
  const handleSelectColor = (value: RgbaColor) => {
    onChange(value);
  };
  const handleInputHexColor = (newColor: string) => {
    onChange(convertHexToRgba(newColor));
  };

  return (
    <Popover className={clsx("relative", className)}>
      <PopoverButton
        className="w-20 h-8 text-sm border border-primary rounded"
        style={{ backgroundColor: formatRGBAColorToString(color) }}
      >
        {!color}
      </PopoverButton>

      <PopoverPanel className={clsx("absolute z-10 bg-default", popoverClassName)}>
        <div className="relative flex flex-col shadow pr-2 pb-4 rounded">
          <div className="flex">
            <RgbaColorPicker color={color} onChange={handleSelectColor} />
            <div className="grid grid-cols-3 gap-2 w-20 py-2 pl-3">
              {presetColors?.map((preset) => (
                <Tooltip label={preset.title} offset={0} key={`color-picker-${preset.title}`}>
                  <button
                    className="w-4 h-4 rounded border border-primary"
                    style={{ backgroundColor: formatRGBAColorToString(preset.rgba) }}
                    onClick={() => handleSelectColor(preset.rgba)}
                  />
                </Tooltip>
              ))}
            </div>
          </div>

          <div className="ml-5 mt-2">
            <HexColorInput
              className="text-center"
              alpha
              prefixed
              color={convertRgbaToHex(color)}
              onChange={handleInputHexColor}
            />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
};
