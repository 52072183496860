import { ChangeEvent, useMemo, useRef, useState, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { OsmSearchJsonV2 } from "services/common/osm.type";
import { useDebounce } from "hooks/lib-ui";
import { useCityQuery } from "services/web/map.queries";
import { optionType } from "components/form-control/autocomplete";
import { INITIAL_VIEW_STATE } from "constants/map.const";
import * as turf from "@turf/turf";
import { useForm, FormProvider } from "react-hook-form";
import { CreationCity, Geometry, CityInput, City, MapViewType } from "types";
import { Coordinates } from "./Coordinates";
import { Details } from "./Details";
import { AddCityActions } from "./FormActions";
import { GeneralCityDetails } from "./GeneralDetails";
import { bbox } from "@turf/turf";
import { CityOptions } from "./Options";
import { PaginatedCitiesProps, usePaginatedCitiesActions } from "../hooks/paginated-cities.hook";
import { getAllInfoByISO } from "iso-country-currency";
import WebMercatorViewport from "viewport-mercator-project";
import WorldCities from "worldcities";

export interface AddCityModalProps extends PaginatedCitiesProps {
  onDismiss: React.Dispatch<React.SetStateAction<boolean>>;
  city?: City;
  open: boolean;
}

const defaultInputCityValue: CityInput = {
  name: "",
  zipCode: "",
  departement: "",
  state: "",
  country: "",
  countryCode: "",
  surfaceArea: "",
  dataProviderName: "",
  deviceIds: [],
  latitude: "",
  longitude: "",
  boundaries: "",
  currency: {
    code: "",
    symbol: "",
  },
  timezone: "",
  options: {
    prediction: false,
    live: false,
    offstreet: false,
    payment: false,
    paymentStats: false,
    mapIntegration: false,
    decreeAnalysis: false,
  },
};
const options = {
  shouldValidate: true,
};
interface TInputField<T> {
  name: T;
  value: string;
  options?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
        shouldTouch: boolean;
      }>
    | undefined;
}

export const AddCity = ({
  onDismiss,
  city,
  open,
  cityName: searchValue,
  setCurrentPage,
  currentPage,
}: AddCityModalProps) => {
  const [cityName, setCityName] = useState<string>("");
  const [viewState, setViewState] = useState<MapViewType>();
  const [enableProvider, setEnableProvider] = useState<boolean>(!!city?.details?.dataProvider?.name);
  const { createNewCity, updateCity } = usePaginatedCitiesActions({
    cityName: searchValue,
    setCurrentPage,
    currentPage,
  });
  const [selectedCity, setSelectedCity] = useState<OsmSearchJsonV2 | undefined>(undefined);
  const searchString = useDebounce(cityName, 500);
  const mapRef = useRef<HTMLDivElement>();
  const methods = useForm<CityInput>({ defaultValues: defaultInputCityValue, mode: "onChange" });
  const {
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = methods;
  const handleSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setCityName(value);
    if (errors.name) {
      clearErrors("name");
    }
  };
  const { data, isLoading } = useCityQuery({
    name: searchString,
  });

  useEffect(() => {
    if (city) {
      setCityName(city.name);
      setValue("name", city.name, options);
      setValue("timezone", city.details?.timezone?.iana);
      let cityData: TInputField<keyof CityInput>[] = [
        { name: "boundaries", value: JSON.stringify(city.boundaries), options },
        { name: "latitude", value: city?.position[1]?.toString(), options },
        { name: "longitude", value: city?.position[0]?.toString(), options },
        { name: "zipCode", value: city.zipCode || "", options },
      ];

      if (city.details) {
        cityData = [
          ...cityData,
          { name: "surfaceArea", value: city.details.surfaceArea?.toString(), options },
          { name: "departement", value: city?.details?.departement, options },
          { name: "state", value: city?.details.state, options },
          { name: "country", value: city.details.country, options },
          { name: "countryCode", value: city.details?.countryCode, options },
        ];
      }

      cityData.forEach(({ name, value, options }) => setValue(name, value, options));

      setValue("currency.code", city?.details?.currency?.code || "", options);
      setValue("currency.symbol", city?.details?.currency?.symbol, options);
      setValue("options.prediction", !!city?.details?.options?.prediction, options);
      setValue("options.offstreet", !!city?.details?.options?.offstreet, options);
      setValue("options.live", !!city?.details?.options?.live, options);
      setValue("options.payment", !!city?.details?.options?.payment, options);
      setValue("options.paymentStats", !!city?.details?.options?.paymentStats, options);
      setValue("options.mapIntegration", !!city?.details?.options?.mapIntegration, options);
      setValue("options.decreeAnalysis", !!city?.details?.options?.decreeAnalysis, options);
      setValue("options.decreeAnalysisCount", city?.details?.options?.decreeAnalysisCount, options);
    }
  }, [city, setValue]);

  useEffect(() => {
    if (city && city.boundaries) {
      const viewport = new WebMercatorViewport({
        ...INITIAL_VIEW_STATE,
        width: mapRef.current?.clientWidth || 0,
        height: mapRef.current?.clientHeight || 0,
      });
      const [minLng, minLat, maxLng, maxLat] = bbox({
        type: "Feature",
        properties: {},
        geometry: city.boundaries,
      });
      const { longitude, latitude, zoom } = viewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 2 },
      );
      setViewState(() => ({
        ...INITIAL_VIEW_STATE,
        latitude,
        longitude,
        zoom,
        width: mapRef.current?.clientWidth || 0,
        height: mapRef.current?.clientHeight || 0,
      }));
    } else {
      setViewState({
        ...INITIAL_VIEW_STATE,
        width: mapRef.current?.clientWidth || 0,
        height: mapRef.current?.clientHeight || 0,
      });
    }
  }, [city]);

  const handleSelectCity = (value: string, label: string) => {
    setCityName(label);
    if (data && data.length > 0) {
      const currentCityInfo = data[Number(value)];
      setSelectedCity(currentCityInfo);
      const { lat, lon } = currentCityInfo;
      const nearestCity = WorldCities.getNearestCity(Number(lat), Number(lon));
      if (nearestCity) {
        setValue("timezone", nearestCity.timezone);
      }
      const cityData: TInputField<keyof CityInput>[] = [
        { name: "departement", value: currentCityInfo.address.city as string, options },
        { name: "boundaries", value: JSON.stringify(currentCityInfo.geojson), options },
        { name: "latitude", value: currentCityInfo.lat, options },
        { name: "longitude", value: currentCityInfo.lon, options },
        { name: "state", value: currentCityInfo.address.state, options },
        { name: "country", value: currentCityInfo.address.country, options },
        { name: "countryCode", value: currentCityInfo.address.country_code, options },
      ];
      cityData.forEach(({ name, value, options }) => setValue(name, value, options));

      setValue("currency.code", getAllInfoByISO(currentCityInfo.address.country_code).currency, options);
      setValue("currency.symbol", getAllInfoByISO(currentCityInfo.address.country_code).symbol, options);

      if (currentCityInfo.geojson.type === "Polygon") {
        const surface = turf.area(turf.polygon(currentCityInfo.geojson.coordinates));
        setValue("surfaceArea", surface.toString());
      }

      const [minLng, minLat, maxLng, maxLat] = [
        +currentCityInfo.boundingbox[2],
        +currentCityInfo.boundingbox[0],
        +currentCityInfo.boundingbox[3],
        +currentCityInfo.boundingbox[1],
      ];
      if (mapRef.current) {
        const viewport = new WebMercatorViewport({
          ...INITIAL_VIEW_STATE,
          width: mapRef.current?.clientWidth || 0,
          height: mapRef.current?.clientHeight || 0,
        });

        const { longitude, latitude, zoom } = viewport.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 2 },
        );

        setViewState({
          ...INITIAL_VIEW_STATE,
          longitude,
          latitude,
          zoom,
          width: mapRef.current?.clientWidth || 0,
          height: mapRef.current?.clientHeight || 0,
        });
      }
    }
  };

  const result = useMemo(() => {
    const cities: Array<optionType<string>> = [];
    if (data && data.length > 0) {
      data.forEach((item: OsmSearchJsonV2, index: number) => {
        cities.push({
          value: `${index}`,
          label: item.display_name,
        });
      });
    }
    return cities;
  }, [data]);

  const addCity = async (inputData: CityInput) => {
    const decreeAnalysisCount = inputData.options?.decreeAnalysis ? inputData.options?.decreeAnalysisCount : [];
    const options = inputData.options ? { ...inputData.options, decreeAnalysisCount } : undefined;

    const details = {
      departement: inputData.departement,
      state: inputData.state,
      country: inputData.country,
      surfaceArea: +inputData.surfaceArea,
      osmId: +(selectedCity?.osm_id as number) || 0,
      countryCode: inputData.countryCode,
      options: options,
      currency: {
        code: inputData.currency.code,
        symbol: inputData.currency.symbol,
      },
      timezone: {
        iana: inputData.timezone,
      },
    };

    const dataProvider = {
      name: inputData.dataProviderName,
      deviceIds: inputData.deviceIds.map((item: { device: string }) => item.device),
    };

    let newCity: CreationCity = {
      name: inputData.name,
      zipCode: inputData.zipCode,
      details,
      position: [+inputData.longitude, +inputData.latitude],
      boundaries: JSON.parse(inputData.boundaries) as Geometry,
    };

    if (enableProvider) {
      newCity = {
        ...newCity,
        details: {
          ...details,
          dataProvider,
        },
      };
    }

    if (city?.id) {
      const updatedCity = { ...city, ...newCity };
      await updateCity({ variables: { city: updatedCity } });
    } else {
      await createNewCity({ variables: { city: newCity } });
    }
    onDismiss(false);
  };
  return (
    <Dialog as="div" className="relative z-10" open={open} onClose={() => onDismiss(false)}>
      <div className="fixed inset-0 bg-black bg-opacity-25" />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(addCity)}>
          <div className="fixed inset-0 flex justify-center p-4">
            <DialogPanel className="w-4/6 max-w-full rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                {city ? "Edit City" : "Add City"}
              </DialogTitle>
              <div className="w-full h-full overflow-y-auto px-4 pb-3 flex flex-col justify-between">
                <div className="flex flex-col gap-y-2">
                  <GeneralCityDetails
                    handleSearch={handleSearch}
                    handleSelectCity={handleSelectCity}
                    isLoading={isLoading}
                    result={result}
                    cityName={cityName}
                    selectedCity={selectedCity}
                  />
                  <CityOptions />
                  <Details city={city} enableProvider={enableProvider} setEnableProvider={setEnableProvider} />
                  <Coordinates
                    ref={mapRef}
                    viewState={viewState}
                    setViewState={setViewState}
                    selectedCity={selectedCity}
                  />
                </div>
                <AddCityActions onDismiss={onDismiss} />
              </div>
            </DialogPanel>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
