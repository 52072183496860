import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { FieldStatus, borderStatus } from "./field.type";
import { HintText } from "../hint-text";
import { Label } from "../label";

export type FieldContainerProps = {
  status?: FieldStatus;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  labelAlign?: "center" | "start";
  className?: string;
  children: React.ReactNode;
  readOnly?: boolean;
  htmlFor?: string;
};

export const FieldContainer = ({
  status = "default",
  helpText,
  label,
  className,
  children,
  htmlFor,
}: FieldContainerProps) => {
  const getClass = clsx(!hasClassName(className, "mb-", "my-") && "mb-8", className);

  if (label) {
    return (
      <div className={getClass}>
        <div className="grid grid-cols-4 sm:items-center">
          <Label className={clsx("flex-1 sm:mb-0")} htmlFor={htmlFor}>
            {label}
          </Label>
          <div className={clsx("col-span-3", borderStatus[status])}>{children}</div>
        </div>
        {!!helpText && (
          <div className="grid grid-cols-4">
            <div />
            <div className="col-span-3">
              <HintText status={status}>{helpText}</HintText>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={getClass}>
      {children}
      {helpText && <HintText status={status}>{helpText}</HintText>}
    </div>
  );
};
