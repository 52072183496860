import { IPrivateParking } from "../../types";

export const initPrivateParking: IPrivateParking = {
  name: "",
  location: {
    type: "Point",
    coordinates: [],
  },
  address: "",
  cityId: "",
  externalId: "",
  totalPlaces: 0,
  pricePerHour: 0,
  maxHeight: 0,
  levels: 0,
  structure: "",
  otherAdresses: [],
  options: {
    evChargers: 0,
    disablesPlaces: 0,
    bicyclePlaces: 0,
    motorcyclePlaces: 0,
  },
  openingHours: [],
};

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 60, height: 60 },
};

export const MARKER_MAPPING = {
  marker: { x: 0, y: 0, width: 60, height: 70 },
};

export enum PrivateParkingModalState {
  SHOW = "SHOW",
  DEACTIVATE = "DEACTIVATE",
  MINIMIZE = "MINIMIZE",
}
