import clsx from "clsx";
import { useMemo, useRef, useState } from "react";
import { InputButtonProps } from "./input-button";
import { CalendarIcon, ChevronDownIcon, CloseIcon } from "../icons";
import { hasClassName } from "helper/check-class";
import { DateRangeType, formatDateRange, Ranges } from "helper/date-time";
import { DateRangePickerSelector } from "./DateRangePicker";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Range } from "react-date-range";
import { isEmpty } from "lodash";
import { VariantButton } from "../button";

export interface DateRangeInputProps extends InputButtonProps {
  from?: Date | null;
  to?: Date | null;
  onChangeValue: (newDateRange: Range, type?: DateRangeType) => void;
  placeholder?: string;
  selectedValue?: DateRangeType;
  variant?: VariantButton;
  minDate?: Date;
  maxDate?: Date;
}

export const DateRangeSelector = ({
  variant = "primary",
  selectedValue = "custom",
  from,
  to,
  onChangeValue,
  placeholder = "Période",
  className,
  minDate,
  maxDate,
  ...buttonProps
}: DateRangeInputProps) => {
  const fromValue = from === null ? undefined : from;
  const toValue = to === null ? undefined : to;

  const displayValue = useMemo(() => {
    if (isEmpty(selectedValue) || selectedValue === "custom") {
      return formatDateRange(fromValue, toValue);
    }
    return Ranges.filter((item) => item.name === selectedValue)[0].display;
  }, [fromValue, toValue, selectedValue]);

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const styles = getStyle(ref);

  const handleChangeDate = (newDateRange: Range, type: DateRangeType) => {
    referenceElement?.click();
    onChangeValue(newDateRange, type);
  };

  return (
    <div
      ref={ref}
      className={clsx("transition-opacity h-11 bg-default p-1 mb-2 rounded-md shadow-lg date-range", className)}
    >
      <Popover className="relative">
        <PopoverButton
          className={clsx(
            "flex items-center min-w-0",
            "h-11 rounded-t-small",
            buttonProps.disabled
              ? "bg-gray-200 cursor-default"
              : [btnClassByVariant[variant], !hasClassName("active:") && "active:shadow-outline"],
            !hasClassName(className, "w-") && "w-60",
          )}
          ref={setReferenceElement}
        >
          <CalendarIcon className="w-sm h-sm" color={colorByVariant[variant]} />
          <span
            className={clsx(
              "flex-1 truncate text-left text-table-data text-center capitalize ml-1",
              textColorByVariant[variant],
            )}
          >
            {displayValue || <span className="text-lightgrey">{placeholder}</span>}
          </span>
          <div className="flex w-6">
            <ChevronDownIcon color={colorByVariant[variant]} className="rotate-90" />
            <ChevronDownIcon className="-rotate-90" color={colorByVariant[variant]} />
          </div>
          <div
            onClick={() => handleChangeDate({ startDate: undefined, endDate: undefined }, "custom")}
            className="flex w-6"
            role="presentation"
          >
            <CloseIcon className="text-black w-4 h-4" />
          </div>
        </PopoverButton>
        <PopoverPanel className="absolute z-10" style={{ ...styles }}>
          <DateRangePickerSelector
            onSelectDate={handleChangeDate}
            selectedType={selectedValue}
            startDate={fromValue}
            endDate={toValue}
            minDate={minDate}
            maxDate={maxDate}
            onClose={() => referenceElement?.click()}
          />
        </PopoverPanel>
      </Popover>
    </div>
  );
};

const getStyle = (ref: React.RefObject<HTMLDivElement>) => {
  const windowWidth = window.innerWidth;
  let leftSpace = windowWidth - 80;
  const defaultStyle = {
    top: 45,
    left: 0,
  };
  if (!ref.current?.offsetLeft) {
    return defaultStyle;
  } else {
    leftSpace = leftSpace - ref.current?.offsetLeft;
  }
  if (leftSpace < 808) {
    return { top: 45, right: -80 };
  }
  return defaultStyle;
};

const btnClassByVariant: Record<VariantButton, string> = {
  primary: "bg-primary",
  secondary: "bg-transparent",
  outline: "bg-transparent",
  error: "bg-red-500",
};

const colorByVariant: Record<VariantButton, string> = {
  primary: "#F3F3F4",
  secondary: "#28292F",
  outline: "#28292F",
  error: "#28292F",
};

const textColorByVariant: Record<VariantButton, string> = {
  primary: "text-secondary",
  secondary: "text-primary",
  outline: "text-primary",
  error: "text-rose-500",
};
