import { CSSAlignement, HeaderItem, SortDirection } from "components/data-table";
import { UserReport } from "types/user-report.type";

export const defaultUserReportCells: HeaderItem<keyof UserReport>[] = [
  { title: "ID", align: CSSAlignement.left },
  {
    title: "Note",
    orderBy: "note",
    sortable: true,
    sortDirection: SortDirection.ASC,
    align: CSSAlignement.left,
  },
  {
    title: "File",
    align: CSSAlignement.center,
    className: "w-20",
  },
  {
    title: "Done",
    align: CSSAlignement.center,
    className: "w-20",
    sortable: true,
    orderBy: "isDone",
    sortDirection: SortDirection.DESC,
  },
];
