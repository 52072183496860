import { makeVar } from "@apollo/client";
import { TableContainer } from "components/TableContainer";
import { useDebounce } from "hooks/lib-ui";
import { useState } from "react";
import { FilterParams, initPage } from "types";
import { CityTable } from "./CityTable";
import { FiltersBar } from "./FiltersBar";

export const filter = makeVar("");

export const CitiesPanel = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebounce = useDebounce(searchValue, 500);
  const [currentPage, setCurrentPage] = useState<FilterParams>({
    ...initPage,
    sortField: "name",
  });
  return (
    <div className="flex flex-col">
      <TableContainer
        filters={
          <FiltersBar setSearchValue={setSearchValue} {...{ cityName: searchDebounce, setCurrentPage, currentPage }} />
        }
        table={<CityTable {...{ cityName: searchDebounce, setCurrentPage, currentPage }} />}
      />
    </div>
  );
};
