import * as React from "react";
import { GlobalSpinner } from "./global-spinner";
import { GlobalProgressBar } from "./global-progess-bar";

export type LoadingProps = Omit<React.ComponentPropsWithoutRef<"div">, "title"> & {
  type?: LoadingType;
};

export type LoadingType = "spinner" | "progress";

export function LoadingComponentType({ type = "spinner" }: { type: LoadingType }) {
  return (
    <div id="loading" className="hidden">
      {loadingByVariant[type]}
    </div>
  );
}

type LoadingVariant = NonNullable<LoadingProps["type"]>;

const loadingByVariant: Record<LoadingVariant, React.ReactNode> = {
  spinner: <GlobalSpinner />,
  progress: <GlobalProgressBar />,
};
