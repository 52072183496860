import * as React from "react";
import { DescriptionList, DescriptionItem } from "components";
import { IPrivateParking } from "types";
import { moneyFormat, numberFormat } from "helper/format-data";
import { toolTipStyle } from "helper/utils";

export interface parkingTooltipProps extends React.ComponentPropsWithoutRef<"div"> {
  left: number;
  top: number;
  privateParking: Omit<IPrivateParking, "location">;
}

export const PrivateParkingTooltip = (props: parkingTooltipProps) => {
  const { left, top, privateParking } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const styles = toolTipStyle({ top, left, height: 207, width: 384 });

  return (
    <div style={styles} className="absolute w-96" ref={ref}>
      <div className="relative transition-opacity h-auto p-3 mt-2 mb-3 rounded-md shadow-lg z-10 bg-primary">
        <div className="text-secondary text-13px mb-4 font-bold">{privateParking.name}</div>
        <DescriptionList>
          <DescriptionItem label="Address" value={privateParking.address} />
          {!!privateParking.totalPlaces && (
            <DescriptionItem label="Parking capacity" value={numberFormat(privateParking.totalPlaces)} />
          )}
          {!!privateParking.pricePerHour && (
            <DescriptionItem label="Price per hour" value={moneyFormat(privateParking.pricePerHour)} />
          )}
          <DescriptionItem label="External Id" value={privateParking.externalId} />
        </DescriptionList>
      </div>
    </div>
  );
};
