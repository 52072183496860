import { useEffect, useRef } from "react";

export function useDebouncedCallback<T>(callback: (args: T) => void, wait = 500) {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => cleanup, []);

  return function debouncedCallback(args: T) {
    cleanup();

    timeout.current = setTimeout(() => {
      callback(args);
    }, wait);
  };
}
