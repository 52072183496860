import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { CURRENT_ENVIRONMENT_SUFFIX } from "constants/environment.const";
import { GraphqlProvider } from "contexts/GraphqlProvider";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes/router";
import { Notification } from "./components";
import { auth0Config } from "./configs/auth-config";
import { CITY_ID, clearItem } from "./helper/storage";

const onRedirectCallback = (appState?: AppState) => {
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};
function App() {
  useEffect(() => {
    document.title = `Parking Drawer${CURRENT_ENVIRONMENT_SUFFIX}`;
    const unloadCallback = () => {
      clearItem(CITY_ID);
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: auth0Config.redirect_uri,
        audience: auth0Config.audience,
        scope: auth0Config.scope,
      }}
      useRefreshTokensFallback={true}
    >
      <GraphqlProvider>
        <RouterProvider router={router} />
      </GraphqlProvider>
      <Notification />
    </Auth0Provider>
  );
}

export default App;
