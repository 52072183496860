import * as React from "react";

import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Restriction, RestrictionType } from "types";
import { Feature, Polygon } from "geojson";

import { TimeSpanContent } from "components/time-span/time-span-item";
import { twMerge } from "tailwind-merge";

type DisabledCurbZoneByDecreeProps = {
  listDisabledCurbZones: Restriction[];
  onSelectDescriptionCard: (disabledCurbZone: Feature<Polygon, Restriction>) => void;
  selectedDescriptionCardId: string | number | undefined;
  selectedDecreeId: string | undefined;
};

export const DisabledCurbZoneByDecree = ({
  listDisabledCurbZones,
  onSelectDescriptionCard,
  selectedDescriptionCardId,
  selectedDecreeId,
}: DisabledCurbZoneByDecreeProps) => {
  if (!listDisabledCurbZones?.length) {
    return <EmptyData />;
  }

  const listPolygonsDisabledCurbZones = listDisabledCurbZones?.map((item) => {
    return {
      properties: { ...item, decreeIds: [selectedDecreeId] },
      type: "Feature",
      geometry: item.geometry,
      id: item.id,
    } as Feature<Polygon, Restriction>;
  });

  return (
    <div className="transition-opacity absolute top-16 list-disabled-curb-zones right-6 z-5 w-80 overflow-y-auto">
      {listPolygonsDisabledCurbZones.map((disabledPart, idx) => (
        <DisabledCurbZoneItem
          onSelectedDisabledPart={() => onSelectDescriptionCard(disabledPart)}
          disabledPart={disabledPart}
          key={`disabled-detail-${idx}`}
          active={disabledPart.id === selectedDescriptionCardId}
        />
      ))}
    </div>
  );
};

const EmptyData = () => (
  <div className="transition-opacity absolute h-11 top-16 bg-white right-6 rounded-md shadow-lg z-5 w-80">
    <DataTable className="p-0 shadow-none rounded-none">
      <TableBody>
        <TableRow className="hover:none bg-white">
          <TableCell className="text-sm text-center">No results found.</TableCell>
        </TableRow>
      </TableBody>
    </DataTable>
  </div>
);

type DisabledCurbZoneItemProps = {
  disabledPart: Feature<Polygon, Restriction>;
  onSelectedDisabledPart: () => void;
  active: boolean;
};

const DisabledCurbZoneItem = ({ disabledPart, onSelectedDisabledPart, active }: DisabledCurbZoneItemProps) => {
  return (
    <div
      onClick={onSelectedDisabledPart}
      role="presentation"
      className={twMerge(
        "relative transition-opacity h-auto pt-3 pb-3 mb-2 rounded-md shadow-lg z-10 bg-white border-l-4 border-red-500 cursor-pointer",
        active && "bg-neutral-300",
      )}
    >
      <div className="flex flex-col justify-between pr-2.5 pl-5">
        <dt className="text-primary mr-2 text-tiny">Type</dt>
        {disabledPart.properties?.type && (
          <dd className="text-description-value break-all text-primary font-bold">
            {RestrictionType[disabledPart.properties?.type]}
          </dd>
        )}
      </div>
      <div className="pr-2.5 pl-5">
        <span className="text-tiny">Start Date - End Date</span>
        <div>
          {disabledPart.properties?.timeSpans?.map((timeSpan, timeSpanIndex) => (
            <div className="flex items-center border-b py-2 border-gray-900" key={`time-span-${timeSpanIndex}`}>
              <TimeSpanContent
                timeSpan={timeSpan}
                name={`timeSpans[${timeSpanIndex}]`}
                options={{
                  showSelectDate: true,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="pr-2.5 pl-5">
        <span className="text-tiny">Description</span>
        <div>
          <span className="font-bold text-sm">{disabledPart.properties?.description}</span>
        </div>
      </div>
      <div className="pr-2.5 pl-5">
        <span className="text-tiny">Places calculées</span>
        <div>
          <span className="font-bold text-sm">{disabledPart.properties?.calculatedSpaces}</span>
        </div>
      </div>
    </div>
  );
};
