interface TableContainerProps {
  table: React.ReactElement;
  filters: React.ReactElement;
}

export const TableContainer = ({ table, filters }: TableContainerProps) => {
  return (
    <>
      <div className="w-full bg-white h-auto mb-3 rounded-lg flex flex-col gap-y-2 px-7 pt-6">
        <div className="flex flex-row justify-between items-center">{filters}</div>
        {table}
      </div>
    </>
  );
};
