import { useQuery } from "@apollo/client";
import { QUERY_ALL_LIST_DECREES } from "services/web/api-decree.const";
import { Decree } from "types/decree.type";

export const useQueryDecreesListByCity = (cityId?: string) => {
  const { data, loading } = useQuery<{ decrees: Decree[] }>(QUERY_ALL_LIST_DECREES, {
    variables: {
      cityId,
    },
    skip: !cityId,
  });
  return {
    decreeList: data?.decrees || [],
    isLoading: loading,
  };
};
