import { Feature, LineString } from "geojson";
import { getPlace } from "./format-data";
import { cloneDeep } from "lodash";
import { CurbZone } from "../types/curb-zone.type";
import { IconLayer } from "deck.gl";
import { MARKER_MAPPING } from "modules/offstreet-parking/private-parking.const";

export const copyLineString = async (input: Feature<LineString, CurbZone>) => {
  const copiedCurbZone = cloneDeep(input);
  const newCoordinates = copiedCurbZone.geometry.coordinates.map((item) => [item[0] + 0.00002, item[1] - 0.00005]);
  copiedCurbZone.geometry = {
    ...copiedCurbZone.geometry,
    coordinates: newCoordinates,
  };
  copiedCurbZone.properties.geometry.coordinates = copiedCurbZone.properties.geometry.coordinates.map((item) => {
    return item.map((point) => [point[0] + 0.00002, point[1] - 0.00005]);
  });
  const convertCurbZone = await getPlace(copiedCurbZone);
  return {
    ...convertCurbZone,
    id: undefined,
    properties: {
      ...convertCurbZone.properties,
      id: undefined,
      curbZoneId: undefined,
    },
  };
};

export const createMarkerLayer = (point: { coordinates: [number, number] }) => {
  return new IconLayer({
    id: "icon-layer",
    data: [point],
    pickable: true,
    iconAtlas: "images/maker.png",
    iconMapping: MARKER_MAPPING,
    getIcon: () => "marker",
    sizeScale: 8,
    getPosition: (d: any) => d.coordinates,
    getSize: () => 3,
  });
};
