const PREFIX = "mca-";
export const CITY_ID = "cityId";

export function saveItem(key: string, value: any) {
  try {
    const savedValue = JSON.stringify(value);
    window.localStorage.setItem(`${PREFIX}${key}`, savedValue);
  } catch {
    return null;
  }
}

export function loadItem(key: string, parse = true): unknown {
  try {
    const value = window.localStorage.getItem(`${PREFIX}${key}`);
    return value && parse ? JSON.parse(value) : value;
  } catch {
    return null;
  }
}

export function clearItem(key: string) {
  try {
    window.localStorage.removeItem(`${PREFIX}${key}`);
  } catch {
    return null;
  }
}
