import { FieldContainer, FormInput } from "components/form-control";
import { Controller, useFormContext } from "react-hook-form";
import { City, CityInput } from "types";
import { AddDeviceIds } from "./AddDevices";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "assets";
import { DropdownAutoCompleteSelect, OptionType } from "components/form-control/dropdown-autocomplete";
import { useDebounce } from "hooks/lib-ui";
import WorldCities from "worldcities";

interface DetailsProps {
  city?: City;
  enableProvider: boolean;
  setEnableProvider: Dispatch<SetStateAction<boolean>>;
}

export const Details = ({ enableProvider, setEnableProvider, city }: DetailsProps) => {
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext<CityInput>();

  const timezoneByCity = getValues("timezone");
  const [searchValue, setSearchValue] = useState<string>("");
  const searchString = useDebounce(searchValue);
  const [listTimeZones, setListTimeZones] = useState<OptionType[]>([]);

  useEffect(() => {
    if (timezoneByCity) {
      const newOptions = [
        {
          label: timezoneByCity,
          value: timezoneByCity,
        },
      ];
      setListTimeZones(newOptions);
      setSearchValue("");
    }
  }, [timezoneByCity]);

  useEffect(() => {
    if (searchString.length >= 3) {
      const result = WorldCities.getAllByName(searchString);
      const newOptions = result.map((item) => ({
        label: item.timezone,
        value: item.timezone,
        description: item.name,
      })) as OptionType[];
      setListTimeZones(newOptions);
    } else if (listTimeZones.length) {
      setListTimeZones([]);
    }
  }, [searchString, listTimeZones.length]);

  useEffect(() => {
    if (city?.details?.dataProvider) {
      setValue("dataProviderName", city?.details?.dataProvider?.name);
    }
  }, [city, setValue]);

  return (
    <div className="w-full flex flex-col">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <DisclosureButton className="flex w-full justify-between items-center rounded-md shadow-lg bg-white  px-4 py-2 text-left text-sm font-medium">
              <h1 className="font-bold text-md">Details</h1>{" "}
              <ChevronDownIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-purple-500`} />
            </DisclosureButton>
            <DisclosurePanel className="px-4 pt-4 pb-2 text-sm">
              <div className="flex flex-row w-full gap-x-4 justify-around">
                <FieldContainer
                  className="basis-1/2 items-center border-b justify-start border-primary"
                  helpText={errors.departement ? "This is required" : undefined}
                  status={errors.departement ? "error" : "default"}
                >
                  <FormInput
                    {...register("departement")}
                    className="border-none"
                    label="Departement"
                    status={errors.departement ? "error" : "primary"}
                    defaultValue={getValues("departement")}
                  />
                </FieldContainer>
                <FieldContainer
                  className="basis-1/2 items-center border-b justify-start border-primary"
                  helpText={errors.state ? "This is required" : undefined}
                  status={errors.state ? "error" : "default"}
                >
                  <FormInput
                    {...register("state")}
                    className="border-none"
                    label="State"
                    status={errors.state ? "error" : "primary"}
                    defaultValue={getValues("state")}
                  />
                </FieldContainer>
              </div>
              <div className="flex flex-row w-full gap-x-4 justify-around">
                <FieldContainer
                  className="basis-1/2 items-center border-b justify-start border-primary"
                  status={errors.country ? "error" : "default"}
                >
                  <FormInput
                    {...register("country")}
                    className="border-none"
                    label="Country"
                    status={errors.country ? "error" : "primary"}
                    defaultValue={getValues("country")}
                  />
                </FieldContainer>
                <FieldContainer
                  className="basis-1/2 items-center border-b justify-start border-primary"
                  status={errors.countryCode ? "error" : "default"}
                >
                  <FormInput
                    {...register("countryCode")}
                    className="border-none"
                    label="Country code"
                    status={errors.countryCode ? "error" : "primary"}
                    defaultValue={getValues("countryCode")}
                  />
                </FieldContainer>
              </div>
              <div className="flex flex-row w-full gap-x-4 justify-around items-center">
                <FieldContainer
                  className="basis-1/2 items-center border-b justify-start border-primary"
                  status={errors.surfaceArea ? "error" : "default"}
                >
                  <FormInput
                    label="Surface area"
                    className="border-none"
                    {...register("surfaceArea")}
                    status={errors.surfaceArea ? "error" : "primary"}
                    defaultValue={getValues("surfaceArea")}
                  />
                </FieldContainer>
                <FieldContainer
                  htmlFor="dataProvider"
                  label="Data Provider"
                  className="basis-1/2 cursor-pointer items-center"
                >
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    id="dataProvider"
                    defaultChecked={enableProvider}
                    onChange={(ev) => setEnableProvider(ev.target.checked)}
                  />
                </FieldContainer>
              </div>
              <h1 className="font-bold text-sm mb-2">Currency</h1>
              <div className="flex flex-row w-full gap-x-4 justify-between items-center mb-6">
                <FieldContainer
                  className="basis-6/12 items-center border-b justify-start border-primary mb-0"
                  status={errors.surfaceArea ? "error" : "default"}
                >
                  <FormInput
                    label="Code"
                    className="border-none"
                    {...register("currency.code")}
                    status={errors.currency?.code ? "error" : "primary"}
                    defaultValue={getValues("currency.code")}
                  />
                </FieldContainer>
                <FieldContainer
                  className="basis-6/12 items-center border-b justify-start border-primary mb-0"
                  status={errors.surfaceArea ? "error" : "default"}
                >
                  <FormInput
                    label="Symbol"
                    className="border-none"
                    {...register("currency.symbol")}
                    status={errors.currency?.symbol ? "error" : "primary"}
                    defaultValue={getValues("currency.symbol")}
                  />
                </FieldContainer>
              </div>
              <div className="w-full gap-x-4 justify-between items-center mb-2">
                <FieldContainer className="items-center border-b border-primary justify-start mb-0" status="default">
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field: { onChange, ref, value } }) => {
                      return (
                        <DropdownAutoCompleteSelect
                          value={value}
                          options={listTimeZones}
                          onChangeValue={onChange}
                          status="default"
                          variant="secondary"
                          handleSearch={setSearchValue}
                          searchString={searchValue}
                          label="Timezone"
                          size="normal"
                          className="w-80"
                          ref={ref}
                        />
                      );
                    }}
                  ></Controller>
                </FieldContainer>
              </div>
              {enableProvider && (
                <div className="mt-6">
                  <h1 className="font-bold text-md mb-2">Data Provider</h1>
                  <div className="flex flex-row w-full">
                    <FieldContainer
                      className="basis-1/2 items-center border-b justify-start border-primary"
                      status={errors.dataProviderName ? "error" : "default"}
                    >
                      <FormInput
                        label="Name"
                        className="border-none"
                        {...register("dataProviderName")}
                        status={errors.dataProviderName ? "error" : "default"}
                      />
                    </FieldContainer>
                  </div>
                  <AddDeviceIds city={city} />
                </div>
              )}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
