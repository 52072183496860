import { makeVar } from "@apollo/client";
import { useContext, useState } from "react";
import { Policy } from "types";
import { PolicyTable } from "./PolicyTable";
import { Confirm, MacButton } from "components";
import { AddIcon } from "assets";
import { AddPolicyModal } from "./AddPolicyModal";
import useToggle from "hooks/use-toggle";
import { CityContext } from "contexts/city-context-provider";
import { defaultPolicy } from "../policies.const";
import { usePolicies } from "../hooks/use-policies";

export const filter = makeVar("");

export const PoliciesPanel = () => {
  const { currentCity } = useContext(CityContext);
  const [openConfirmDelete, setOpenConfirmDelete] = useToggle();
  const [openAddPolicyModal, setOpenAddPolicyModal] = useToggle(false);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(defaultPolicy);
  const { handleDeletePolicy } = usePolicies();

  const editPolicy = (input: Policy) => {
    setSelectedPolicy(input);
    setOpenAddPolicyModal();
  };

  const closeAddPolicyModal = () => {
    setOpenAddPolicyModal();
    setSelectedPolicy(defaultPolicy);
  };
  const handleClickDelete = (input: Policy) => {
    setOpenConfirmDelete();
    setSelectedPolicy(input);
  };
  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete();
    setSelectedPolicy(defaultPolicy);
  };
  const handleRemovePolicy = async () => {
    if (!selectedPolicy.id) return;
    await handleDeletePolicy(selectedPolicy.id);
    handleCloseConfirmDelete();
  };

  return (
    <>
      <div className="flex flex-col gap-y-2 h-auto">
        <div className="w-full bg-white h-auto mb-3 rounded-lg flex flex-col gap-y-2 px-7 pt-6">
          <div className="flex justify-end">
            {currentCity && (
              <MacButton
                onClick={setOpenAddPolicyModal}
                className="flex flex-row gap-x-1 p-2 bg-white shadow-md outline-none font-bold text-sm rounded-md text-black hover:bg-primary hover:bg-opacity-5"
              >
                Add Policy
                <AddIcon className="h-5 w-5" fill="white" />
              </MacButton>
            )}
          </div>
          <PolicyTable selectPolicy={editPolicy} onDelete={handleClickDelete} />
        </div>
      </div>
      {openAddPolicyModal && currentCity && (
        <AddPolicyModal currentCity={currentCity} policyData={selectedPolicy} onClose={closeAddPolicyModal} />
      )}

      {openConfirmDelete && (
        <Confirm
          title="Delete city"
          content="Are you sure that you want to delete this policy?"
          onCancel={handleCloseConfirmDelete}
          onOk={handleRemovePolicy}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
