import { useMutation } from "@apollo/client";
import { useFetchCurbZone } from "hooks/use-curb-zone.hook";
import {
  QUERY_BULK_UPDATE_CURB_ZONE,
  QUERY_CREATE_CURB_ZONE,
  QUERY_CURB_ZONES,
  QUERY_REMOVE_CURB_ZONE,
  QUERY_UPDATE_CURB_ZONE,
  QUERY_REMOVE_CURB_ZONES,
} from "services/web/api-curb-zone.const";
import {
  BulkDeleteCurbZonesResponse,
  CreateCurbZoneResponse,
  MultiCurbZoneResponse,
  RemoveCurbZoneResponse,
  UpdateCurbZoneResponse,
} from "services/web/api-curb-zone.type";

export const useQueryCurbZone = () => {
  const { setIsResetData, loading, nearBy, curbZones, isResetData, setBounding, filter, changeFilter } =
    useFetchCurbZone({ queryString: QUERY_CURB_ZONES });

  const resetState = () => {
    setIsResetData(true);
  };

  const [createCurbZone] = useMutation<CreateCurbZoneResponse>(QUERY_CREATE_CURB_ZONE, {
    onError: resetState,
  });

  const [updateCurbZone] = useMutation<UpdateCurbZoneResponse>(QUERY_UPDATE_CURB_ZONE, {
    onError: resetState,
  });

  const [removeCurbZone] = useMutation<RemoveCurbZoneResponse>(QUERY_REMOVE_CURB_ZONE, {
    onError: resetState,
  });
  const [bulkDeleteCurbZones] = useMutation<BulkDeleteCurbZonesResponse>(QUERY_REMOVE_CURB_ZONES, {
    onError: resetState,
  });

  const [updateMultiCurbZones] = useMutation<MultiCurbZoneResponse>(QUERY_BULK_UPDATE_CURB_ZONE, {
    onError: resetState,
  });

  return {
    nearBy,
    setBounding,
    curbZones,
    loading,
    createCurbZone,
    updateCurbZone,
    removeCurbZone,
    updateMultiCurbZones,
    isResetData,
    setIsResetData,
    bulkDeleteCurbZones,
    filter,
    changeFilter,
  };
};
