import { Checkbox } from "./check-box";
import * as React from "react";

export type MonthsOfYearProps = {
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  months: number[];
};

export type MonthsOfYearType = {
  value: number;
  isCheck: boolean;
};

export const MonthsOfYear = ({ onChange, months = [] }: MonthsOfYearProps) => {
  const mapMonths = syncMonthsOfYear(months);
  const changeValue = (idx: number) => {
    const newDaysOfMonth = mapMonths.reduce((preValue: number[], currentValue, index) => {
      if ((index === idx && !currentValue.isCheck) || (index !== idx && currentValue.isCheck)) {
        return [...preValue, currentValue.value];
      }
      return preValue;
    }, []);
    onChange(newDaysOfMonth);
  };

  return (
    <div className="flex flex-wrap">
      {mapMonths.map((item, idx) => (
        <Checkbox
          label={item.value}
          onChangeValue={() => changeValue(idx)}
          checked={item.isCheck}
          key={`days-of-month-${idx}`}
          wrapperClass="mr-2"
        />
      ))}
    </div>
  );
};

export const syncMonthsOfYear = (list: number[]): MonthsOfYearType[] => {
  const arrayDay = [];
  for (let i = 1; i <= 12; i++) {
    arrayDay.push({ value: i, isCheck: list.includes(i) });
  }
  return arrayDay;
};
