import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from "@headlessui/react";
import { LoadingIcon } from "assets";
import { Label } from "components/label";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { FormInputProps } from "./form-input";
import { isNil } from "helper/utils";

export type optionType<T> = {
  label: string;
  value: T;
  description?: React.ReactNode;
};
export type AutocompleteProps = Omit<FormInputProps, "value" | "onChangeValue"> & {
  value: string;
  onChangeValue: (value: string, label: string) => void;
  optionData?: Array<optionType<string>>;
  messageEmpty?: string;
  isLoading?: boolean;
  className?: string;
  panelClassName?: string;
  inputClassName?: string;
  variant?: "border" | "underline";
  label?: string;
};

type AutocompleteVariant = NonNullable<AutocompleteProps["variant"]>;

const classByVariant: Record<AutocompleteVariant, string> = {
  border: "border border-solid",
  underline: "border-b",
};

export type OptionProps = {
  label: React.ReactNode;
  description?: React.ReactNode;
} & Omit<React.ComponentPropsWithoutRef<"div">, "children">;

export const Option = ({ label, description, ...divProps }: OptionProps) => {
  return (
    <div {...divProps} className="flex items-center px-4 cursor-pointer select-none hover:bg-gray-100">
      <div className="flex-1 overflow-hidden">
        <div className="text-black">{label}</div>
        {description && <div className="text-lightgrey">{description}</div>}
      </div>
    </div>
  );
};

export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(function Autocomplete(
  {
    label,
    placeholder,
    value,
    onChangeValue,
    optionData,
    messageEmpty,
    isLoading,
    className,
    panelClassName,
    inputClassName,
    variant,
    onChange,
    onClick,
    ...props
  },
  ref,
) {
  const [isFocused, setIsFocused] = useState(!!value);
  const getLabelClass = isFocused ? "form-label-focus" : "form-label-nonfocus";
  const notFound = messageEmpty || "No results found.";

  useEffect(() => {
    if (!isNil(value) && !!String(value).trim()) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <Combobox
      value={value}
      onChange={(value) => {
        const selectedOption = optionData?.find((item) => item.value === value);
        if (selectedOption) {
          onChangeValue(selectedOption.value, selectedOption.label);
        }
      }}
    >
      <div className="relative">
        <div className={twMerge("relative w-full", className)}>
          <ComboboxInput
            ref={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              if (!value) {
                setIsFocused(false);
              }
            }}
            placeholder={placeholder}
            className={twMerge(
              "border-primary form-input focus:outline-none focus-visible:outline-none w-full py-2 bg-default text-sm placeholder:text-sm",
              variant && classByVariant[variant],
              inputClassName,
            )}
            displayValue={() => value}
            onChange={onChange}
            onClick={onClick}
            {...props}
          />
        </div>
        <Label className={getLabelClass}>{label}</Label>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <ComboboxOptions
            className={twMerge(
              "mt-2 text-sm w-full rounded-md bg-default shadow-xs max-h-72 overflow-auto",
              panelClassName,
            )}
          >
            {isLoading && (
              <div className="text-center p-3">
                <LoadingIcon className="w-5 h-5 inline-block" />
              </div>
            )}
            {!isLoading && optionData?.length === 0 ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">{notFound}</div>
            ) : (
              optionData?.map((option, idx) => (
                <ComboboxOption
                  key={option.value}
                  className={({ active }) =>
                    `relative cursor-pointer select-none p-2 ${active ? "bg-secondary" : "text-black"}`
                  }
                  value={option.value}
                >
                  <Option key={`${option.value}-${idx}`} label={option.label} description={option.description} />
                </ComboboxOption>
              ))
            )}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
});
