import * as React from "react";

export const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Arrow left</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Calendrier" transform="translate(-1181.000000, -90.000000)">
        <g id="Calendar_composant" transform="translate(888.000000, 75.000000)">
          <path
            d="M299.858381,28.23535 L293.694777,22.4412417 C293.435074,22.1973392 293.435074,21.8032943 293.694777,21.5587583 L299.858381,15.76465 C300.233285,15.4117833 300.843253,15.4117833 301.218822,15.76465 C301.593726,16.1175166 301.593726,16.6902122 301.218822,17.0430789 L295.946197,22.0003168 L301.218822,26.9562876 C301.593726,27.3097878 301.593726,27.8824834 301.218822,28.23535 C300.843253,28.5882167 300.233285,28.5882167 299.858381,28.23535"
            id="Arrow-left"
            transform="translate(297.500000, 22.000000) scale(-1, 1) rotate(180.000000) translate(-297.500000, -22.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);
