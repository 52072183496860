/**
 * @see: https://headlessui.dev/react/listbox
 **/
import clsx from "clsx";
import { Fragment, useMemo, forwardRef, useState, useEffect, ComponentPropsWithoutRef } from "react";
import { isNil, isPrimitive, Primitive } from "helper/utils";
import { borderStatus, FieldStatus } from "./field.type";
import { hasClassName } from "helper/check-class";
import { Transition, Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react";
import { ChevronDownIcon, BoldSearchIcon } from "../icons";
import { FormInput } from "./form-input";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";

export type OptionType = {
  value: Primitive;
  label: string;
  description?: string;
};

export type Variant = "primary" | "secondary" | "outline";

export type Size = "small" | "normal";
export type ElementPosition = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type DropdownAutoCompleteSelectProps<T = string> = ComponentPropsWithoutRef<"select" | "button"> & {
  status?: FieldStatus;
  value?: T | undefined | null;
  onChangeValue?: (value: Primitive, label: string) => void;
  options: T extends Primitive ? T[] | OptionType[] : OptionType[];
  className?: string;
  label?: string;
  placeholder?: string;
  variant?: Variant;
  handleSearch?: (value: string) => void;
  searchString?: string;
  size?: Size;
  styleList?: ElementPosition;
};

export const DropdownAutoCompleteSelect = forwardRef<HTMLSelectElement, DropdownAutoCompleteSelectProps>(
  function DropdownSelect(
    {
      variant = "primary",
      status = "primary",
      placeholder,
      onChangeValue,
      value = "",
      options = [],
      className,
      label,
      handleSearch,
      searchString = "",
      size = "normal",
      styleList,
      ...selectProps
    },
    ref,
  ) {
    const { name } = { ...selectProps };
    const [selectedItem, setSelectedItem] = useState<OptionType | null>(null);
    const { disabled } = selectProps;

    const optionData = useMemo(
      () =>
        (options as any[]).map(
          (opt) =>
            (isPrimitive(opt)
              ? {
                  value: opt,
                  label: String(opt),
                }
              : opt) as OptionType,
        ),
      [options],
    );

    const getLabelClass = clsx(disabled && "text-disabled", classByStatus[status]);

    useEffect(() => {
      if (isNil(value)) {
        setSelectedItem(null);
      } else {
        const newSelected = optionData.find((opt) => opt.value === value);
        if (newSelected) {
          setSelectedItem(newSelected);
        }
      }
    }, [value, optionData]);

    const placeHolderText = useMemo(() => {
      return placeholder ? placeholder : label;
    }, [label, placeholder]);

    return (
      <div className={clsx("relative p-1", classByVariant[variant])}>
        {label && selectedItem && (
          <Label htmlFor={name || ""} className="form-label-focus">
            {label}
          </Label>
        )}
        <Listbox
          value={selectedItem}
          onChange={(item) => {
            if (item && onChangeValue) {
              onChangeValue(item.value, item.label);
            }
          }}
          {...ref}
        >
          <div className="relative">
            <ListboxButton
              className={clsx(
                !hasClassName(className, "border-") && status && borderStatus[status],
                "flex items-center text-left transition ease-in-out duration-150 w-full appearance-none pt-2.5 pb-2 z-1 text-medium focus-visible:outline-none",
                className,
                getLabelClass,
              )}
            >
              <span className={clsx("block truncate pr-3", classLabelBySize[size], classByStatus[status])}>
                {selectedItem ? selectedItem.label : placeHolderText}
              </span>
              <span className="flex items-center pointer-events-none">
                <ChevronDownIcon />
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                className={twMerge(
                  "absolute py-1 overflow-hidden bg-white rounded-xl shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none z-10 w-48",
                  className,
                )}
                style={{ ...styleList }}
              >
                {handleSearch && (
                  <div className="px-3 flex items-end">
                    <FormInput
                      onChangeValue={handleSearch}
                      value={searchString}
                      placeholder="Search"
                      iconStart={<BoldSearchIcon />}
                    />
                  </div>
                )}
                <div className="max-h-60 overflow-auto">
                  {optionData.map((item, itemIdx) => (
                    <ListboxOption
                      key={itemIdx}
                      className={clsx("cursor-pointer select-none relative hover:bg-secondary")}
                      value={item}
                    >
                      {({ selected }) => (
                        <>
                          <span className={clsx("block", classItemBySize[size], selected && "bg-secondary")}>
                            {item.label}
                          </span>
                          {item?.description && (
                            <span className={twMerge("text-primary-top", classDescriptionItemBySize[size])}>
                              {item.description}
                            </span>
                          )}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </div>
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>
    );
  },
);

type DropdownSelectStatus = NonNullable<DropdownAutoCompleteSelectProps["status"]>;
const classByStatus: Record<DropdownSelectStatus, string> = {
  primary: "text-white",
  default: "text-primary",
  error: "text-white hover:bg-red-800",
  warning: "text-yellow",
  success: "text-green",
};

const classByVariant: Record<Variant, string> = {
  secondary: "",
  primary: "border-b",
  outline: "border border-primary rounded-md",
};

const classLabelBySize: Record<Size, string> = {
  small: "text-sm leading-3.6",
  normal: "text-medium",
};

const classItemBySize: Record<Size, string> = {
  small: "font-medium text-13px leading-5 py-1 px-3",
  normal: "font-normal text-medium py-2 pl-6 pr-4",
};

const classDescriptionItemBySize: Record<Size, string> = {
  small: "font-medium text-sm leading-5 py-1 px-3",
  normal: "font-normal text-xs py-2 pl-6 pr-4",
};
