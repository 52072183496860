import { AppMode } from "types";
import { IconButton, Tooltip } from "components";
import clsx from "clsx";
import { cloneElement, isValidElement, ReactElement, ReactNode } from "react";

export type MapToolBoxEntryProps = {
  text: string;
  mode: AppMode;
  icon: ReactNode;
  onSelect: (mode: AppMode) => void;
  selected: boolean;
};

export const MapToolBoxEntry = ({ text, mode, icon, onSelect, selected }: MapToolBoxEntryProps) => {
  const className = selected ? "bg-primary-active" : "bg-primary";
  let iconNode: ReactElement | null = null;

  if (isValidElement(icon)) {
    iconNode = cloneElement(icon);
  }
  return (
    <div className="py-1" key={"toolbox-" + mode}>
      <div className="rounded-md bg-primary w-10 h-10">
        <Tooltip label={text} pos="right" arrow>
          <IconButton
            onClick={() => onSelect(mode)}
            className={clsx(
              "border-transparent text-secondary rounded-md w-10 h-10 hover:bg-primary-active",
              className,
            )}
          >
            {iconNode}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
