import clsx from "clsx";
import * as React from "react";
import { hasClassName } from "helper/check-class";

export interface IconButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  render?: (renderProps: { className: string; children: React.ReactNode }) => React.ReactElement<any, any> | null;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { type = "button", render, ...buttonProps },
  ref,
) {
  const className = clsx(
    "rounded-full inline-flex justify-center items-center",
    !hasClassName(buttonProps.className, "p-") && "p-1",
    buttonProps.disabled && "cursor-default",
    buttonProps.className,
  );

  if (render) {
    return render({
      className,
      children: buttonProps.children,
    });
  }

  return <button type={type} {...buttonProps} className={className} ref={ref} />;
});
