import { CitiesFilter, PromptCity } from "types";
import { OsmSearchJsonV2 } from "./osm.type";
/**
 * Create OSM API URL to get more data for city.
 * https://nominatim.openstreetmap.org/search.php?city=${city.name}&postalcode=${city.zip}&polygon_geojson=1&countrycodes=fr&format=jsonv2&addressdetails=1&hierarchy=0&group_hierarchy=1
 */
export const toOsmApiUrl = (city: PromptCity): string => {
  const u = new URL("https://nominatim.openstreetmap.org/search.php");
  u.searchParams.set("q", city.name);
  if (city.zipCode) {
    u.searchParams.set(
      "postalcode",
      [2, 5].includes(city.zipCode.length) ? city.zipCode : city.zipCode.substring(0, 2).padStart(2, "0"),
    );
  }
  u.searchParams.set("polygon_geojson", "1");
  u.searchParams.set("format", "jsonv2");
  u.searchParams.set("addressdetails", "1");
  u.searchParams.set("hierarchy", "0");
  u.searchParams.set("group_hierarchy", "1");
  u.searchParams.set("extratags", "1");
  return u.toString();
};

/**
 * Fetch city info from OSM.
 */
export const fetchCityInfo = async (city: CitiesFilter): Promise<OsmSearchJsonV2[]> => {
  try {
    // Get OSM special format
    const osmRes: OsmSearchJsonV2[] = await fetch(toOsmApiUrl(city)).then((x) => x.json());

    // Isolate relevant data
    const data: OsmSearchJsonV2[] = osmRes.filter(
      (item) => item.category === "boundary" && (item.place_rank >= 10 || item.place_rank <= 16),
    );

    return data;
  } catch (err) {
    throw Error(`City (${city.name}, fr) not found for boundaries: ${err}`);
  }
};
