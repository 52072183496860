import { Filter } from "./SearchFilter.type";
import { DropdownMultiSelect, FieldContainer } from "../form-control";
import { usePolicies } from "../../modules/policies/hooks/use-policies";
import { useMemo } from "react";

type PoliciesFilterProps = {
  onChange: (input: Pick<Filter, "selectedPolicies">) => void;
} & Pick<Filter, "selectedPolicies">;

export const PoliciesFilter = ({ selectedPolicies, onChange }: PoliciesFilterProps) => {
  const { policyList } = usePolicies();

  const optionData = useMemo((): any[] => {
    return policyList.map((item) => {
      return {
        value: item.curbPolicyId,
        label: item.name,
      };
    });
  }, [policyList]);

  return (
    <div className="transition-opacity h-auto bg-default p-1 mt-16 mb-2 rounded-md shadow-lg z-10">
      <FieldContainer className="w-full mb-0 relative">
        <DropdownMultiSelect
          options={optionData}
          values={selectedPolicies}
          onChangeValues={(value) => onChange({ selectedPolicies: value })}
          size="small"
          placeholder="Type de place"
        />
      </FieldContainer>
    </div>
  );
};
