import {
  AddIcon,
  AddMultiModeIcon,
  EditIcon,
  EditLocationIcon,
  EditModeIcon,
  EditMultiModeIcon,
  ViewIcon,
} from "components/icons";

/**
 * The icons to be displayed in the toolbox, depending on mode.
 */
export const toolboxIcons = [
  {
    name: "Restriction",
    groups: [
      {
        text: "View",
        mode: "view",
        icon: <ViewIcon className="h-5 w-5" />,
      },
      {
        text: "Add Restriction",
        mode: "addDisabledCurbZone",
        icon: <EditIcon className="h-5 w-5" />,
      },
      {
        text: "Edit Restriction",
        mode: "editDisabledCurbZone",
        icon: <EditLocationIcon className="h-5 w-5" />,
      },
      {
        text: "Multiple Create Restrictions",
        mode: "multiCreateDisabledCurbZone",
        icon: <AddMultiModeIcon className="h-5 w-5" />,
      },
      {
        text: "Multiple Edit Restrictions",
        mode: "multiEdit",
        icon: <EditMultiModeIcon className="h-5 w-5" />,
      },
    ],
  },
  {
    name: "Disabled Street",
    groups: [
      {
        text: "Add Disabled Street",
        mode: "addDisabledStreet",
        icon: <AddIcon className="h-5 w-5" />,
      },
      {
        text: "Edit Disabled Street",
        mode: "editDisabledStreet",
        icon: <EditModeIcon className="h-5 w-5" />,
      },
    ],
  },
];
