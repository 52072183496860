import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "assets";
import { FieldContainer, Autocomplete, FormInput } from "components/form-control";
import { optionType } from "components/form-control/autocomplete";
import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { OsmSearchJsonV2 } from "services/common/osm.type";
import { CityInput } from "types";

interface GeneralProps {
  handleSearch: (ev: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  result: optionType<string>[];
  cityName: string;
  handleSelectCity: (value: string, label: string) => void;
  selectedCity: OsmSearchJsonV2 | undefined;
}

export const GeneralCityDetails = ({ isLoading, result, cityName, handleSelectCity, handleSearch }: GeneralProps) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<CityInput>();

  const generalErrors = errors.name || errors.zipCode;

  return (
    <>
      <div className="w-full flex flex-col">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <DisclosureButton className="flex w-full justify-between items-center rounded-md shadow-lg bg-white  px-4 py-2 text-left text-sm outline-none font-medium">
                <h1 className={`font-bold text-md ${generalErrors ? "text-red-700" : ""}`}>General</h1>
                <div className="flex flex-row gap-x items-center">
                  {generalErrors ? <span className="text-red-700 text-lg">&#9888;</span> : null}
                  <ChevronDownIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5`} />
                </div>
              </DisclosureButton>
              <DisclosurePanel className="px-4 pt-4 pb-2 text-sm">
                <div className="flex flex-row w-full gap-x-4 justify-around">
                  <FieldContainer
                    className="basis-1/2 items-center justify-start border-b border-primary"
                    helpText={errors.name ? "This is required" : undefined}
                    status={errors.name ? "error" : "default"}
                  >
                    <Autocomplete
                      {...register("name", { required: true })}
                      variant="underline"
                      onChangeValue={handleSelectCity}
                      value={cityName}
                      onChange={handleSearch}
                      optionData={result}
                      isLoading={isLoading}
                      name="name"
                      className="relative"
                      panelClassName="absolute z-10"
                      inputClassName="border-none focus:border-none focus:ring-0"
                      status={errors.name ? "error" : "primary"}
                      placeholder="Search city..."
                    />
                  </FieldContainer>
                  <FieldContainer
                    className="basis-1/2 items-center justify-start border-b border-primary"
                    helpText={errors.zipCode ? "This is required" : undefined}
                    status={errors.zipCode ? "error" : "default"}
                  >
                    <FormInput
                      label="Zip code"
                      className="border-none"
                      {...register("zipCode", { required: true })}
                      status={errors.zipCode ? "error" : "primary"}
                      defaultValue={getValues("zipCode")}
                    />
                  </FieldContainer>
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
