export type NestedArrays<T> = Array<T | NestedArrays<T>>;
/**
 * @see https://github.com/jonschlinkert/arr-flatten
 */
function flatten<T>(arr: NestedArrays<T>, result: T[]) {
  let len = arr.length;
  let num = 0;

  while (len--) {
    const current = arr[num++];
    if (Array.isArray(current)) {
      flatten(current, result);
    } else {
      result.push(current);
    }
  }
}

export function flattenedArray<T>(nestedArray: NestedArrays<T>): T[] {
  const result: T[] = [];
  flatten(nestedArray, result);
  return result;
}

export function findObject<T, K extends keyof T>(arrayObject: T[], key: K, val: any): number {
  return arrayObject.findIndex((item) => item && item[key] === val);
}

export function sortAsc<T>(array: T[], key: keyof T) {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}

export function sortDesc<T>(array: T[], key: keyof T) {
  return array.sort((a, b) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  });
}

export const createArray = <T = unknown>(length: number, filler: T = undefined as any as T) => {
  const result: T[] = [];
  for (let index = 0; index < length; index++) {
    result.push(filler);
  }
  return result;
};

export const filterArrayByString = <T, K extends keyof T>(arr: T[], key: K, searchString: string): T[] => {
  return searchString === ""
    ? arr
    : arr.filter((item) =>
        (item[key] as string)
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(searchString.toLowerCase().replace(/\s+/g, "")),
      );
};
