import { MacButton, Modal, ModalContent, ModalFooter } from "components";
import { DropdownSelect, FieldContainer, FormInput } from "components/form-control";
import { useState } from "react";
import { Decree, UpdateDecreeInput } from "types/decree.type";
import { decreeCategoryType } from "./decree.const";
import { DecreeCategory } from "enums/decree.enum";
import { Primitive } from "helper/utils";

type EditDecreeProps = {
  decreeData: Decree | null;
  closeModal: () => void;
  updateDecreeFile: (input: UpdateDecreeInput) => void;
};

export const EditDecreeModal = ({ decreeData, closeModal, updateDecreeFile }: EditDecreeProps) => {
  const [name, setName] = useState(decreeData?.name);
  const [selectedCategory, setSelectedCategory] = useState(decreeData?.category ?? DecreeCategory.temporary);

  const saveDecree = async () => {
    const newDecree = {
      id: decreeData?.id,
      name: name || "",
      done: decreeData?.done,
      category: selectedCategory,
    };
    await updateDecreeFile(newDecree);
    closeModal();
  };

  const handleUpdateByDecreeCategory = (value: Primitive) => {
    setSelectedCategory(value as DecreeCategory);
  };
  return (
    <Modal isOpen className="my-1vh">
      <ModalContent
        className="pb-0"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{"Edit "} decree</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Decree information</p>
          </>
        }
      >
        <FieldContainer>
          <DropdownSelect
            defaultValue={selectedCategory}
            className="justify-between w-full border-b"
            options={decreeCategoryType}
            onChangeValue={handleUpdateByDecreeCategory}
          />
        </FieldContainer>
        <FieldContainer className="border-b">
          <FormInput
            label="Name"
            className="col-span-3 border-none"
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </FieldContainer>
        <ModalFooter>
          <MacButton onClick={saveDecree} variant="primary">
            save
          </MacButton>
          <MacButton onClick={closeModal} variant="secondary">
            Cancel
          </MacButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
