export const showGlobalLoading = () => {
  document.querySelector("#loading")!.classList.remove("hidden");
};

export const hideGlobalLoading = () => {
  const isLoading = !document.querySelector("#loading")!.classList.contains("hidden");

  if (isLoading) {
    document.querySelector("#loading")!.classList.add("hidden");
  }
};
