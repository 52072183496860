import * as React from "react";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Confirm, MacButton } from "components";
import { isEmpty, isNil } from "helper/utils";
import { formatDate } from "helper/date-time";
import { distanceUnit } from "constants/mapbox.const";
import { numberFormat } from "helper/format-data";
import { Policy } from "types";
import { BookIcon, CloseIcon, CopyIcon } from "components/icons";
import { CurbZone } from "types/curb-zone.type";
import useToggle from "hooks/use-toggle";

type CurbZoneDetailProps = {
  curbZone: CurbZone;
  resetState: () => void;
  openCurbZoneModal: React.Dispatch<React.SetStateAction<boolean>>;
  cityId: string;
  handleRemoveCurbZone: (curbZoneId: string) => void;
  copyCurbZone: () => void;
  title: string | null;
};

export const CurbZoneDetail = ({
  resetState,
  curbZone,
  openCurbZoneModal,
  handleRemoveCurbZone,
  copyCurbZone,
  title,
}: CurbZoneDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle();

  const removeParking = () => {
    if (curbZone.id) {
      handleRemoveCurbZone(curbZone.id);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm();
  };

  const handleOpenConfirm = () => {
    setOpenConfirm();
  };
  return (
    <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80">
      <div className="flex justify-between h-6 w-full">
        <CopyIcon className="text-white w-4 h-4 cursor-pointer" onClick={copyCurbZone} />
        {title && <p className="leading-5 text-sm text-lg font-semibold text-white">{title}</p>}

        <CloseIcon className="text-white w-4 h-4 cursor-pointer" onClick={resetState} />
      </div>
      <DataTable className="p-0 shadow-none rounded-none">
        <TableBody>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Policy</TableCell>
            <TableCell>{curbZone?.curbPolicies && <Policies policies={curbZone.curbPolicies} />}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Forme</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {curbZone?.parkingAngle}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Nom voie</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {curbZone?.streetName}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Début du numéro de rue</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {curbZone?.streetNumberStart}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Fin du numéro de rue</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {curbZone?.streetNumberEnd}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Longueur</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{`${numberFormat(
              curbZone?.length,
            )} ${distanceUnit}`}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Largeur</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{`${numberFormat(
              curbZone?.width,
            )} ${distanceUnit}`}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Surface calculée</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {numberFormat(curbZone?.calculatedArea)}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Places calculées</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {numberFormat(curbZone?.calculatedSpaces)}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Date relevé</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {formatDate(curbZone.publishedDate)}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Start date</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {formatDate(curbZone.startDate)}
            </TableCell>
          </TableRow>
          {!isNil(curbZone?.externalId) && !isEmpty(curbZone?.externalId) && (
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary pb-1">Identifiant externe</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {curbZone?.externalId}
              </TableCell>
            </TableRow>
          )}
          {!isNil(curbZone?.note) && !isEmpty(curbZone?.note) && (
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary pb-1">Commentaire</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                <span dangerouslySetInnerHTML={{ __html: curbZone?.note.replace(/\n/g, "<br />") }} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </DataTable>
      <div className="flex justify-between mt-4">
        <MacButton onClick={handleOpenConfirm} variant="error">
          Delete
        </MacButton>
        <MacButton onClick={() => openCurbZoneModal(true)} variant="secondary">
          Edit
        </MacButton>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete a curb zone"
          content="Are you sure that you want to delete this curb zone?"
          onCancel={handleCloseConfirm}
          onOk={removeParking}
          butOkName="Delete"
          type="error"
        />
      )}
    </div>
  );
};

type RegulationProps = {
  policies: Policy[];
};
const Policies = ({ policies }: RegulationProps) => {
  return (
    <>
      {!policies || policies?.length === 0 ? (
        <span className="h-5 flex-1 truncate text-left text-secondary text-table-data flex justify-end">
          No policy yet!
        </span>
      ) : (
        <>
          {policies.map((item, index) => (
            <div className="flex items-center" key={`regulation-${index}`}>
              <div className="regulation-card flex items-center min-w-0 mb-2 w-full bg-primary text-table-data text-secondary">
                <BookIcon className="w-4 h-4 mr-3" />
                <div className="flex flex-col">
                  <span className="h-5 flex-1 truncate text-left">{item.name}</span>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
