export enum DaysOfWeek {
  Monday = "mon",
  Tuesday = "tue",
  Wednesday = "wed",
  Thursday = "thu",
  Friday = "fri",
  Saturday = "sat",
  Sunday = "sun",
}

export enum Activity {
  parking = "parking",
  noParking = "no parking",
  loading = "loading",
  noLoading = "no loading",
  unloading = "unloading",
  noUnlaoding = "no unloading",
  stopping = "stopping",
  noStopping = "no stopping",
  travel = "travel",
  noTravel = "no travel",
}

export type RuleActivity = keyof typeof Activity;

export enum UnitOfTime {
  Second = "second",
  Minute = "minute",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum RateUnitPeriod {
  rolling = "rolling",
  calendar = "calendar",
}

export enum VehicleTypes {
  bicycle = "bicycle",
  bus = "bus",
  cargoBicycle = "cargoBicycle",
  car = "car",
  moped = "moped",
  motorcycle = "motorcycle",
  scooter = "scooter",
  truck = "truck",
  van = "van",
  bike = "bike",
  motorbike = "motorbike",
  autobus = "autobus",
}
export enum VehicleProperties {
  HandicapAccessible = "handicap-accessible",
  human = "human",
  electricAssist = "electricAssist",
  electric = "electric",
  combustion = "combustion",
  autonomous = "autonomous",
}
export enum Purpose {
  construction = "construction",
  delivery = "delivery",
  emergencyUse = "emergencyUse",
  freight = "freight",
  parking = "parking",
  permit = "permit",
  rideShare = "rideshare",
  school = "school",
  serviceVehicles = "serviceVehicles",
  specialEvents = "specialEvents",
  taxi = "taxi",
  utilities = "utilities",
  vending = "vending",
  wasteManagement = "wasteManagement",
}

export enum OtherTypes {
  police = "police",
  twoWheeledVehicles = "two-wheeled vehicles",
  twoWheeler = "two-wheeler",
  cashEscort = "cash-escort",
  cashTransport = "cash-transport",
  moneyTransporter = "money-transporter",
  pompiers = "pompiers",
  firefighter = "firefighter",
}

export enum PolicyCategory {
  paid = "paid",
  free = "free",
  disabled = "disabled",
  delivery = "delivery",
  taxi = "taxi",
  bicycle = "bicycle",
  scooter = "scooter",
  twoWheels = "twoWheels",
  motorcycle = "motorcycle",
  carpooling = "carpooling",
  electric = "electric",
  police = "police",
  cashTransport = "cashTransport",
  fireFigthers = "fireFigthers",
  coach = "coach",
  busStop = "busStop",
  dropOff = "dropOff",
  other = "other",
}
