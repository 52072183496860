import * as React from "react";
import clsx from "clsx";

export const DescriptionList = ({ className = "", ...props }: React.ComponentProps<"dl"> & { className?: string }) => (
  <dl {...props} className={clsx("sm:grid sm:gap-4 sm:grid-cols-3 md:grid-cols-5 items-center", className)} />
);
export const DescriptionItem = (props: { label: React.ReactNode; value: React.ReactNode }) => (
  <>
    <dt className="text-xs lg:col-span-2 text-secondary">{props.label || ""}</dt>
    <dd className="text-xs mb-4 sm:mb-0 sm:col-span-2 md:col-span-3 break-all text-secondary">{props.value || ""}</dd>
  </>
);
