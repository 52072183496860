import { Checkbox } from "./check-box";
import * as React from "react";

export type DaysOfMonthProps = {
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  days: number[];
};

export type DaysOfMonthType = {
  value: number;
  isCheck: boolean;
};

export const DaysOfMonth = ({ onChange, days = [] }: DaysOfMonthProps) => {
  const daysOfMonth = syncDaysOfMonth(days);
  const changeValue = (idx: number) => {
    const newDaysOfMonth = daysOfMonth.reduce((preValue: number[], currentValue, index) => {
      if ((index === idx && !currentValue.isCheck) || (index !== idx && currentValue.isCheck)) {
        return [...preValue, currentValue.value];
      }
      return preValue;
    }, []);
    onChange(newDaysOfMonth);
  };

  return (
    <div className="flex flex-wrap">
      {daysOfMonth.map((item, idx) => (
        <Checkbox
          label={item.value}
          onChangeValue={() => changeValue(idx)}
          checked={item.isCheck}
          key={`days-of-month-${idx}`}
          wrapperClass="mr-2"
        />
      ))}
    </div>
  );
};

export const syncDaysOfMonth = (list: number[]): DaysOfMonthType[] => {
  const arrayDay = [];
  for (let i = 1; i <= 31; i++) {
    arrayDay.push({ value: i, isCheck: list.includes(i) });
  }
  return arrayDay;
};
