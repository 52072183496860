import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_CREATE_POLICY,
  QUERY_POLICIES,
  QUERY_REMOVE_POLICY,
  QUERY_UPDATE_POLICY,
} from "services/web/api-policy.const";
import {
  CreatePolicyInput,
  CreatePolicyResponse,
  GetPoliciesResponse,
  UpdatePolicyInput,
} from "services/web/api-policy.type";
import { useContext, useMemo } from "react";
import { Policy } from "types";
import { CityContext } from "contexts/city-context-provider";

export const usePolicies = () => {
  const { currentCity } = useContext(CityContext);
  const { data, loading, refetch } = useQuery<GetPoliciesResponse>(QUERY_POLICIES, {
    variables: {
      cityId: currentCity?.id,
    },
    skip: !currentCity,
  });

  const [createNewPolicy] = useMutation<CreatePolicyResponse>(QUERY_CREATE_POLICY, {
    update: () => {
      refetch({ cityId: currentCity?.id });
    },
  });
  const [updatePolicy] = useMutation<CreatePolicyResponse>(QUERY_UPDATE_POLICY, {
    update: () => {
      refetch({ cityId: currentCity?.id });
    },
  });
  const [deletePolicy] = useMutation(QUERY_REMOVE_POLICY, {
    update: () => {
      refetch({ cityId: currentCity?.id });
    },
  });

  const policyList = useMemo(() => {
    if (data) {
      return data.policiesByCityId;
    }
    return [] as Policy[];
  }, [data]);

  const handleAddPolicy = async (input: CreatePolicyInput) => {
    const response = await createNewPolicy({ variables: input });
    return response.data;
  };

  const handleUpdatePolicy = async (input: UpdatePolicyInput) => {
    await updatePolicy({ variables: input });
  };
  const handleDeletePolicy = async (policyId: string) => {
    await deletePolicy({
      variables: {
        policyId,
      },
    });
  };

  return {
    handleUpdatePolicy,
    handleAddPolicy,
    policyList,
    refetch,
    loading,
    handleDeletePolicy,
  };
};
