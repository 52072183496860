import { AddIcon } from "assets";
import { IconButton } from "components";
import { MacButton } from "components/button";
import { DropdownSelect } from "components/form-control";
import { FieldContainer } from "components/form-control/field-container";
import { InputNumber } from "components/form-control/input-number";
import { DeleteIcon } from "components/icons";
import { Modal, ModalContent, ModalFooter } from "components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PolicyRuleInput, RateInput } from "types";
import { arrActivity, arrRateUnitPeriod, arrUnitOfTime, defaultRate } from "../policies.const";
import { UserClasses } from "./UserClasses";

export interface AddRuleModalProps {
  ruleData: PolicyRuleInput;
  isOpen: boolean;
  onDismiss: () => void;
  onApply: (input: PolicyRuleInput) => void;
}

export const AddRuleModal = ({ ruleData, isOpen, onDismiss, onApply }: AddRuleModalProps) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<PolicyRuleInput>({
    defaultValues: { ...ruleData },
    mode: "onChange",
  });

  const [rates, setRates] = useState<RateInput[]>(ruleData?.rate || []);
  const [userClasses, setUserClasses] = useState<string[]>(ruleData?.userClasses || []);

  const handleSave = () => {
    const newRule = {
      ...getValues(),
      rate: rates,
      userClasses,
    };
    onApply(newRule);
  };

  const handleChangeRate = ({ field, value, idx }: ChangeRateParams) => {
    const newRates = [...rates];
    newRates[idx] = { ...newRates[idx], [field]: value };
    setRates(newRates);
  };

  const addRate = () => {
    setRates((prevState) => [...prevState, defaultRate]);
  };

  const removeRate = (idx: number) => {
    const newRates = [...rates];
    newRates.splice(idx, 1);
    setRates([...newRates]);
  };

  return (
    <Modal
      isOpen={isOpen}
      className="my-10 flex items-center content-center w-full mx-auto bg-default rounded-lg overflow-hidden z-20 max-w-sm md:max-w-md lg:max-w-2xl "
    >
      <ModalContent className="pb-0" header="Edit a rule">
        <form>
          <FieldContainer className="mt-8 gap-4 w-36">
            <DropdownSelect
              label="Activity"
              className="border-primary border-solid"
              options={arrActivity}
              {...register("activity")}
              value={ruleData.activity}
              defaultValue={ruleData.activity}
            />
          </FieldContainer>
          <div className="flex gap-4">
            <FieldContainer helpText={errors?.maxStay ? "This is required" : undefined}>
              <InputNumber
                label="Max stay"
                {...register("maxStay", { valueAsNumber: true })}
                status={errors.maxStay ? "error" : "primary"}
                min={0}
                defaultValue={ruleData?.maxStay}
              />
            </FieldContainer>
            <FieldContainer className="w-24">
              <DropdownSelect
                label="Unit"
                className="border-primary border-solid"
                options={arrUnitOfTime}
                {...register("maxStayUnit")}
                value={ruleData.maxStayUnit}
                defaultValue={ruleData.maxStayUnit}
              />
            </FieldContainer>
          </div>
          <div className="flex gap-4">
            <FieldContainer>
              <InputNumber
                label="No return"
                {...register("noReturn", { valueAsNumber: true })}
                status={errors.noReturn ? "error" : "primary"}
                defaultValue={ruleData?.noReturn}
              />
            </FieldContainer>
            <FieldContainer className="w-24">
              <DropdownSelect
                label="Unit"
                className="border-primary border-solid"
                options={arrUnitOfTime}
                value={ruleData.noReturnUnit}
                {...register("noReturnUnit")}
                defaultValue={ruleData.noReturnUnit}
              />
            </FieldContainer>
          </div>
          <FieldContainer>
            <UserClasses userClasses={userClasses} setUserClasses={setUserClasses} />
          </FieldContainer>

          <div className="text-medium mb-4">Rates</div>
          <div className="flex flex-col gap-4">
            {rates.map((item, rateIndex) => (
              <div key={`rate-${rateIndex}`} className="flex justify-between">
                <RateItem item={item} changeValue={handleChangeRate} idx={rateIndex} />
                <div className="mt-3">
                  <IconButton className="w-6 h-6" onClick={() => removeRate(rateIndex)}>
                    <DeleteIcon className="w-4 h-4 text-gray-400" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center">
            <IconButton className="w-6 h-6" onClick={addRate}>
              <AddIcon className="w-4 h-4 text-gray-400" />
            </IconButton>
          </div>
          <ModalFooter className="text-right">
            <MacButton variant="primary" onClick={handleSave}>
              Save
            </MacButton>
            <MacButton variant="secondary" className="mr-2" onClick={onDismiss}>
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

type ChangeRateParams = { field: keyof RateInput; value: any; idx: number };

type RateItemProps = {
  item: RateInput;
  changeValue: (input: ChangeRateParams) => void;
  idx: number;
};

const RateItem = ({ item, changeValue, idx }: RateItemProps) => {
  return (
    <div>
      <div className="flex gap-4">
        <FieldContainer>
          <InputNumber
            label="Rate"
            defaultValue={item.rate}
            onChangeValue={(value) => changeValue({ field: "rate", value, idx })}
            min={0}
          />
        </FieldContainer>
        <FieldContainer className="w-24">
          <DropdownSelect
            label="Unit"
            className="border-primary border-solid"
            options={arrUnitOfTime}
            defaultValue={item.rateUnit}
            onChangeValue={(value) => changeValue({ field: "rateUnit", value: value, idx })}
          />
        </FieldContainer>
        <FieldContainer className="w-24">
          <DropdownSelect
            label="Period of time"
            className="border-primary border-solid"
            options={arrRateUnitPeriod}
            defaultValue={item.rateUnitPeriod}
            onChangeValue={(value) => changeValue({ field: "rateUnitPeriod", value: value, idx })}
          />
        </FieldContainer>
      </div>
      <div className="flex gap-4">
        <FieldContainer>
          <InputNumber
            label="Increment duration"
            defaultValue={item.incrementDuration}
            onChangeValue={(value) => changeValue({ field: "incrementDuration", value: value, idx })}
            min={0}
          />
        </FieldContainer>
        <FieldContainer>
          <InputNumber
            label="Increment amount"
            defaultValue={item.incrementAmount}
            onChangeValue={(value) => changeValue({ field: "incrementAmount", value: value, idx })}
            min={0}
          />
        </FieldContainer>
      </div>
      <div className="flex gap-4">
        <FieldContainer>
          <InputNumber
            label="Start duration"
            defaultValue={item.startDuration}
            onChangeValue={(value) => changeValue({ field: "startDuration", value: value, idx })}
            min={0}
          />
        </FieldContainer>
        <FieldContainer>
          <InputNumber
            label="End duration"
            defaultValue={item.endDuration}
            onChangeValue={(value) => changeValue({ field: "endDuration", value: value, idx })}
            min={0}
          />
        </FieldContainer>
      </div>
    </div>
  );
};
